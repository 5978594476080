import { Loadable } from '@vivium/frontend-common/components'
import { ModuleRoute } from '@vivium/frontend-common/types'
import { paths } from 'config'

const routes: ModuleRoute[] = [
  {
    path: paths.faq,
    exact: true,
    public: false,
    layoutSettings: {
      drawerProps: { hidden: false },
      topbarProps: { hidden: false },
    },
    component: Loadable({
      component: () => import('./pages/Faq'),
    }),
  },
]

export default routes
