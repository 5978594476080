import createPalette, {
  PaletteOptions,
} from '@material-ui/core/styles/createPalette'

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    primary: string
    secondary: string
  }

  interface Palette {
    red: Palette['primary']
    error: Palette['primary']
    neutral: Palette['primary']
    shadow: Palette['primary']
    statusCircle: {
      gray: Palette['primary']
    }
    pill: {
      yellow: Palette['primary']
      red: Palette['primary']
      green: Palette['primary']
      gray: Palette['primary']
      staffing: Palette['primary']
      scheduling: Palette['primary']
      viviumI: Palette['primary']
      viviumX: Palette['primary']
      contrastText: Palette['primary']
    }
  }

  interface PaletteOptions {
    red: PaletteOptions['primary']
    neutral: PaletteOptions['primary']
    pill: {
      red: PaletteOptions['primary']
      yellow: PaletteOptions['primary']
      green: PaletteOptions['primary']
      gray: PaletteOptions['primary']
      staffing: PaletteOptions['primary']
      scheduling: PaletteOptions['primary']
      viviumI: PaletteOptions['primary']
      viviumX: PaletteOptions['primary']
      contrastText: PaletteOptions['primary']
    }
    shadow: PaletteOptions['primary']
    statusCircle: {
      gray: PaletteOptions['primary']
    }
  }
}

const palette: PaletteOptions = {
  type: 'light',
  error: {
    main: '#d92b00',
    light: '#FFDEDE',
  },
  red: {
    main: '#d92b00',
    light: '#E47777',
  },
  primary: {
    light: '#f5a583',
    main: '#F38E64',
    dark: '#9c4827',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#DDDDDD',
    main: '#595959',
    dark: '#515151',
    contrastText: '#FFF',
  },
  neutral: {
    light: '#F2F2F2',
    main: '#EBEBEB',
    dark: '#CBCBCB',
    contrastText: 'rgba(0,0,0,0.8)',
  },
  background: {
    default: '#FAFAFA',
    paper: '#FFF',
    primary: '#FFF9F6',
    secondary: '#8A8A8A',
  },
  text: {
    primary: '#000000',
    secondary: '#828282',
  },
  shadow: {
    main: 'rgba(0, 0, 0, 0.12)',
    dark: 'rgba(0, 0, 0, 0.28)',
  },
  pill: {
    yellow: {
      main: '#FFAF3A',
    },
    green: {
      main: '#65BF73',
    },
    gray: {
      main: '#515151',
    },
    red: {
      main: '#DD3D20',
    },
    contrastText: {
      main: '#FFFFFF',
      dark: '#000000',
    },
    staffing: {
      main: '#36BCDA',
    },
    scheduling: {
      main: '#FFAF3A',
    },
    viviumI: {
      main: '#F38E64',
    },
    viviumX: {
      main: '#089BAB',
    },
  },
  statusCircle: {
    gray: {
      light: '#E6E6E6',
      main: '#B6B6B6',
    },
  },
}

export default createPalette(palette)
