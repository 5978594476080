import { SectionCard } from '@vivium/frontend-common/components'
import { useTranslation } from 'react-i18next'
import { MODULE_NAME } from '../../../modules/system/strings'
import { LoginInfoReset } from './LoginInfoReset'
import { StyledCardWrapper } from './LoginInfoResetSection.styles'

interface LoginInfoResetSectionProps {
  accountId: number
  accountName?: string
  hasPhoneNumber: boolean
  withLabel?: boolean
}

const LoginInfoResetSection = ({
  accountName,
  accountId,
  hasPhoneNumber,
  withLabel,
}: LoginInfoResetSectionProps) => {
  const { t } = useTranslation(MODULE_NAME)

  return (
    <StyledCardWrapper>
      <SectionCard label={t('resetLoginInformation.label')}>
        <LoginInfoReset
          accountName={accountName}
          accountId={accountId}
          hasPhoneNumber={hasPhoneNumber}
          withLabel={withLabel}
        />
      </SectionCard>
    </StyledCardWrapper>
  )
}

export default LoginInfoResetSection
