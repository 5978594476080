import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { createAsyncThunkWithErrorHandling } from '@vivium/frontend-common/utils'
import { AxiosError } from 'axios'
import {
  StaffProviderResource,
  GetAllStaffProviderResourceParams,
  GetStaffProviderResourceParams,
  DeleteStaffProviderResourceParams,
  CreateStaffProviderResourceParams,
} from 'shared/services/staffProviderResource'
import { MODULE_NAME } from '../../strings'

const staffProviderResource = new StaffProviderResource()

export const createResource = createAsyncThunk(
  `${MODULE_NAME}/createResource`,
  (payload: CreateStaffProviderResourceParams, { rejectWithValue }) =>
    staffProviderResource.createResource(payload).catch((error: AxiosError) => {
      if (!error.response) throw error
      return rejectWithValue(error.response.data.message)
    })
)

export const resetCreateResource = createAction(
  `${MODULE_NAME}/resetCreateResource`
)

export const getAllStaffProvidersResourceDetails = createAsyncThunk(
  `${MODULE_NAME}/getAllStaffProvidersResourceDetails`,
  (payload: GetAllStaffProviderResourceParams) => {
    return staffProviderResource.getAllStaffProviderResourceDetails(payload)
  }
)

export const deleteStaffProviderResource = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deleteStaffProviderResource`,
  (payload: DeleteStaffProviderResourceParams) =>
    staffProviderResource.deleteStaffProviderResource(payload)
)

export const resetDeleteStaffProviderResource = createAction(
  `${MODULE_NAME}/resetDeleteStaffProviderResource`
)

export const getStaffProviderResourceDetails = createAsyncThunk(
  `${MODULE_NAME}/getStaffProviderResourceDetails`,
  (payload: GetStaffProviderResourceParams) => {
    return staffProviderResource.getStaffProviderResourceDetails(payload)
  }
)
