import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { createAsyncThunkWithErrorHandling } from '@vivium/frontend-common/utils'
import { OrganizationSearchBy } from 'shared/services/organizations'
import {
  Organizations,
  GetOrganizationsDetailsParams,
  PostOrganizationScheduling,
  PostOrganizationStaffing,
  AssignStaffProvidersToCareProviderPayload,
  UnassignStaffProvidersFromCareProviderPayload,
  AssignCareProvidersToStaffProviderPayload,
  UnassignCareProvidersFromStaffProviderPayload,
} from 'shared/services/organizations'
import { MODULE_NAME } from '../../strings'

const organizations = new Organizations()

export const assignCareProvidersToStaffProvider =
  createAsyncThunkWithErrorHandling(
    `${MODULE_NAME}/assignCareProvidersToStaffProvider`,
    (payload: AssignCareProvidersToStaffProviderPayload) =>
      organizations.assignCareProvidersToStaffProvider(payload)
  )

export const unassignCareProvidersFromStaffProvider =
  createAsyncThunkWithErrorHandling(
    `${MODULE_NAME}/unassignCareProvidersFromStaffProvider`,
    (payload: UnassignCareProvidersFromStaffProviderPayload) =>
      organizations.unassignCareProvidersFromStaffProvider(payload)
  )

export const assignStaffProvidersToCareProvider =
  createAsyncThunkWithErrorHandling(
    `${MODULE_NAME}/assignStaffProvidersToCareProvider`,
    (payload: AssignStaffProvidersToCareProviderPayload) =>
      organizations.assignStaffProvidersToCareProvider(payload)
  )

export const unassignStaffProvidersFromCareProvider =
  createAsyncThunkWithErrorHandling(
    `${MODULE_NAME}/unassignStaffProvidersFromCareProvider`,
    (payload: UnassignStaffProvidersFromCareProviderPayload) =>
      organizations.unassignStaffProvidersFromCareProvider(payload)
  )

export const getStaffProviderOrganization = createAsyncThunk(
  `${MODULE_NAME}/getStaffProviderOrganization`,
  (payload: number) => organizations.getStaffProviderOrganization(payload)
)

export const getCareProviderOrganization = createAsyncThunk(
  `${MODULE_NAME}/getCareProviderOrganization`,
  (payload: number) => organizations.getCareProviderOrganization(payload)
)

export const addOrganizationStaffing = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/addOrganizationStaffing`,
  (payload: PostOrganizationStaffing) =>
    organizations.addOrganizationStaffing(payload)
)

export const getOrganizationsByNameUniquenesValidation = createAsyncThunk(
  `${MODULE_NAME}/getOrganizationsByNameUniquenesValidation`,
  (payload: string) =>
    organizations.getOrganizations({
      strictSearch: payload,
      searchBy: OrganizationSearchBy.Name,
      showOnlyOrganizationsWithActiveAccounts: false,
    })
)

export const resetGetOrganizationsByNameUniquenesValidation = createAction(
  `${MODULE_NAME}/resetGetOrganizationsByNameUniquenesValidation`
)

export const getOrganizationsByOrganizationIdUniquenesValidation =
  createAsyncThunk(
    `${MODULE_NAME}/getOrganizationsByOrganizationIdUniquenesValidation`,
    (payload: string) =>
      organizations.getOrganizations({
        strictSearch: payload,
        searchBy: OrganizationSearchBy.OrganizationNumber,
        showOnlyOrganizationsWithActiveAccounts: false,
      })
  )

export const resetGetOrganizationsByOrganizationIdUniquenesValidation =
  createAction(
    `${MODULE_NAME}/resetGetOrganizationsByOrganizationIdUniquenesValidation`
  )

export const addOrganizationScheduling = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/addOrganizationScheduling`,
  (payload: PostOrganizationScheduling) =>
    organizations.addOrganizationScheduling(payload)
)

export const getOrganizationsDetails = createAsyncThunk(
  `${MODULE_NAME}/getOrganizationsDetails`,
  (payload: GetOrganizationsDetailsParams) =>
    organizations.getOrganizationsDetails(payload)
)
