import { RootState } from 'app'

export const getStaffProviderResourceDetailsCount = (state: RootState) =>
  state.system.consultants.getStaffProviderResourceDetails.meta?.count

export const getStaffProviderResourceDetails = (state: RootState) =>
  state.system.consultants.getStaffProviderResourceDetails

export const getDeleteStaffProviderResource = (state: RootState) =>
  state.system.consultants.deleteStaffProviderResource

export const getAllStaffProvidersResourceDetails = (state: RootState) =>
  state.system.consultants.getAllStaffProvidersResourceDetails

export const getCreateResource = (state: RootState) =>
  state.system.consultants.createResource
