import { Overrides } from '@material-ui/core/styles/overrides'
import palette from './palette'
import spacing from './spacing'

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      '*': {
        '&:focus': {
          outline: 'none',
        },
        '-webkit-tap-highlight-color': 'transparent',
      },
      '#root': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: spacing(4),
    },
  },
  MuiInputBase: {
    input: {
      padding: spacing(1, 0.5, 1.5),
    },
    inputAdornedStart: {
      paddingLeft: 0,
    },
    inputAdornedEnd: {
      paddingRight: 0,
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: '0.625rem',
      letterSpacing: 1.5,
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    shrink: {
      transform: 'translate(0, 5px) scale(1)',
    },
  },
  MuiButton: {
    text: {
      padding: 0,
    },
  },
  MuiFormLabel: {
    root: {
      textTransform: 'uppercase',
      fontSize: '0.75rem',
    },
  },
  MuiLink: {
    root: {
      textTransform: 'uppercase',
    },
  },
  MuiBadge: {
    root: {
      color: palette.primary.contrastText,
    },
    badge: {
      height: '1.125rem',
      minWidth: '1.125rem',
      fontSize: '0.625rem',
    },
  },
  MuiAvatar: {
    root: {
      fontSize: '0.75rem',
    },
    colorDefault: {
      color: palette.neutral.contrastText,
      backgroundColor: palette.neutral.main,
    },
  },
  MuiTabs: {
    scroller: {
      borderBottomWidth: 1,
      borderBottomColor: palette.neutral.main,
      borderBottomStyle: 'solid',
      padding: spacing(0, 3),
    },
  },
  MuiTableContainer: {
    root: {
      padding: spacing(3, 5, 5),
    },
  },
  MuiTableCell: {
    root: {
      borderBottomWidth: 0,
      fontSize: '0.875rem',
    },
    head: {
      fontWeight: 500,
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      color: palette.neutral.contrastText,
      paddingBottom: spacing(1),
    },
    body: {
      padding: spacing(2),
      minWidth: 15,
    },
    sizeSmall: {
      maxHeight: 10,
      padding: spacing(1, 2),
      '&.MuiTableCell-body': {
        borderBottomWidth: 1,
      },
    },
  },
  // @ts-ignore
  MuiAutocomplete: {
    root: {
      '& .MuiSvgIcon-root': {
        marginRight: spacing(1),
      },
    },
    input: {
      padding: '0 !important',
    },
    inputRoot: {
      padding: `${spacing(1, 6.5, 1.5, 1.5)} !important`,
    },
  },
  MuiDropzoneArea: {
    root: {
      minHeight: '3.5rem',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      fontSize: '0.875rem',
      color: palette.text.secondary,
    },
    icon: {
      paddingRight: spacing(2),
    },
  },
  MuiDropzonePreviewList: {
    root: {
      margin: 0,
      padding: spacing(0, 3),
    },
    imageContainer: {
      padding: `0 !important`,
      maxWidth: '6.25rem',
    },
    image: {
      width: '3rem',
      height: '3rem',
    },
    removeButton: {
      minHeight: '1.375rem !important',
      height: '1.375rem !important',
      width: '1.375rem !important',
      '& svg': {
        width: '1.25rem',
        height: '1.25rem',
      },
    },
  },
  MuiStepper: {
    root: {
      padding: 0,
      backgroundColor: 'transparent',
    },
  },
  MuiChip: {
    label: {
      marginRight: spacing(1.5),
    },
  },
  MuiCheckbox: {
    root: {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 1,
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: spacing(0.5),
          left: spacing(0.5),
          right: spacing(0.5),
          bottom: spacing(0.5),
          background: 'white',
          zIndex: -1,
        },
      },
    },
  },
  MuiFab: {
    root: {
      backgroundColor: palette.background.default,
      boxShadow: 'none',
      '& .MuiFab-label': {
        color: palette.primary.main,
      },
    },
  },
}

export default overrides
