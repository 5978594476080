import Api from '../api'
import {
  GetAccountParams,
  Account,
  PostAccount,
  PutAccount,
  GetAccountsDetailsParams,
  GetAccountsDetailsResponse,
  ActivateDeactivateAccountParams,
  ActivateDeactivateAccountResponse,
  GetAllAccountsDetailsParams,
  ResetLoginInfoParams,
  ResetLoginInfoResponse,
  ChangeAccountStatusParams,
  ChangeAccountStatusResponse,
  DeleteAccountParams,
  DeleteAccountResponse,
  GetMeResponse,
} from './Accounts.types'

class Accounts extends Api {
  public getMe = async () => {
    const { data } = await this.api.get<GetMeResponse>('/accounts/me')
    return data
  }

  public deleteAccount = async ({ accountIds }: DeleteAccountParams) => {
    const { data } = await this.api.delete<DeleteAccountResponse>(
      `/admin/accounts/delete/${accountIds}`
    )
    return data
  }
  public changeAccountStatus = async ({
    action,
    ...payload
  }: ChangeAccountStatusParams) => {
    const { data } = await this.api.post<ChangeAccountStatusResponse>(
      `/admin/accounts/${action}`,
      payload
    )
    return data
  }
  public activateAccount = async (payload: ActivateDeactivateAccountParams) => {
    const { data } = await this.api.post<ActivateDeactivateAccountResponse>(
      `/admin/accounts/activate`,
      payload
    )

    return data
  }
  public deactivateAccount = async (
    payload: ActivateDeactivateAccountParams
  ) => {
    const { data } = await this.api.post<ActivateDeactivateAccountResponse>(
      `/admin/accounts/deactivate`,
      payload
    )

    return data
  }
  public getAllAccountsDetails = async (
    params: GetAllAccountsDetailsParams
  ) => {
    const { data } = await this.api.get<GetAccountsDetailsResponse>(
      '/admin/accounts/details',
      { params }
    )
    return data
  }
  public getAccountsDetails = async (params: GetAccountsDetailsParams) => {
    const response = await this.api.get<GetAccountsDetailsResponse>(
      '/admin/accounts/details',
      { params }
    )
    const { data, ...meta } = response.data

    return { data, meta }
  }
  public getAccount = async ({ id }: GetAccountParams) => {
    const { data } = await this.api.get<Account>(`/admin/accounts/${id}`)
    return data
  }
  public addAccount = async ({ ...payload }: PostAccount) => {
    const { data } = await this.api.post<number>('/admin/accounts', payload)
    return data
  }

  public putAccount = async ({ id, ...payload }: PutAccount) => {
    const { data } = await this.api.put<Account>(
      `/admin/accounts/${id}`,
      payload
    )
    return data
  }

  public resetLoginInfo = async ({
    accountId,
    ...params
  }: ResetLoginInfoParams) => {
    const { data } = await this.api.post<ResetLoginInfoResponse>(
      `/admin/accounts/${accountId}/reset-password`,
      null,
      { params }
    )
    return data
  }
}

export default Accounts
