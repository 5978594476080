import email from './email'
import faq from './faq'
import home from './home'
import informations from './informations'
import sms from './sms'
import system from './system'

export const routes = [
  // Add routes below
  ...home.routes,
  ...email.routes,
  ...sms.routes,
  ...informations.routes,
  ...faq.routes,
  ...system.routes,
]

export const reducers = {
  // Add reducers below
  [faq.name]: faq.reducer,
  [home.name]: home.reducer,
  [email.name]: email.reducer,
  [sms.name]: sms.reducer,
  [informations.name]: informations.reducer,
  [system.name]: system.reducer,
}
