import { SvgIconComponent } from '@material-ui/icons'
import { Routes } from '@vivium/frontend-common/components'
import { ModuleRoute } from '@vivium/frontend-common/types'
import { useState } from 'react'
import Drawer from './Drawer'
import { Container, Content } from './Layout.style'
import { useCurrentLayoutSettings, useNavItems } from './Layout.utils'
import Topbar from './Topbar'

export interface LayoutNavItem {
  path: string
  label: string
  icon?: SvgIconComponent
}

export interface LayoutProps {
  routes: ModuleRoute[]
  modalRoutes: ModuleRoute[]
  hideDrawer?: boolean
  hideTopbar?: boolean
}

const Layout = ({ routes, modalRoutes }: LayoutProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const navItems = useNavItems()
  const { drawerProps = {}, topbarProps = {} } =
    useCurrentLayoutSettings(routes)

  const handleDrawerToggle = () => setIsDrawerOpen(open => !open)
  const handleDrawerClose = () => setIsDrawerOpen(false)

  return (
    <Container>
      <Topbar onDrawerToggle={handleDrawerToggle} {...topbarProps} />
      <Drawer
        navItems={navItems}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        {...drawerProps}
      />
      <Content withTopbarFix={!topbarProps.hidden}>
        <Routes routes={routes} modalRoutes={modalRoutes} />
      </Content>
    </Container>
  )
}

export default Layout
