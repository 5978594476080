import {
  styled,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { ErrorSharp } from '@material-ui/icons'

export const ModalContainer = styled(Dialog)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backdropFilter: 'blur(8px)',
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  '& .MuiDialogTitle-root': {
    padding: 0,
  },
}))

export const ContentWrapper = styled(DialogContent)(({ theme }) => ({
  maxHeight: '100vh',
  padding: theme.spacing(5),
  paddingBottom: theme.spacing(4),
}))

export const HeaderWrapper = styled(DialogTitle)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

export const WarningIcon = styled(ErrorSharp)(({ theme }) => ({
  fontSize: '10rem',
  fill: theme.palette.primary.main,
}))

export const RecipientsWrapper = styled(Grid)(({ theme }) => ({
  textAlign: 'left',
  '& p:first-child': {
    paddingBottom: theme.spacing(3),
  },
}))

export const ActionsWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(5),
  '& button': {
    padding: theme.spacing(1, 5),
  },
}))
