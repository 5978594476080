import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import { Account } from 'shared/types/Suggestions'
import { getAccountsSuggestions } from './actions'

export interface State {
  getAccounts: Resource<Account[]>
}

const initialState = {
  getAccounts: resource.getInitial<Account[]>([]),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getAccountsSuggestions.pending, state => {
      resource.setPending(state.getAccounts)
    })
    .addCase(getAccountsSuggestions.fulfilled, (state, action) => {
      resource.setSucceeded(state.getAccounts, action.payload)
    })
    .addCase(getAccountsSuggestions.rejected, (state, action) => {
      resource.setFailed(state.getAccounts, action.error.message)
    })
)
