import { Entity } from '@vivium/frontend-common/types'
import {
  SortingOrder,
  SortingParams,
  FilteringParams,
} from '@vivium/frontend-common/types'

export interface CareProvider extends Entity {}

export interface OrganizationGroupAdmin extends Entity {
  title: string
  isActive: boolean
}

export interface CpOrganizationGroup extends Entity {
  organizationsCount: number
  careProviders: Entity[]
  admins: OrganizationGroupAdmin[]
}

export interface GetCpOrganizationGroupResponse extends CpOrganizationGroup {
  admins: OrganizationGroupAdmin[]
}

export interface GetCpOrganizationGroupParams {
  groupId: number
}

export interface CpOrganizationGroupAdmins extends Entity {
  title: string
  email: string
  phoneNumber: string
  isActive: boolean
}

export interface GetCpOrganizationGroupAdminsResponse {
  admins: CpOrganizationGroupAdmins[]
}
export interface GetCpOrganizationGroupAdminsParams extends FilteringParams {
  organizationGroupId: number
}

export enum GroupSortBy {
  Name = 'name',
  OrganizationsCount = 'organizationsCount',
}

export interface GetCpOrganizationGroupsParams {
  sortOrder: SortingOrder
  sortBy: GroupSortBy
}

export interface PostCpOrganizationGroup {
  name: string
  organizationIds?: number[]
  adminIds?: number[]
}

export interface PutCpOrganizationGroup {
  id: number
  name: string
  organizationIds: number[]
  adminIds: number[]
}

export interface GetCpOrganizationGroupsResponse {
  data: CpOrganizationGroup[]
}

export interface OrganizationGroupSuggestion extends Entity {}

export interface GetCpOrganizationGroupsSuggestionsResponse {
  data: OrganizationGroupSuggestion[]
}

export interface GetCpOrganizationGroupsSuggestionsParams
  extends SortingParams,
    FilteringParams {}
