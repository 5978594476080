import Api from '../api'
import {
  PostMessageUpdate,
  GetMessageUpdatesResponse,
  GetMessageUpdatesParams,
  MessageUpdatePutParams,
  MessageUpd,
} from './MessageUpdate.types'

class MessageUpdate extends Api {
  public getMessagesUpdates = async (params?: GetMessageUpdatesParams) => {
    const { data } = await this.api.get<GetMessageUpdatesResponse>(
      '/admin/system-messages/update',
      { params }
    )
    return data
  }
  public addMessageUpdate = async ({ ...payload }: PostMessageUpdate) => {
    const { data } = await this.api.post<number>(
      '/admin/system-messages/update',
      payload
    )
    return data
  }

  public getMessageUpdate = async (messageId: number) => {
    const { data } = await this.api.get<MessageUpd>(
      `/admin/system-messages/update/${messageId}`
    )
    return data
  }

  public putMessageUpdate = async ({
    id,
    ...payload
  }: MessageUpdatePutParams) => {
    const { data } = await this.api.put<MessageUpd>(
      `/admin/system-messages/update/${id}`,
      payload
    )
    return data
  }

  public deleteMessageUpdate = async (id: number) => {
    const { data } = await this.api.delete<number>(
      `/admin/system-messages/${id}`
    )
    return data
  }
}

export default MessageUpdate
