import { TableBody, TableCell, Grid } from '@material-ui/core'
import { EditOutlined, DeleteForeverOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { ModulePill } from 'shared/components'
import { UseModal } from 'shared/hooks'
import { MessageUpd } from 'shared/services/messageUpdate'
import { Messages, ModalTypes, ModulePillTypes } from 'shared/types'
import { AccessibleApplications } from 'shared/types'
import { DeleteActualizationModal, InformationStatusPill } from '../../'
import { MODULE_NAME } from '../../../strings'
import { OpinionsStatisticsChart } from '../OpinionsStatisticsChart'
import {
  StyledTableRow,
  ActionsWrapper,
  PillWrapper,
} from './UpdateTableBody.styles'
import { formatDisplayedDate } from './UpdateTableBody.utils'

type AccessibleApplicationsOmitAllProp = Omit<AccessibleApplications, 'All'>

interface UpdateTableBodyParams {
  messagesUpdates: MessageUpd[]
  modalType: ModalTypes | null
  id: number
  isModalOpen: boolean
  openModal: UseModal['openModal']
  onCloseModal: () => void
}

const UpdateTableBody = ({
  messagesUpdates,
  modalType,
  id,
  isModalOpen,
  onCloseModal,
  openModal,
}: UpdateTableBodyParams) => {
  const { t } = useTranslation(MODULE_NAME)

  const shouldDeleteModalBeVisible =
    modalType === ModalTypes.Delete && isModalOpen

  return (
    <TableBody>
      {messagesUpdates.map(
        ({
          accessibleApplications,
          status,
          id: updateId,
          startDate,
          title,
          views,
          feedback,
          feedbackDetails,
        }) => (
          <StyledTableRow key={updateId}>
            <TableCell>
              <p onClick={e => openModal(e, ModalTypes.Preview, updateId)}>
                {title}
              </p>
            </TableCell>
            <TableCell>
              <InformationStatusPill
                label={t(`statusPills.${status}`)}
                status={status}
              />
            </TableCell>
            <TableCell>{formatDisplayedDate(startDate)}</TableCell>
            <TableCell>
              <Grid container direction="column">
                {accessibleApplications.map(
                  (module: AccessibleApplicationsOmitAllProp) => (
                    <PillWrapper key={`key-${updateId}-${module}`}>
                      <ModulePill
                        label={t(`accessibleApplications.${module}`)}
                        pillType={module as ModulePillTypes}
                      />
                    </PillWrapper>
                  )
                )}
              </Grid>
            </TableCell>
            <TableCell>{`${views.percentage} % (${views.totalAccountsCount}st)`}</TableCell>
            <TableCell>{`${feedback.percentage} % (${feedback.totalReadCount})`}</TableCell>
            <TableCell>
              <OpinionsStatisticsChart feedbackData={feedbackDetails} />
            </TableCell>
            <TableCell>
              <ActionsWrapper
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                <EditOutlined
                  onClick={e => openModal(e, ModalTypes.Update, updateId)}
                />
                <DeleteForeverOutlined
                  onClick={e => openModal(e, ModalTypes.Delete, updateId)}
                />
              </ActionsWrapper>
              {shouldDeleteModalBeVisible && updateId === id && (
                <DeleteActualizationModal
                  onCloseModal={onCloseModal}
                  id={updateId}
                  messageType={Messages.Update}
                />
              )}
            </TableCell>
          </StyledTableRow>
        )
      )}
    </TableBody>
  )
}

export default UpdateTableBody
