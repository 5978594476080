import { LoadingStatus } from '@vivium/frontend-common/types'
import { RootState } from 'app'
import { SystemRole } from 'shared/types'

export const updateFaq = (state: RootState) => state.faq.updateFaq

export const updateFaqLoading = (state: RootState) =>
  state.faq.updateFaq.loading === LoadingStatus.Pending

export const getFaq = (state: RootState) => state.faq.getFaq

export const getFaqLoading = (state: RootState) =>
  state.faq.getFaq.loading === LoadingStatus.Pending

export const getFaqs = (state: RootState) => state.faq.getFaqs

export const getFaqsLoading = (state: RootState) =>
  state.faq.getFaqs.loading === LoadingStatus.Pending

export const getFaqsData = (state: RootState) => state.faq.getFaqs.data

export const getFaqsByRole = (role: SystemRole) => (state: RootState) => {
  const data = state.faq.getFaqs.data.filter(
    ({ roleGroups }) => roleGroups.includes(role) || roleGroups.length === 0
  )
  return {
    ...state.faq.getFaqs,
    data,
  }
}

export const addFaq = (state: RootState) => state.faq.addFaq

export const addFaqLoading = (state: RootState) =>
  state.faq.addFaq.loading === LoadingStatus.Pending

export const deleteFaqLoading = (state: RootState) =>
  state.faq.deleteFaq.loading === LoadingStatus.Pending
