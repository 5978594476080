import {
  NewActualization,
  InformationsSection,
  UpdatesSection,
} from '../components'

const Informations = () => {
  return (
    <>
      <NewActualization />
      <InformationsSection />
      <UpdatesSection />
    </>
  )
}

export default Informations
