import { createAsyncThunk } from '@reduxjs/toolkit'
import Recipients, { GetRecipientsParams } from 'shared/services/recipients'
import { NAMESPACE } from '../../strings'

const recipients = new Recipients()

export const getRecipients = createAsyncThunk(
  `${NAMESPACE}/getRecipients`,
  (payload?: GetRecipientsParams) => recipients.getRecipients(payload)
)
