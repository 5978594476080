import { LoadingStatus } from '@vivium/frontend-common/types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from 'shared/services/organizations'
import { actions, selectors } from 'shared/store'
import { OrganizationType } from 'shared/types'

export const useGetOrganizations = (organizationType: OrganizationType) => {
  const dispatch = useDispatch()
  const {
    data: organizations,
    loading,
    error,
  } = useSelector(selectors.organizations.getOrganizations)
  const organizationsOptions = useAutocompleteOptions(organizations)

  useEffect(() => {
    dispatch(
      actions.organizations.getOrganizations({
        organizationType,
        showOnlyOrganizationsWithActiveAccounts: false,
      })
    )
  }, [organizationType]) //eslint-disable-line

  return {
    organizationsOptions,
    organizations,
    isLoading: loading === LoadingStatus.Pending,
    error,
  }
}

interface CommonOption {
  label: string
  value: number
  data?: string
}

export const modifyDataForAutocomplete = (
  organizations: Organization[],
  commonOption: CommonOption
) => {
  const options = organizations.map(({ name, id, type }) => {
    return {
      label: name,
      value: id,
      ...(!!type && {
        data: type,
      }),
    }
  })
  if (organizations.length === 1) {
    return options
  }
  options.unshift(commonOption)
  return options
}

export const useAutocompleteOptions = (organizations: Organization[]) => {
  const { t } = useTranslation()
  const label = t('organizationType.all')
  const common = {
    label: label,
    value: 0,
  }

  return organizations
    ? modifyDataForAutocomplete(organizations, common)
    : [common]
}
