import { RootState } from 'app'

export const unassignCareProvidersFromStaffProvider = (state: RootState) =>
  state.system.organizations.unassignCareProvidersFromStaffProvider

export const assignCareProvidersToStaffProvider = (state: RootState) =>
  state.system.organizations.assignCareProvidersToStaffProvider

export const unassignStaffProvidersFromCareProvider = (state: RootState) =>
  state.system.organizations.unassignStaffProvidersFromCareProvider

export const assignStaffProvidersToCareProvider = (state: RootState) =>
  state.system.organizations.assignStaffProvidersToCareProvider

export const getCareProviderOrganization = (state: RootState) =>
  state.system.organizations.getCareProviderOrganization

export const getStaffProviderOrganization = (state: RootState) =>
  state.system.organizations.getStaffProviderOrganization

export const getOrganizationsDetails = (state: RootState) =>
  state.system.organizations.getOrganizationsDetails

export const addOrganizationScheduling = (state: RootState) =>
  state.system.organizations.addOrganizationScheduling

export const addOrganizationStaffing = (state: RootState) =>
  state.system.organizations.addOrganizationStaffing

export const isOrganizationNameUnique = (state: RootState) =>
  state.system.organizations.getOrganizationsByNameUniquenesValidation.data

export const isOrganizationNumberUnique = (state: RootState) =>
  state.system.organizations.getOrganizationsByOrganizationIdUniquenesValidation
    .data
