import { UserRole } from '@vivium/frontend-common/types'
import { generatePathWithQuery } from '@vivium/frontend-common/utils'
import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { paths } from 'config'
import { actions, selectors } from '../store'

const INITIAL_PATH = paths.email

export const useSignIn = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const data = useSelector(selectors.auth.getSignIn)

  const signIn = useCallback(
    (email: string, password: string) => {
      const onNewPasswordRequired = (token?: string) =>
        history.push(
          generatePathWithQuery(paths.activateAccount, {}, { token })
        )
      const onMFARequired = (token?: string) =>
        history.push(
          generatePathWithQuery(paths.multifactorAuthentication, {}, { token })
        )
      return dispatch(
        actions.auth.signIn({
          params: {
            email,
            password,
            onNewPasswordRequired,
            onMFARequired,
          },
        })
      )
    },
    [dispatch, history]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetSignIn()),
    [dispatch]
  )

  return { ...data, signIn, resetState }
}

export const useSignOut = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getSignOut)

  const signOut = useCallback(
    () => dispatch(actions.auth.signOut({ params: {} })),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetSignOut()),
    [dispatch]
  )

  return { ...data, signOut, resetState }
}

export const useResetPassword = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getResetPassword)

  const resetPassword = useCallback(
    (email: string) =>
      dispatch(actions.auth.resetPassword({ params: { email } })),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetResetPassword()),
    [dispatch]
  )

  return { ...data, resetPassword, resetState }
}

export const useSetNewPassword = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getSetNewPassword)

  const setNewPassword = useCallback(
    (password: string, token: string) =>
      dispatch(actions.auth.setNewPassword({ params: { token, password } })),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetSetNewPassword()),
    [dispatch]
  )

  return { ...data, setNewPassword, resetState }
}

export const useConfirmForgotPassword = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getConfirmForgotPassword)

  const confirmForgotPassword = useCallback(
    (password: string, token: string) =>
      dispatch(
        actions.auth.confirmForgotPassword({ params: { token, password } })
      ),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetConfirmForgotPassword()),
    [dispatch]
  )

  return { ...data, confirmForgotPassword, resetState }
}

export const useResolveChallenge = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getResolveChallenge)

  const resolveChallenge = useCallback(
    (code: string, token: string) =>
      dispatch(actions.auth.resolveChallenge({ params: { token, code } })),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetResolveChallenge()),
    [dispatch]
  )

  return { ...data, resolveChallenge, resetState }
}

export const useAuth = () => {
  const user = useSelector(selectors.auth.getUser)
  const loading = useSelector(selectors.auth.getAuthLoading)
  const error = useSelector(selectors.auth.getAuthError)

  const isAuthenticated = user?.role === UserRole.Admin

  return {
    user,
    isAuthenticated,
    initialPath: INITIAL_PATH,
    loading,
    error,
  }
}

export const useCurrentUser = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actions.auth.getCurrentUser({ params: {} }))
    }
  }, [isAuthenticated, dispatch])
}
