import Api from '../api'
import {
  GetTagsParams,
  GetTagsResponse,
  CreateTagParams,
  CreateTagResponse,
  GetCareProviderTagsSuggestionsParams,
  GetCareProviderTagsSuggestionsResponse,
} from './Tags.types'

class Tags extends Api {
  public async getCareProviderTags({
    careProviderId,
    ...params
  }: GetCareProviderTagsSuggestionsParams) {
    const { data } = await this.api.get<GetCareProviderTagsSuggestionsResponse>(
      `/mock/care-providers/${careProviderId}/suggestions/tags`,
      { params }
    )
    return data.data
  }
  public async getTags({ careProviderId, ...params }: GetTagsParams) {
    const response = await this.api.get<GetTagsResponse>(
      // TODO: remove mock
      `/mock/care-providers/${careProviderId}/tags`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }
  public createTag = async ({
    careProviderId,
    ...payload
  }: CreateTagParams) => {
    const { data } = await this.api.post<CreateTagResponse>(
      `/mock/care-providers/${careProviderId}/tags`,
      payload
    )
    return data
  }
}

export default Tags
