import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { createAsyncThunkWithErrorHandling } from '@vivium/frontend-common/utils'
import { AxiosError } from 'axios'
import {
  GetCareProviderRolesDetailsParams,
  CareProviderRoles,
  AssignRoleToCpParams,
} from 'shared/services/careProviderRoles'
import {
  CareProviderSpecialties,
  AssignSpecialtiesParams,
} from 'shared/services/careProviderSpecialties'
import {
  SystemRoles,
  PostSystemRole,
  UpdateRoleParams,
  AssignSpecialtiesToSystemRoleParams,
  GetSystemRolesWithSpecialitiesParams,
} from 'shared/services/systemRoles'
import {
  SystemSpecializations,
  PostSystemSpecialization,
  GetSystemSpecializationsParams,
  GetSpecialtiesSuggestionsParams,
} from 'shared/services/systemSpecializations'
import { MODULE_NAME } from '../../strings'
import {
  OpenValidationErrorModalOrgScopePayload,
  DeleteSystemRolePayload,
  validationModalErrorCodes,
  UnassignSpecialtyFromCareProviderPayload,
  UnassignSpecialtyFromSystemRolePayload,
  UnassignRoleFromCareProviderPayload,
  DeleteSystemSpecializationPayload,
  OpenValidationErrorModalPayload,
} from './action.types'

const systemRoles = new SystemRoles()
const systemSpecializations = new SystemSpecializations()
const careProviderRoles = new CareProviderRoles()
const careProviderSpecialties = new CareProviderSpecialties()

export const assignSpecialtiesToCareProvider =
  createAsyncThunkWithErrorHandling(
    `${MODULE_NAME}/assignSpecialtiesToCareProvider`,
    (payload: AssignSpecialtiesParams) =>
      careProviderSpecialties.careProviderSpecialtiesAssign(payload)
  )

export const assignRoleToCareProvider = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/assignRoleToCareProvider`,
  (payload: AssignRoleToCpParams) =>
    careProviderRoles.careProviderRolesAssign(payload)
)

export const getCareProviderRolesWithSpecialties = createAsyncThunk(
  `${MODULE_NAME}/getCareProviderRolesWithSpecialties`,
  (payload: number) => {
    return careProviderRoles.getCareProviderRolesWithSpecialties(payload)
  }
)

export const getCareProviderRolesDetails = createAsyncThunk(
  `${MODULE_NAME}/getCareProviderRolesDetails`,
  (payload: GetCareProviderRolesDetailsParams) => {
    return careProviderRoles.getCareProviderRolesDetails(payload)
  }
)

export const getSystemRoles = createAsyncThunk(
  `${MODULE_NAME}/getSystemRoles`,
  () => systemRoles.getSystemRoles()
)

export const addSystemRole = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/addSystemRole`,
  (payload: PostSystemRole) => systemRoles.addSystemRole(payload)
)

export const updateRole = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/updateRole`,
  (payload: UpdateRoleParams) => systemRoles.updateSystemRole(payload)
)

export const getSystemRolesWithSpecialties = createAsyncThunk(
  `${MODULE_NAME}/getSystemRolesWithSpecialties`,
  (payload: GetSystemRolesWithSpecialitiesParams) =>
    systemRoles.getSystemRolesWithSpecialties(payload)
)

export const getSystemSpecializationsByRoleId = createAsyncThunk(
  `${MODULE_NAME}/getSystemSpecializationsByRoleId`,
  (payload?: GetSystemSpecializationsParams) =>
    systemSpecializations.getSystemSpecializations(payload)
)

export const getSystemSpecializations = createAsyncThunk(
  `${MODULE_NAME}/getSystemSpecializations`,
  (payload?: GetSystemSpecializationsParams) =>
    systemSpecializations.getSystemSpecializations(payload)
)

export const getSpecializationsSuggestions = createAsyncThunk(
  `${MODULE_NAME}/getSpecializationsSuggestions`,
  (payload: GetSpecialtiesSuggestionsParams) =>
    systemSpecializations.getSpecializationsSuggestions(payload)
)

export const addSystemSpecialization = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/addSystemSpecialization`,
  (payload: PostSystemSpecialization) =>
    systemSpecializations.addSystemSpecialization(payload)
)

export const deleteSystemSpecialization = createAsyncThunk(
  `${MODULE_NAME}/deleteSystemSpecialization`,
  async ({
    params,
    onSuccess,
    onFailure,
  }: DeleteSystemSpecializationPayload) => {
    try {
      const data = await systemSpecializations.deleteSystemSpecialization(
        params
      )
      onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const errorCode = error?.response?.data.code
      validationModalErrorCodes.includes(errorCode)
        ? onFailure(error.response?.data)
        : onFailure()
    }
  }
)

export const deleteSystemRole = createAsyncThunk(
  `${MODULE_NAME}/deleteSystemRole`,
  async ({ params, onSuccess, onFailure }: DeleteSystemRolePayload) => {
    try {
      const data = await systemRoles.deleteSystemRole(params)
      onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const errorCode = error?.response?.data.code
      validationModalErrorCodes.includes(errorCode)
        ? onFailure(error.response?.data)
        : onFailure()
    }
  }
)

export const unassignRoleFromCareProvider = createAsyncThunk(
  `${MODULE_NAME}/unassignRoleFromCareProvider`,
  async ({
    params,
    onSuccess,
    onFailure,
  }: UnassignRoleFromCareProviderPayload) => {
    try {
      const data = await careProviderRoles.careProviderRolesUnassign(params)
      onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const errorCode = error?.response?.data.code
      validationModalErrorCodes.includes(errorCode)
        ? onFailure(error.response?.data)
        : onFailure()
    }
  }
)

export const assignSpecialtiesToSystemRole = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/assignSpecialtiesToSystemRole`,
  (payload: AssignSpecialtiesToSystemRoleParams) =>
    systemRoles.assignSpecialtiesToSystemRole(payload)
)

export const unassignSpecialtyFromSystemRole = createAsyncThunk(
  `${MODULE_NAME}/unassignSpecialtyFromSystemRole`,
  async ({
    params,
    onSuccess,
    onFailure,
  }: UnassignSpecialtyFromSystemRolePayload) => {
    try {
      const data = await systemRoles.unassignSpecialtyFromSystemRole(params)
      onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const errorCode = error?.response?.data.code
      validationModalErrorCodes.includes(errorCode)
        ? onFailure(error.response?.data)
        : onFailure()
    }
  }
)

export const unassignSpecialtiesFromCareProvider = createAsyncThunk(
  `${MODULE_NAME}/unassignSpecialtiesFromCareProvider`,
  async ({
    params,
    onSuccess,
    onFailure,
  }: UnassignSpecialtyFromCareProviderPayload) => {
    try {
      const data =
        await careProviderSpecialties.careProviderSpecialtiesUnassign(params)
      onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const errorCode = error?.response?.data.code
      validationModalErrorCodes.includes(errorCode)
        ? onFailure(error.response?.data)
        : onFailure()
    }
  }
)

export const openValidationErrorModalOrgScoped = createAction(
  `${MODULE_NAME}/openValidationErrorModalOrgScoped`,
  (payload: OpenValidationErrorModalOrgScopePayload) => ({
    payload,
  })
)

export const openValidationErrorModal = createAction(
  `${MODULE_NAME}/openValidationErrorModal`,
  (payload: OpenValidationErrorModalPayload) => ({
    payload,
  })
)

export const closeValidationErrorModal = createAction(
  `${MODULE_NAME}/closeValidationErrorModal`
)
