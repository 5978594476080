import { RootState } from 'app'

export const updateCpOrganizationGroup = (state: RootState) =>
  state.system.organizationGroups.updateCpOrganizationGroup

export const addCpOrganizationGroup = (state: RootState) =>
  state.system.organizationGroups.addCpOrganizationGroup

export const getCpOrganizationGroups = (state: RootState) =>
  state.system.organizationGroups.getCpOrganizationGroups

export const getCpOrganizationGroup = (state: RootState) =>
  state.system.organizationGroups.getCpOrganizationGroup

export const getCpOrganizationGroupAdmins = (state: RootState) =>
  state.system.organizationGroups.getCpOrganizationGroupAdmins

export const getCpOrganizationGroupCareProviders = (state: RootState) =>
  state.system.organizationGroups.getCpOrganizationGroup.data?.careProviders
