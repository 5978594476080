import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormProps,
  AutocompleteValue,
} from '@vivium/frontend-common/components'
import { LoadingStatus } from '@vivium/frontend-common/types'
import { formatISO, addDays } from 'date-fns'
import { RawDraftContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { SchemaOf, object, string, date } from 'yup'
import {
  UpdateSortBy,
  UpdateSortOrder,
  PostMessageUpdate,
} from 'shared/services/messageUpdate'
import { getDefaultTime } from '../../pages'
import {
  convertOptionToAccessibleApplicationArray,
  dateAndTimeToIso,
  useInformationAutocompleteOptions,
} from '../../pages'
import { selectors, actions } from '../../store'

export enum UpdateFields {
  AccessibleApplications = 'accessibleApplications',
  PublicationDate = 'publicationDate',
  PublicationTime = 'publicationTime',
  UpdateMessage = 'updateMessage',
  Title = 'title',
  MessageBody = 'messageBody',
}

export const MAX_MESSAGE_LENGTH = 150
export const MAX_TITLE_LENGTH = 40

export interface UpdateValues {
  [UpdateFields.AccessibleApplications]: AutocompleteValue
  [UpdateFields.PublicationDate]: Date
  [UpdateFields.PublicationTime]: string
  [UpdateFields.UpdateMessage]: string
  [UpdateFields.Title]: string
  [UpdateFields.MessageBody]: RawDraftContentState | string
}

export const useGetUpdateDefaultValues = (): UpdateValues => {
  const { defaultAccessibleApplicationOptions } =
    useInformationAutocompleteOptions()
  const today = new Date()
  const defaultPublicationDate = addDays(today, 1)
  const defaultPublicationTime = getDefaultTime(today)
  return {
    [UpdateFields.AccessibleApplications]: defaultAccessibleApplicationOptions,
    [UpdateFields.PublicationDate]: defaultPublicationDate,
    [UpdateFields.PublicationTime]: defaultPublicationTime,
    [UpdateFields.UpdateMessage]: '',
    [UpdateFields.Title]: '',
    [UpdateFields.MessageBody]: '',
  }
}

export const useUpdateSchema = (): SchemaOf<UpdateValues> => {
  const { t } = useTranslation()
  return object()
    .shape({
      [UpdateFields.PublicationDate]: date()
        .nullable()
        .required(t('validation.required')),
      [UpdateFields.PublicationTime]: string().required(
        t('validation.required')
      ),
      [UpdateFields.UpdateMessage]: string()
        .required(t('validation.required'))
        .max(
          MAX_MESSAGE_LENGTH,
          t('validation.characterLimit', {
            characterLimit: MAX_MESSAGE_LENGTH,
          })
        ),
      [UpdateFields.MessageBody]: object().required(t('validation.required')),
      [UpdateFields.Title]: string()
        .required(t('validation.required'))
        .max(
          MAX_TITLE_LENGTH,
          t('validation.characterLimit', {
            characterLimit: MAX_TITLE_LENGTH,
          })
        ),
      [UpdateFields.Title]: string().required(t('validation.required')),
    })
    .required()
}

const useValidationSchema = (): SchemaOf<UpdateValues> => {
  const updateSchema = useUpdateSchema()

  return object().shape({
    ...updateSchema.fields,
  })
}

const useGetDefaultValues = (): UpdateValues => {
  const updateDefaultValues = useGetUpdateDefaultValues()
  return {
    ...updateDefaultValues,
  }
}

export const useOnSubmit = (onCloseModal: () => void) => {
  const { loading } = useSelector(selectors.addMessageUpdate)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onError = (message?: string) =>
    enqueueSnackbar(message || t('error.defaultMessage'), {
      variant: 'error',
    })

  const onSuccess = (message?: string) => {
    dispatch(
      actions.getMessagesUpdates({
        sortBy: UpdateSortBy.CreatedAt,
        sortOrder: UpdateSortOrder.Descending,
      })
    )
    onCloseModal()
    return enqueueSnackbar(message || t('messages.addUpdateSuccess'), {
      variant: 'success',
    })
  }
  const isSubmitting = loading === LoadingStatus.Pending

  const handleSubmit = (values: UpdateValues) => {
    const startDate = dateAndTimeToIso(
      formatISO(values[UpdateFields.PublicationDate]),
      values[UpdateFields.PublicationTime]
    )

    const payload: PostMessageUpdate = {
      accessibleApplications: convertOptionToAccessibleApplicationArray(
        values[UpdateFields.AccessibleApplications]?.value
      ),
      startDate,
      title: values[UpdateFields.Title],
      body: draftToHtml(
        values[UpdateFields.MessageBody] as RawDraftContentState
      ),
      message: values[UpdateFields.UpdateMessage],
    }
    return dispatch(actions.addMessageUpdate({ onError, onSuccess, payload }))
  }

  return { isSubmitting, handleSubmit }
}

export const useFormProps = (): Omit<FormProps<UpdateValues>, 'onSubmit'> => {
  const schema = useValidationSchema()
  const defaultValues = useGetDefaultValues()
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  return methods
}
