export const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      height="36"
      version="1.1"
      viewBox="0 0 1181.333 320"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="clipPath32" clipPathUnits="userSpaceOnUse">
          <path d="M0 2400h2080V0H0z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 320)">
        <g transform="scale(.1)">
          <path
            fill="#292424"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2876.45 700.422L2420 1739.59h228.22l329.89-777.852L3308 1739.59h222L3073.55 700.422h-197.1"
          ></path>
          <path
            fill="#292424"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3949.14 1739.59V700.422h-207.48V1739.59h207.48"
          ></path>
          <path
            fill="#292424"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4616.74 700.422L4160.29 1739.59h228.23l329.89-777.852 329.88 777.852h222L4813.85 700.422h-197.11"
          ></path>
          <path
            fill="#292424"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M5689.43 1739.59V700.422h-207.48V1739.59h207.48"
          ></path>
          <path
            fill="#292424"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M6220.1 1739.59v-616.57c0-81.66 23.51-146.309 70.54-193.95 48.41-47.629 112.04-71.449 190.88-71.449 55.33 0 105.12 11.57 149.38 34.711 44.27 24.488 80.92 58.52 109.97 102.066v745.192h207.47V700.422h-207.47v104.117c-41.49-40.828-89.22-72.141-143.16-93.918-52.56-20.41-110.66-30.621-174.28-30.621-120.34 0-219.24 38.109-296.69 114.328-76.08 76.223-114.12 174.211-114.12 293.992v651.27h207.48"
          ></path>
          <path
            fill="#292424"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M7276.95 700.422V1739.59h207.48v-98c38.73 38.11 82.99 67.37 132.78 87.79 49.8 20.41 104.43 30.62 163.91 30.62 70.54 0 134.17-15.65 190.88-46.96 56.71-29.94 101.66-72.13 134.86-126.58 42.88 57.17 94.05 100.04 153.53 128.63 60.86 29.94 128.64 44.91 203.33 44.91 116.19 0 210.93-38.11 284.24-114.33 74.69-74.86 112.04-172.17 112.04-291.95V700.422h-207.48v618.598c0 81.66-22.13 145.63-66.39 191.91-42.88 47.63-101.66 71.45-176.36 71.45-51.17 0-97.51-11.57-139-34.7-41.5-23.14-77.46-57.85-107.89-104.13 2.76-13.61 4.83-28.58 6.22-44.91 1.39-14.97 2.08-29.94 2.08-44.92V700.422h-205.4v618.598c0 81.66-22.14 145.63-66.4 191.91-42.88 47.63-101.67 71.45-176.35 71.45-49.8 0-94.75-10.88-134.86-32.66-40.12-20.42-74.69-51.73-103.74-93.92V700.422h-207.48"
          ></path>
          <g>
            <g
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              clipPath="url(#clipPath32)"
            >
              <path
                fill="#f57956"
                d="M1323.75 1043.17l-446.715 191.76c-32.777 13.95-54.625 46.72-54.625 82.53v717.26c0 33.38-20.027 63.72-50.984 77.07l-654.895 281.57C61.305 2417.03 0 2376.37 0 2316.29v-875.04c0-33.38 19.422-63.11 49.77-76.46l432.144-190.55c42.484-18.81 70.406-60.68 70.406-107.4V373.23c0-33.371 19.422-63.109 49.77-76.46L1256.98 7.922c55.23-24.274 117.75 16.387 117.75 76.457V966.71c0 33.369-20.03 63.719-50.98 76.459"
              ></path>
              <path
                fill="#3da1ab"
                d="M2003.52 944.219l-424.86 187.511c-19.43 8.5-32.17 27.92-32.17 49.76v567.39c0 38.83 40.06 65.53 75.87 49.75l424.86-182.65c20.03-8.49 32.77-27.91 32.77-49.76V993.98c0-39.441-40.66-65.531-76.47-49.761"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
