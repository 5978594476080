import { Typography, Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useModal } from '@vivium/frontend-common/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InformationForm, UpdateForm } from '..'
import { MODULE_NAME } from '../../strings'
import {
  GridWrapper,
  ModalContainer,
  FormWrapper,
  HeaderWrapper,
} from './NewActualization.styles'
import {
  defaultActualizationOption,
  ActualizationOptions,
  RadioSelect,
} from './RadioSelect'

const NewActualization = () => {
  const [actualizationType, setActualizationType] = useState<string>(
    defaultActualizationOption
  )
  const { t } = useTranslation(MODULE_NAME)
  const { openModal, closeModal, isModalOpen } = useModal()

  return (
    <>
      <GridWrapper container alignItems="center" justifyContent="space-between">
        <Typography variant="h4" component="h4">
          {t('informations.sendInformation')}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          fullWidth={false}
          endIcon={<Add />}
          onClick={openModal}
        >
          {t('informations.newUpdate')}
        </Button>
      </GridWrapper>
      <ModalContainer open={isModalOpen} fullWidth onClose={closeModal}>
        <FormWrapper>
          <HeaderWrapper>
            <Typography variant="h4" component="h4">
              {t('informations.newUpdate')}
            </Typography>
          </HeaderWrapper>
          <RadioSelect
            actualizationType={actualizationType}
            onActualizationTypeChange={setActualizationType}
          />
          {actualizationType === ActualizationOptions.Information && (
            <InformationForm onCloseModal={closeModal} />
          )}
          {actualizationType === ActualizationOptions.Update && (
            <UpdateForm onCloseModal={closeModal} />
          )}
        </FormWrapper>
      </ModalContainer>
    </>
  )
}

export default NewActualization
