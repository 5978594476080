import { RootState } from 'app'

export const getOrganizations = (state: RootState) =>
  state.common.organizations.getOrganizations

export const getOrganizationsData = (state: RootState) =>
  state.common.organizations.getOrganizations.data
// export const getAccountsCountByOrganizationId = (organizationId: number) =>
//   createSelector(
//     getOrganizationsData,
//     (data: Organization[]) =>
//       (data || []).find(({ id }) => id === organizationId)?.accountsCount ?? 0
//   )
