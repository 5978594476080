import {
  styled,
  Grid,
  Table as BaseTable,
  TableRow as BaseTableRow,
  Paper,
  Dialog,
  DialogContent,
} from '@material-ui/core'

export const ModalContainer = styled(Dialog)(({ theme }) => ({
  backgroundColor: theme.palette.shadow.dark,
  zIndex: theme.zIndex.drawer + 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const FormWrapper = styled(DialogContent)(({ theme }) => ({
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    overflowY: 'auto',
  },
}))

export const GridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',

    '& button': {
      marginTop: theme.spacing(1),
    },
  },
}))

export const SectionHeaderWrapper = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 2),
}))

export const FormHeaderWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
}))

export const TablePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6, 2.5),
  boxShadow: `0px 0px 30px -10px ${theme.palette.shadow.dark}`,
}))

export const LoaderWrapper = styled(Grid)(() => ({
  height: 410,
}))

export const EmptyDataWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(20),
}))

export const TableWrapper = styled('div')(() => ({
  overflow: 'auto',
  position: 'relative',
  height: 410,
}))

export const Table = styled(BaseTable)(({ theme }) => ({
  minWidth: 900,
  position: 'absolute',
  borderSpacing: theme.spacing(0, 1),
  borderCollapse: 'separate',
  padding: theme.spacing(0, 2),
  tableLayout: 'fixed',
  '& tr': {
    position: 'relative',
    overflow: 'hidden',
  },
  '& thead': {
    '& th': {
      fontSize: '0.9rem',
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.drawer - 1,
    },
  },
  '& th:nth-child(1)': {
    width: 180,
  },
  '& th:nth-child(2)': {
    width: 200,
  },
  '& td:nth-child(3), th:nth-child(3)': {
    width: 310,

    [theme.breakpoints.down('md')]: {
      width: 200,
    },
  },
  '& td:nth-child(4), th:nth-child(4)': {
    width: '100%',
    wordWrap: 'break-word',
  },

  '& th:nth-child(5)': {
    width: 100,
  },
  '& td:nth-child(1)': {
    fontWeight: 500,
  },
  '& svg': {
    width: '1.9rem',
    height: '1.9rem',
    cursor: 'pointer',
    color: theme.palette.neutral.contrastText,
  },
}))

export const StyledTableRow = styled(BaseTableRow)(({ theme }) => ({
  boxShadow: `0px 2px 16px ${theme.palette.shadow.main}`,
  borderRadius: 4,
  height: 90,

  '& .MuiTableCell-body': {
    color: theme.palette.text.secondary,
    height: 90,
  },
}))

export const ActionsWrapper = styled(Grid)(() => ({
  '& button': {
    backgroundColor: 'transparent',
    border: 'none',
  },
}))

export const PillWrapper = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1, 1, 1, 0),
}))
