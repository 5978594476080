import { Container, Grid } from '@material-ui/core'
import { ErrorHandler } from '@vivium/frontend-common/components'
import { useEffect } from 'react'
import { useWatch, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RecipientsFields } from '../RecipientsSection'
import { StyledAutocompleteField } from './SelectOrganizationAutocomplete.styles'
import { useGetOrganizations } from './SelectOrganizationAutocomplete.utils'

interface SelectOrganizationAutocompleteProps {
  name: string
}

const SelectOrganizationAutocomplete = ({
  name,
}: SelectOrganizationAutocompleteProps) => {
  const selectedOrganizationType = useWatch({
    name: RecipientsFields.OrganizationType,
  })
  const { t } = useTranslation()
  const { setValue } = useFormContext()
  const { isLoading, error, organizationsOptions, organizations } =
    useGetOrganizations(selectedOrganizationType.value)

  useEffect(() => {
    if (organizationsOptions.length > 0) {
      setValue(RecipientsFields.Organization, organizationsOptions[0])
    }
    // eslint-disable-next-line
  }, [organizations])

  return (
    <>
      {error && (
        <Container>
          <ErrorHandler message={error} />
        </Container>
      )}
      {!error && (
        <Grid container item xs={12} sm={6} md={5} lg={3}>
          <StyledAutocompleteField
            optionsLength={organizationsOptions.length}
            disableClearable
            name={name}
            label={t('sendMessage.filters.organization')}
            options={organizationsOptions}
            loading={isLoading}
            fullWidth
          />
        </Grid>
      )}
    </>
  )
}

export default SelectOrganizationAutocomplete
