import Api from '../api'
import {
  GetSystemSpecializationsResponse,
  PostSystemSpecialization,
  GetSystemSpecializationsParams,
  GetSpecialtiesSuggestionsParams,
  GetSpecialtiesSuggestionsResponse,
} from './SystemSpecializations.types'

class SystemSpecializations extends Api {
  public getSpecializationsSuggestions = async (
    params: GetSpecialtiesSuggestionsParams
  ) => {
    const { data } = await this.api.get<GetSpecialtiesSuggestionsResponse>(
      '/suggestions/specialties',
      { params }
    )
    return data
  }
  public getSystemSpecializations = async (
    params?: GetSystemSpecializationsParams
  ) => {
    const { data } = await this.api.get<GetSystemSpecializationsResponse>(
      '/admin/specialties',
      { params }
    )
    return data
  }
  public addSystemSpecialization = async ({
    ...payload
  }: PostSystemSpecialization) => {
    const { data } = await this.api.post<number>('/admin/specialties', payload)
    return data
  }

  public deleteSystemSpecialization = async (id: number) => {
    const { data } = await this.api.delete<number>(`/admin/specialties/${id}`)
    return data
  }
}

export default SystemSpecializations
