import { Entity } from '@vivium/frontend-common/types'
import { UnassignRoleFromCpParams } from 'shared/services/careProviderRoles'
import { UnassignSpecialtyParams } from 'shared/services/careProviderSpecialties'
import { UnassignSpecialtyFromSystemRoleParams } from 'shared/services/systemRoles'
import { Replace } from 'shared/types'

export const validationModalErrorCodes = [7001, 7002, 7003, 7004, 7005]

export interface Exception {
  code: number
  error: string | null
  errors: string[]
  message: string
  type: string
  meta: Entity | null
  validationErrors: string[]
}

export interface ValidationErrorOrgScopedResponse {
  code: number
  exceptions: Replace<Exception, 'meta', Entity>[]
  path: string
}

export interface OpenValidationErrorModalOrgScopePayload
  extends ValidationErrorOrgScopedResponse {}

export interface ValidationErrorOrgScopedModalHandleResponse {
  onSuccess: () => void
  onFailure: (error?: ValidationErrorOrgScopedResponse) => void
}

export interface DeleteSystemRolePayload
  extends ValidationErrorOrgScopedModalHandleResponse {
  params: number
}

export interface DeleteSystemSpecializationPayload
  extends ValidationErrorOrgScopedModalHandleResponse {
  params: number
}

export interface DeleteSystemSpecializationPayload
  extends ValidationErrorOrgScopedModalHandleResponse {
  params: number
}

export interface UnassignSpecialtyFromSystemRolePayload
  extends ValidationErrorOrgScopedModalHandleResponse {
  params: UnassignSpecialtyFromSystemRoleParams
}

export interface ValidationErrorResponse {
  accountId: number
  code: number
  error: string | null
  errors: string[]
  exceptions: Exception[]
  meta: Entity | null
  path: string
  message: string
  statusCode: number
  type: string
  stack: string
}

export interface OpenValidationErrorModalPayload
  extends ValidationErrorResponse {
  organizationName: string
}

export interface ValidationErrorModalHandleResponse {
  onSuccess: () => void
  onFailure: (error?: OpenValidationErrorModalPayload) => void
}

export interface UnassignRoleFromCareProviderPayload
  extends ValidationErrorModalHandleResponse {
  params: UnassignRoleFromCpParams
}

export interface UnassignSpecialtyFromCareProviderPayload
  extends ValidationErrorModalHandleResponse {
  params: UnassignSpecialtyParams
}
