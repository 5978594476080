import { MODULE_NAME } from 'modules/informations/strings'
import { useTranslation } from 'react-i18next'
import { theme } from 'config'

export interface Opinion {
  rate: number
  percentage: number
  ratedCount: number
  totalFeedbackCount: number
}

export interface FeedbackData {
  positive: Opinion
  neutral: Opinion
  negative: Opinion
}

export interface OpinionsStatisticsChartProps {
  feedbackData: FeedbackData
}

export enum FeedbackStatus {
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Negative = 'NEGATIVE',
}

export const useModifyFeedbackDataForCircularChart = (
  feedbackData: FeedbackData
) => {
  const { t } = useTranslation(MODULE_NAME)
  return [
    {
      status: FeedbackStatus.Positive,
      value: feedbackData.positive.percentage,
      filledLegend: true,
      legendItem:
        t(`informationsForms.legendItems.${FeedbackStatus.Positive}`) +
        t('informationsForms.legendItems.statisticsData', {
          ratedCount: feedbackData.positive.ratedCount,
          percentage: feedbackData.positive.percentage,
        }),
      color: theme.palette.pill.green.main,
    },
    {
      status: FeedbackStatus.Neutral,
      value: feedbackData.neutral.percentage,
      filledLegend: feedbackData.neutral.percentage,
      legendItem:
        t(`informationsForms.legendItems.${FeedbackStatus.Neutral}`) +
        t('informationsForms.legendItems.statisticsData', {
          ratedCount: feedbackData.neutral.ratedCount,
          percentage: feedbackData.neutral.percentage,
        }),
      color: theme.palette.pill.yellow.main,
    },
    {
      status: FeedbackStatus.Negative,
      value: feedbackData.negative.percentage,
      filledLegend: feedbackData.negative.percentage,
      legendItem:
        t(`informationsForms.legendItems.${FeedbackStatus.Negative}`) +
        t('informationsForms.legendItems.statisticsData', {
          ratedCount: feedbackData.negative.ratedCount,
          percentage: feedbackData.negative.percentage,
        }),
      color: theme.palette.pill.red.main,
    },
  ]
}
