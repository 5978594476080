import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from '../../../shared/resource'
import { sendEmail } from './actions'

interface State {
  sendEmail: Resource<number | null>
}

const initialState: State = {
  sendEmail: resource.getInitial<number | null>(null),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(sendEmail.pending, state => {
      resource.setPending(state.sendEmail)
    })
    .addCase(sendEmail.fulfilled, (state, action) => {
      resource.setSucceeded(state.sendEmail, action.payload)
    })
    .addCase(sendEmail.rejected, (state, action) => {
      resource.setFailed(state.sendEmail, action.error.message)
    })
)
