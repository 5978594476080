import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import {
  CpOrganizationGroup,
  CpOrganizationGroupAdmins,
} from 'shared/services/organizationGroups'
import {
  getCpOrganizationGroups,
  getCpOrganizationGroup,
  addCpOrganizationGroup,
  getCpOrganizationGroupAdmins,
  updateCpOrganizationGroup,
} from './actions'

export interface State {
  getCpOrganizationGroups: Resource<CpOrganizationGroup[]>
  getCpOrganizationGroup: Resource<CpOrganizationGroup | null>
  updateCpOrganizationGroup: Resource<CpOrganizationGroup | null>
  getCpOrganizationGroupAdmins: Resource<CpOrganizationGroupAdmins[]>
  addCpOrganizationGroup: Resource<number | null>
}

const initialState: State = {
  updateCpOrganizationGroup: resource.getInitial<CpOrganizationGroup | null>(),
  getCpOrganizationGroups: resource.getInitial<CpOrganizationGroup[]>([]),
  getCpOrganizationGroupAdmins: resource.getInitial<
    CpOrganizationGroupAdmins[]
  >([]),
  addCpOrganizationGroup: resource.getInitial<number | null>(),
  getCpOrganizationGroup: resource.getInitial<CpOrganizationGroup | null>(null),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateCpOrganizationGroup.pending, state => {
      resource.setPending(state.updateCpOrganizationGroup)
    })
    .addCase(updateCpOrganizationGroup.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateCpOrganizationGroup, action.payload)
    })
    .addCase(updateCpOrganizationGroup.rejected, (state, action) => {
      resource.setFailed(state.updateCpOrganizationGroup, action.error.message)
    })
    .addCase(getCpOrganizationGroupAdmins.pending, state => {
      resource.setPending(state.getCpOrganizationGroupAdmins)
    })
    .addCase(getCpOrganizationGroupAdmins.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getCpOrganizationGroupAdmins,
        action.payload.data
      )
    })
    .addCase(getCpOrganizationGroupAdmins.rejected, (state, action) => {
      resource.setFailed(
        state.getCpOrganizationGroupAdmins,
        action.error.message
      )
    })
    .addCase(addCpOrganizationGroup.pending, state => {
      resource.setPending(state.addCpOrganizationGroup)
    })
    .addCase(addCpOrganizationGroup.fulfilled, (state, action) => {
      resource.setSucceeded(state.addCpOrganizationGroup, action.payload)
    })
    .addCase(addCpOrganizationGroup.rejected, (state, action) => {
      resource.setFailed(state.addCpOrganizationGroup, action.error.message)
    })
    .addCase(getCpOrganizationGroup.pending, state => {
      resource.setPending(state.getCpOrganizationGroup)
    })
    .addCase(getCpOrganizationGroup.fulfilled, (state, action) => {
      resource.setSucceeded(state.getCpOrganizationGroup, action.payload)
    })
    .addCase(getCpOrganizationGroup.rejected, (state, action) => {
      resource.setFailed(state.getCpOrganizationGroup, action.error.message)
    })
    .addCase(getCpOrganizationGroups.pending, state => {
      resource.setPending(state.getCpOrganizationGroups)
    })
    .addCase(getCpOrganizationGroups.fulfilled, (state, action) => {
      resource.setSucceeded(state.getCpOrganizationGroups, action.payload.data)
    })
    .addCase(getCpOrganizationGroups.rejected, (state, action) => {
      resource.setFailed(state.getCpOrganizationGroups, action.error.message)
    })
)
