import { Typography, Button, Grid } from '@material-ui/core'
import { LoadingButton } from '@vivium/frontend-common/components'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RecipientsFields, RecipientsValues } from '../RecipientsSection'
import {
  ModalContainer,
  ContentWrapper,
  HeaderWrapper,
  WarningIcon,
  RecipientsWrapper,
  ActionsWrapper,
} from './MessageSubmitModal.styles'

interface MessageSubmitModalProps {
  isSubmitting: boolean
  onCloseModal: () => void
  isModalOpen: boolean
}

const MessageSubmitModal = ({
  isSubmitting,
  onCloseModal,
  isModalOpen,
}: MessageSubmitModalProps) => {
  const { control } = useFormContext()
  const [organizationType, organization, recipients, accountStatus] = useWatch({
    control: control,
    name: [
      RecipientsFields.OrganizationType,
      RecipientsFields.Organization,
      RecipientsFields.Recipients,
      RecipientsFields.AccountStatus,
    ],
  }) as [
    RecipientsValues[RecipientsFields.OrganizationType],
    RecipientsValues[RecipientsFields.Organization],
    RecipientsValues[RecipientsFields.Recipients],
    RecipientsValues[RecipientsFields.AccountStatus]
  ]

  const { t } = useTranslation()
  return (
    <Grid container>
      <ModalContainer
        disablePortal
        PaperProps={{ elevation: 1 }}
        open={isModalOpen}
        fullWidth
        onClose={onCloseModal}
      >
        <Grid item sm={12}>
          <ContentWrapper>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <WarningIcon color="primary" />
              <HeaderWrapper>{t('submitModal.sendMessage')}</HeaderWrapper>
              <RecipientsWrapper item container sm={10} direction="column">
                <Typography>{t('submitModal.confirmRecipients')}</Typography>
                <Typography>
                  {t('submitModal.organizationType')}
                  <b>{organizationType?.label}</b>
                </Typography>
                <Typography>
                  {t('submitModal.organization')}
                  <b>{organization?.label}</b>
                </Typography>
                <Typography>
                  {t('submitModal.recipients')}
                  <b>{recipients?.label ?? 0}</b>
                </Typography>
                <Typography>
                  {t('submitModal.accountStatus')}
                  <b>{accountStatus?.label}</b>
                </Typography>
              </RecipientsWrapper>
              <ActionsWrapper
                item
                container
                sm={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  onClick={onCloseModal}
                  fullWidth={false}
                  color="primary"
                  variant="outlined"
                  disabled={isSubmitting}
                >
                  {t('submitModal.cancel')}
                </Button>
                <LoadingButton
                  type="submit"
                  fullWidth={false}
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!recipients?.label}
                >
                  {t('submitModal.send')}
                </LoadingButton>
              </ActionsWrapper>
            </Grid>
          </ContentWrapper>
        </Grid>
      </ModalContainer>
    </Grid>
  )
}

export default MessageSubmitModal
