import {
  UserRole,
  Entity,
  SortingParams,
  FilteringParams,
  PaginationParams,
  PaginationData,
  MessageDeliveryType,
  Me,
} from '@vivium/frontend-common/types'
import { SystemRole } from 'shared/types'

export enum OrganizationType {
  StaffProvider = 'STAFF_PROVIDER',
  CareProvider = 'CARE_PROVIDER',
}

export enum AccountStatuses {
  Active = ' ACTIVE',
  Blocked = 'BLOCKED',
  Inactive = 'INACTIVE',
}

export interface GetAllAccountsDetailsParams {
  organizationType?: OrganizationType
  organizationId?: number
  accountStatus?: AccountStatuses
  accountType?: string
  systemRole?: string
  searchBy?: string
  search?: string
  authRoleGroup?: SystemRole
}

export interface GetAccountsDetailsParams
  extends PaginationParams,
    SortingParams,
    FilteringParams {
  organizationType?: OrganizationType
  organizationId?: number
  organizationGroupId?: number
  accountStatuses?: AccountStatuses
  authRoleGroup?: SystemRole
}

export interface AccountDetail extends Entity {
  id: number
  name: string
  roleGroup: SystemRole
  organization: {
    id: number
    name: string
    type: UserRole
  }
  status: AccountStatuses
  lastLoggedInDate: string
}

export interface GetAccountsDetailsResponse extends PaginationData {
  data: AccountDetail[]
}

export interface Account {
  id: number
  name: string
  title: string
  role: UserRole
  phoneNumber: string
  email: string
  receiveNewEventsEmails: boolean
  careProviderId: number
  staffProviderId: number
  careProviderOrganizationGroupIds: number[]
  isActive: boolean
}

export interface PostAccount {
  name: string
  title: string
  role: UserRole
  phoneNumber: string
  email: string
  receiveNewEventsEmails: boolean
  careProviderId?: number
  staffProviderId?: number
  careProviderOrganizationGroupIds?: number[]
}

export interface PutAccount {
  id: number
  name: string
  title: string
  role: UserRole
  phoneNumber: string
  email: string
  receiveNewEventsEmails: boolean
  careProviderId?: number
  staffProviderId?: number
  careProviderOrganizationGroupIds?: number[]
}

export interface GetAccountParams {
  id: number
}

export interface ActivateDeactivateAccountParams {
  accountIds: number[]
}

export interface ActivateDeactivateAccountResponse extends Entity {
  title: string
  email: string
  phoneNumber: string
  isActive: true
  role: UserRole[]
}

export interface ResetLoginInfoParams {
  accountId: number
  messageDeliveryType: MessageDeliveryType
}

export type ResetLoginInfoResponse = string

export enum ToggleActivation {
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export interface ChangeAccountStatusParams {
  accountIds: number[]
  action: ToggleActivation
}

export type ChangeAccountStatusResponse = string

export interface DeleteAccountParams {
  accountIds: number[]
}

export type DeleteAccountResponse = number

export interface GetMeResponse extends Me {}
