import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import {
  CareProviderRoleDetail,
  CareProviderRoleWithSpecialties,
} from 'shared/services/careProviderRoles'
import { SystemRole, RoleWithSpecialities } from 'shared/services/systemRoles'
import {
  SystemSpecialization,
  SpecializationSuggestion,
} from 'shared/services/systemSpecializations'
import {
  getSystemSpecializations,
  addSystemSpecialization,
  deleteSystemSpecialization,
  getSystemRolesWithSpecialties,
  getSystemSpecializationsByRoleId,
  updateRole,
  getSystemRoles,
  addSystemRole,
  deleteSystemRole,
  getCareProviderRolesDetails,
  getCareProviderRolesWithSpecialties,
  unassignRoleFromCareProvider,
  unassignSpecialtiesFromCareProvider,
  assignSpecialtiesToCareProvider,
  openValidationErrorModalOrgScoped,
  closeValidationErrorModal,
  assignSpecialtiesToSystemRole,
  unassignSpecialtyFromSystemRole,
  openValidationErrorModal,
  getSpecializationsSuggestions,
} from './actions'

interface ValidationErrorModal {
  careProviderName?: string
  isModalOpen: boolean
  aggregate: number
  error: {
    organizationName: string
    errorCodes: number[]
  }[]
}

export interface State {
  getSpecializationsSuggestions: Resource<SpecializationSuggestion[]>
  unassignSpecialtyFromSystemRole: Resource<number | null>
  assignSpecialtiesToSystemRole: Resource<number | null>
  validationErrorModal: ValidationErrorModal
  getSystemSpecializationsByRoleId: Resource<SystemSpecialization[]>
  getSystemSpecializations: Resource<SystemSpecialization[]>
  addSystemSpecialization: Resource<number | null>
  deleteSystemSpecialization: Resource<number | null>
  getSystemRolesWithSpecialties: Resource<RoleWithSpecialities[]>
  updateRole: Resource<number | null>
  getSystemRoles: Resource<SystemRole[]>
  addSystemRole: Resource<number | null>
  deleteSystemRole: Resource<number | null>
  getCareProviderRolesDetails: Resource<CareProviderRoleDetail[]>
  getCareProviderRolesWithSpecialties: Resource<
    CareProviderRoleWithSpecialties[]
  >
  unassignRoleFromCareProvider: Resource<object | null>
  assignRoleToCareProvider: Resource<object | null>
  unassignSpecialtiesFromCareProvider: Resource<object | null>
  assignSpecialtiesToCareProvider: Resource<object | null>
}

const initialState: State = {
  getSpecializationsSuggestions: resource.getInitial<
    SpecializationSuggestion[]
  >([]),
  unassignSpecialtyFromSystemRole: resource.getInitial<number | null>(),
  assignSpecialtiesToSystemRole: resource.getInitial<number | null>(),
  deleteSystemSpecialization: resource.getInitial<number | null>(),
  validationErrorModal: {
    careProviderName: '',
    isModalOpen: false,
    aggregate: NaN,
    error: [],
  },
  assignSpecialtiesToCareProvider: resource.getInitial<object | null>(),
  assignRoleToCareProvider: resource.getInitial<object | null>(),
  unassignRoleFromCareProvider: resource.getInitial<object | null>(),
  getCareProviderRolesWithSpecialties: resource.getInitial<
    CareProviderRoleWithSpecialties[]
  >([]),
  getCareProviderRolesDetails: resource.getInitial<CareProviderRoleDetail[]>(
    []
  ),
  getSystemSpecializationsByRoleId: resource.getInitial<SystemSpecialization[]>(
    []
  ),
  getSystemSpecializations: resource.getInitial<SystemSpecialization[]>([]),
  addSystemSpecialization: resource.getInitial<number | null>(),
  getSystemRolesWithSpecialties: resource.getInitial<RoleWithSpecialities[]>(
    []
  ),
  getSystemRoles: resource.getInitial<SystemRole[]>([]),
  updateRole: resource.getInitial<number | null>(),
  addSystemRole: resource.getInitial<number | null>(),
  deleteSystemRole: resource.getInitial<number | null>(),
  unassignSpecialtiesFromCareProvider: resource.getInitial<object | null>(),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(closeValidationErrorModal, state => {
      state.validationErrorModal = {
        isModalOpen: false,
        aggregate: NaN,
        error: [],
      }
    })
    .addCase(openValidationErrorModalOrgScoped, (state, action) => {
      const { code: aggregation, exceptions } = action.payload

      interface ErrorsObj {
        [key: string]: number[]
      }
      const errorsObj: ErrorsObj = {}

      exceptions.forEach(exception => {
        const organizationName = exception.meta.name
        const errorCode = exception.code
        const organizationErrors = errorsObj[organizationName]
        errorsObj[organizationName] = organizationErrors
          ? [...organizationErrors, errorCode]
          : [errorCode]
      })

      const arrOfErrorsObjScopedToOrganization = Object.entries(errorsObj).map(
        ([key, value]) => ({
          organizationName: key,
          errorCodes: value,
        })
      )

      state.validationErrorModal = {
        isModalOpen: true,
        aggregate: aggregation,
        error: arrOfErrorsObjScopedToOrganization,
      }
    })
    .addCase(openValidationErrorModal, (state, action) => {
      const { code: aggregation, exceptions, organizationName } = action.payload

      const errorCodes = exceptions.map(({ code }) => code)

      state.validationErrorModal = {
        isModalOpen: true,
        aggregate: aggregation,
        error: [
          {
            organizationName,
            errorCodes,
          },
        ],
      }
    })
    .addCase(unassignSpecialtyFromSystemRole.pending, state => {
      resource.setPending(state.unassignSpecialtyFromSystemRole)
    })
    .addCase(unassignSpecialtyFromSystemRole.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.unassignSpecialtyFromSystemRole,
        action.payload
      )
    })
    .addCase(unassignSpecialtyFromSystemRole.rejected, (state, action) => {
      resource.setFailed(
        state.unassignSpecialtyFromSystemRole,
        action.error.message
      )
    })
    .addCase(assignSpecialtiesToSystemRole.pending, state => {
      resource.setPending(state.assignSpecialtiesToSystemRole)
    })
    .addCase(assignSpecialtiesToSystemRole.fulfilled, (state, action) => {
      resource.setSucceeded(state.assignSpecialtiesToSystemRole, action.payload)
    })
    .addCase(assignSpecialtiesToSystemRole.rejected, (state, action) => {
      resource.setFailed(
        state.assignSpecialtiesToSystemRole,
        action.error.message
      )
    })
    .addCase(unassignRoleFromCareProvider.pending, state => {
      resource.setPending(state.unassignRoleFromCareProvider)
    })
    .addCase(unassignRoleFromCareProvider.fulfilled, (state, action) => {
      resource.setSucceeded(state.unassignRoleFromCareProvider, action.payload)
    })
    .addCase(unassignRoleFromCareProvider.rejected, (state, action) => {
      resource.setFailed(
        state.unassignRoleFromCareProvider,
        action.error.message
      )
    })
    .addCase(getCareProviderRolesWithSpecialties.pending, state => {
      resource.setPending(state.getCareProviderRolesWithSpecialties)
    })
    .addCase(getCareProviderRolesWithSpecialties.rejected, (state, action) => {
      resource.setFailed(
        state.getCareProviderRolesWithSpecialties,
        action.error.message
      )
    })
    .addCase(getCareProviderRolesWithSpecialties.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getCareProviderRolesWithSpecialties,
        action.payload.data
      )
    })
    .addCase(getCareProviderRolesDetails.pending, state => {
      resource.setPending(state.getCareProviderRolesDetails)
    })
    .addCase(getCareProviderRolesDetails.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getCareProviderRolesDetails,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getCareProviderRolesDetails.rejected, (state, action) => {
      resource.setFailed(
        state.getCareProviderRolesDetails,
        action.error.message
      )
    })
    .addCase(getSpecializationsSuggestions.pending, state => {
      resource.setPending(state.getSpecializationsSuggestions)
    })
    .addCase(getSpecializationsSuggestions.rejected, (state, action) => {
      resource.setFailed(
        state.getSpecializationsSuggestions,
        action.error.message
      )
    })
    .addCase(getSpecializationsSuggestions.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getSpecializationsSuggestions,
        action.payload.data
      )
    })
    .addCase(getSystemRoles.pending, state => {
      resource.setPending(state.getSystemRoles)
    })
    .addCase(getSystemRoles.rejected, (state, action) => {
      resource.setFailed(state.getSystemRoles, action.error.message)
    })
    .addCase(getSystemRoles.fulfilled, (state, action) => {
      resource.setSucceeded(state.getSystemRoles, action.payload.data)
    })
    .addCase(getSystemSpecializationsByRoleId.pending, state => {
      resource.setPending(state.getSystemSpecializationsByRoleId)
    })
    .addCase(getSystemSpecializationsByRoleId.rejected, (state, action) => {
      resource.setFailed(
        state.getSystemSpecializationsByRoleId,
        action.error.message
      )
    })
    .addCase(getSystemSpecializationsByRoleId.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getSystemSpecializationsByRoleId,
        action.payload.data
      )
    })
    .addCase(getSystemSpecializations.pending, state => {
      resource.setPending(state.getSystemSpecializations)
    })
    .addCase(getSystemSpecializations.rejected, (state, action) => {
      resource.setFailed(state.getSystemSpecializations, action.error.message)
    })
    .addCase(getSystemSpecializations.fulfilled, (state, action) => {
      resource.setSucceeded(state.getSystemSpecializations, action.payload.data)
    })
    .addCase(addSystemSpecialization.pending, state => {
      resource.setPending(state.addSystemSpecialization)
    })
    .addCase(addSystemSpecialization.fulfilled, (state, action) => {
      resource.setSucceeded(state.addSystemSpecialization, action.payload)
    })
    .addCase(addSystemSpecialization.rejected, (state, action) => {
      resource.setFailed(state.addSystemSpecialization, action.error.message)
    })
    .addCase(deleteSystemSpecialization.pending, state => {
      resource.setPending(state.deleteSystemSpecialization)
    })
    .addCase(deleteSystemSpecialization.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteSystemSpecialization, action.payload)
    })
    .addCase(deleteSystemSpecialization.rejected, (state, action) => {
      resource.setFailed(state.deleteSystemSpecialization, action.error.message)
    })
    .addCase(getSystemRolesWithSpecialties.pending, state => {
      resource.setPending(state.getSystemRolesWithSpecialties)
    })
    .addCase(getSystemRolesWithSpecialties.rejected, (state, action) => {
      resource.setFailed(
        state.getSystemRolesWithSpecialties,
        action.error.message
      )
    })
    .addCase(getSystemRolesWithSpecialties.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getSystemRolesWithSpecialties,
        action.payload.data
      )
    })
    .addCase(updateRole.pending, state => {
      resource.setPending(state.updateRole)
    })
    .addCase(updateRole.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateRole, action.payload)
    })
    .addCase(updateRole.rejected, (state, action) => {
      resource.setFailed(state.updateRole, action.error.message)
    })
    .addCase(addSystemRole.pending, state => {
      resource.setPending(state.addSystemRole)
    })
    .addCase(addSystemRole.fulfilled, (state, action) => {
      resource.setSucceeded(state.addSystemRole, action.payload)
    })
    .addCase(addSystemRole.rejected, (state, action) => {
      resource.setFailed(state.addSystemRole, action.error.message)
    })
    .addCase(deleteSystemRole.pending, state => {
      resource.setPending(state.deleteSystemRole)
    })
    .addCase(deleteSystemRole.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteSystemRole, action.payload)
    })
    .addCase(deleteSystemRole.rejected, (state, action) => {
      resource.setFailed(state.deleteSystemRole, action.error.message)
    })
    .addCase(unassignSpecialtiesFromCareProvider.pending, state => {
      resource.setPending(state.unassignSpecialtiesFromCareProvider)
    })
    .addCase(unassignSpecialtiesFromCareProvider.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.unassignSpecialtiesFromCareProvider,
        action.payload
      )
    })
    .addCase(unassignSpecialtiesFromCareProvider.rejected, (state, action) => {
      resource.setFailed(
        state.unassignSpecialtiesFromCareProvider,
        action.error.message
      )
    })
    .addCase(assignSpecialtiesToCareProvider.pending, state => {
      resource.setPending(state.assignSpecialtiesToCareProvider)
    })
    .addCase(assignSpecialtiesToCareProvider.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.assignSpecialtiesToCareProvider,
        action.payload
      )
    })
    .addCase(assignSpecialtiesToCareProvider.rejected, (state, action) => {
      resource.setFailed(
        state.assignSpecialtiesToCareProvider,
        action.error.message
      )
    })
)
