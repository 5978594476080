import { Loadable } from '@vivium/frontend-common/components'
import { ModuleRoute } from '@vivium/frontend-common/types'
import { paths } from 'config'

const routes: ModuleRoute[] = [
  {
    path: paths.system,
    exact: true,
    component: Loadable({
      component: () => import('./pages/System'),
    }),
  },
  {
    path: paths.systemCreateOrganizationScheduling,
    exact: true,
    component: Loadable({
      component: () =>
        import('./components/Organizations/OrganizationSchedulingForm'),
    }),
  },
  {
    path: paths.systemCreateOrganizationStaffing,
    exact: true,
    component: Loadable({
      component: () =>
        import('./components/Organizations/OrganizationStaffingForm'),
    }),
  },
  {
    path: paths.systemCreateOrganizationScheduling,
    exact: true,
    component: Loadable({
      component: () =>
        import('./components/Organizations/OrganizationSchedulingForm'),
    }),
  },
  {
    path: paths.systemCreateOrganizationGroup,
    exact: true,
    component: Loadable({
      component: () =>
        import('./components/OrganizationGroups/CreateOrganizationGroup'),
    }),
  },
  {
    path: paths.systemCreateGroupAdmin,
    exact: true,
    component: Loadable({
      component: () =>
        import(
          './components/OrganizationGroups/UpdateOrganizationGroup/CreateGroupAdmin'
        ),
    }),
  },
  {
    path: paths.systemUpdateGroupAdmin,
    exact: true,
    component: Loadable({
      component: () =>
        import(
          './components/OrganizationGroups/UpdateOrganizationGroup/UpdateGroupAdmin'
        ),
    }),
  },
  {
    path: paths.systemUpdateOrganizationGroup,
    exact: true,
    component: Loadable({
      component: () =>
        import('./components/OrganizationGroups/UpdateOrganizationGroup'),
    }),
  },
  {
    path: paths.systemCreateUser,
    exact: true,
    component: Loadable({
      component: () => import('./components/Accounts/CreateAccount'),
    }),
  },
  {
    path: paths.systemUpdateCareProviderResource,
    exact: true,
    component: Loadable({
      component: () => import('./pages/UpdateCareProviderResource'),
    }),
  },
  {
    path: paths.systemCreateCareProviderResource,
    exact: true,
    component: Loadable({
      component: () => import('./pages/CreateCareProviderResource'),
    }),
  },
  {
    path: paths.systemUpdateAccount,
    exact: true,
    component: Loadable({
      component: () => import('./components/Accounts/UpdateAccount'),
    }),
  },
  {
    path: paths.systemUpdateOrganizationScheduling,
    exact: true,
    component: Loadable({
      component: () =>
        import('./components/Organizations/UpdateOrganizationScheduling'),
    }),
  },
  {
    path: paths.systemUpdateOrganizationStaffing,
    exact: true,
    component: Loadable({
      component: () =>
        import('./components/Organizations/UpdateOrganizationStaffing'),
    }),
  },
  {
    path: paths.systemCreateStaffProviderResource,
    public: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/CreateStaffProviderResource'),
    }),
  },
]

export default routes
