import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  Suggestions,
  GetAccountsSuggestionsParams,
} from 'shared/services/suggestions'
import { MODULE_NAME } from '../../strings'

const suggestions = new Suggestions()

export const getAccountsSuggestions = createAsyncThunk(
  `${MODULE_NAME}/accountsSuggestions`,
  (payload: GetAccountsSuggestionsParams) => suggestions.getAccounts(payload)
)
