import Api from '../api'
import {
  GetCareProviderRolesDetailsParams,
  GetCareProviderRolesDetailsResponse,
  GetCareProviderRolesWithSpecialtiesResponse,
  AssignRoleToCpParams,
  UnassignRoleFromCpParams,
} from './CareProviderRoles.types'

class CareProviderRoles extends Api {
  public getCareProviderRolesDetails = async (
    params: GetCareProviderRolesDetailsParams
  ) => {
    const response = await this.api.get<GetCareProviderRolesDetailsResponse>(
      '/admin/care-providers/roles/details',
      { params }
    )
    const { data, ...meta } = response.data

    return { data, meta }
  }

  public getCareProviderRolesWithSpecialties = async (
    careProviderId: number
  ) => {
    const { data } =
      await this.api.get<GetCareProviderRolesWithSpecialtiesResponse>(
        `/admin/care-providers/${careProviderId}/roles`
      )
    return data
  }
  public careProviderRolesAssign = async ({
    careProviderId,
    ...payload
  }: AssignRoleToCpParams) => {
    const { data } = await this.api.post<object>(
      `/admin/care-providers/${careProviderId}/roles/assign`,
      payload
    )
    return data
  }
  public careProviderRolesUnassign = async ({
    careProviderId,
    roleId,
  }: UnassignRoleFromCpParams) => {
    const { data } = await this.api.post<object>(
      `/admin/care-providers/${careProviderId}/roles/unassign/${roleId}`
    )
    return data
  }
}

export default CareProviderRoles
