import {
  ListItem,
  ListItemProps,
  ListItemIcon,
  ListItemText,
  Badge,
} from '@material-ui/core'
import { SvgIconComponent } from '@material-ui/icons'
import { Location } from '@vivium/frontend-common/types'
import { getCurrentPath } from '@vivium/frontend-common/utils'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, LinkProps, matchPath, useLocation } from 'react-router-dom'

export interface ListItemLinkProps extends Partial<ListItemProps> {
  label?: string
  icon?: SvgIconComponent
  badgeCount?: number
  to: string
}

const ListItemLink = ({
  label,
  to,
  badgeCount,
  icon: Icon,
  ...props
}: ListItemLinkProps) => {
  const { t } = useTranslation()
  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  )

  return (
    <li>
      <ListItem
        // @ts-ignore
        button
        component={renderLink}
        {...props}
      >
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        {label && (
          <ListItemText
            primary={t(label)}
            primaryTypographyProps={{ color: 'inherit' }}
          />
        )}
        <Badge badgeContent={badgeCount} color="error" />
      </ListItem>
    </li>
  )
}

export interface DrawerItemProps extends ListItemLinkProps {
  exact?: boolean
  match?: boolean
}

const DrawerItem = ({
  exact = false,
  match = true,
  to,
  ...props
}: DrawerItemProps) => {
  const location = useLocation()

  const pathname = getCurrentPath(location as Location)
  const pathMatch = match ? matchPath(pathname, { exact, path: to }) : null

  return (
    <ListItemLink
      className={pathMatch ? 'Mui-selected' : ''}
      to={`${to}${pathMatch ? location.search : ''}`}
      {...props}
    />
  )
}

export default DrawerItem
