import {
  Typography,
  TableCell,
  TableHead,
  TableRow,
  Container,
  CircularProgress,
} from '@material-ui/core'
import {
  ErrorHandler,
  DataTableEmptyMessage,
} from '@vivium/frontend-common/components'
import { useTranslation } from 'react-i18next'
import { useModal } from 'shared/hooks'
import { ModalTypes } from 'shared/types'
import { UpdatePutForm } from '..'
import { MODULE_NAME } from '../../strings'
import { UpdatePreview } from './UpdatePreview'
import { useGetMessagesUpdates } from './UpdateSection.utils'
import { UpdateTableBody } from './UpdateTableBody'
import {
  GridContainer,
  SectionHeaderWrapper,
  FormHeaderWrapper,
  Table,
  TablePaper,
  FormWrapper,
  ModalContainer,
  TableWrapper,
  EmptyDataWrapper,
  LoaderWrapper,
} from './UpdatesSection.styles'

const UpdatesSection = () => {
  const { t } = useTranslation(MODULE_NAME)
  const { openModal, closeModal, isModalOpen, modalType, id } = useModal()
  const { messagesUpdates, isLoading, error } = useGetMessagesUpdates()
  const showUpdateModal = isModalOpen && modalType === ModalTypes.Update
  const showPreviewModal = isModalOpen && modalType === ModalTypes.Preview

  return (
    <GridContainer>
      <SectionHeaderWrapper item container>
        <Typography variant="h5" component="h5">
          {t('updatesTable.updatesMessage')}
        </Typography>
      </SectionHeaderWrapper>
      <TablePaper>
        {error && (
          <Container>
            <ErrorHandler message={error} />
          </Container>
        )}
        {isLoading && (
          <LoaderWrapper container alignItems="center" justifyContent="center">
            <CircularProgress />
          </LoaderWrapper>
        )}
        {!isLoading && !error && (
          <TableWrapper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{t('updatesTable.title')}</TableCell>
                  <TableCell>{t('updatesTable.status')}</TableCell>
                  <TableCell>{t('updatesTable.publicationDate')}</TableCell>
                  <TableCell>{t('updatesTable.module')}</TableCell>
                  <TableCell>{t('updatesTable.views')}</TableCell>
                  <TableCell>{t('updatesTable.submittedOpinions')}</TableCell>
                  <TableCell>{t('updatesTable.opinion')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <UpdateTableBody
                modalType={modalType}
                openModal={openModal}
                isModalOpen={isModalOpen}
                id={id}
                onCloseModal={closeModal}
                messagesUpdates={messagesUpdates}
              />
            </Table>
            {messagesUpdates.length === 0 && (
              <EmptyDataWrapper
                container
                alignItems="center"
                justifyContent="center"
              >
                <DataTableEmptyMessage />
              </EmptyDataWrapper>
            )}
          </TableWrapper>
        )}
      </TablePaper>
      {showUpdateModal && (
        <ModalContainer open={isModalOpen} onClose={closeModal}>
          <FormWrapper>
            <FormHeaderWrapper>
              <Typography variant="h4" component="h4">
                {t('informations.newUpdate')}
              </Typography>
            </FormHeaderWrapper>
            <UpdatePutForm onCloseModal={closeModal} id={id} />
          </FormWrapper>
        </ModalContainer>
      )}
      {showPreviewModal && (
        <UpdatePreview
          id={id}
          isModalOpen={isModalOpen}
          onCloseModal={closeModal}
        />
      )}
    </GridContainer>
  )
}

export default UpdatesSection
