import { styled, Grid } from '@material-ui/core'

export const Wrapper = styled(Grid)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(5),
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.shadow.main}`,
  '& h5': {
    marginBottom: theme.spacing(4),
  },
}))

export const AutocompletesWrapper = styled(Grid)(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    margin: theme.spacing(1, 4, 1, 0),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))
