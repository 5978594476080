import { actions } from 'modules/informations/store'
import { MODULE_NAME } from 'modules/informations/strings'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  InformationSortBy,
  InformationSortOrder,
} from 'shared/services/messageInformation'
import { UpdateSortBy, UpdateSortOrder } from 'shared/services/messageUpdate'
import { Messages } from 'shared/types'

export const useDeleteMessage = () => {
  const { t } = useTranslation(MODULE_NAME)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const deleteMessage = (id: number, messageType: Messages) => {
    const onSuccess = () => {
      if (messageType === Messages.Information) {
        dispatch(
          actions.getMessagesInformations({
            sortBy: InformationSortBy.CreatedAt,
            sortOrder: InformationSortOrder.Descending,
          })
        )
      } else if (messageType === Messages.Update) {
        dispatch(
          actions.getMessagesUpdates({
            sortBy: UpdateSortBy.CreatedAt,
            sortOrder: UpdateSortOrder.Descending,
          })
        )
      }
      return enqueueSnackbar(t('informationsDeleteModal.deleteSuccess'), {
        variant: 'success',
      })
    }
    const onError = () =>
      enqueueSnackbar(t('error.defaultMessage'), { variant: 'error' })

    if (messageType === Messages.Information) {
      return dispatch(
        actions.deleteMessageInformation({
          id,
          onSuccess,
          onError,
        })
      )
    }
    return dispatch(
      actions.deleteMessageUpdate({
        id,
        onSuccess,
        onError,
      })
    )
  }

  return deleteMessage
}
