import {
  styled,
  Grid,
  Table as BaseTable,
  Paper,
  Dialog,
  DialogContent,
} from '@material-ui/core'

export const ModalContainer = styled(Dialog)(({ theme }) => ({
  backgroundColor: theme.palette.shadow.dark,
  zIndex: theme.zIndex.drawer + 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const LoaderWrapper = styled(Grid)(() => ({
  height: 410,
}))

export const EmptyDataWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(23),
}))

export const FormWrapper = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  overflowY: 'auto',
}))

export const SectionHeaderWrapper = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 2),
}))

export const FormHeaderWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
}))

export const GridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',

    '& button': {
      marginTop: theme.spacing(1),
    },
  },
}))

export const TablePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6, 2.5),
  boxShadow: `0px 0px 30px -10px ${theme.palette.shadow.dark}`,
}))

export const TableWrapper = styled('div')(() => ({
  overflow: 'auto',
  position: 'relative',
  height: 410,
}))

export const Table = styled(BaseTable)(({ theme }) => ({
  position: 'absolute',
  minWidth: 1280,
  borderSpacing: theme.spacing(0, 1),
  borderCollapse: 'separate',
  padding: theme.spacing(0, 2),
  tableLayout: 'fixed',
  '& tr': {
    position: 'relative',
    overflow: 'hidden',
  },
  '& thead': {
    '& th': {
      fontSize: '0.9rem',
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.drawer - 1,
    },
  },
  '& td:nth-child(1), th:nth-child(1)': {
    width: '12.5%',
    wordWrap: 'break-word',
  },
  '& th:nth-child(2)': {
    width: '12.5%',
  },
  '& th:nth-child(3)': {
    width: '12.5%',
    wordWrap: 'break-word',
  },
  '& th:nth-child(4)': {
    width: '12.5%',
  },
  '& th:nth-child(5)': {
    width: '12.5%',
  },
  '& th:nth-child(6)': {
    width: '10%',
  },
  '& th:nth-child(7)': {
    width: '15%',
  },
  '& th:nth-child(8)': {
    width: '12.5%',
  },
  '& td:nth-child(1)': {
    fontWeight: 500,
  },
  '& td:nth-child(1) p': {
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.shadow.main,
      cursor: 'pointer',
    },
  },
}))
