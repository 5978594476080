import {
  PaginationData,
  SortingParams,
  PaginationParams,
  Entity,
  FilteringParams,
  UserRole,
} from '@vivium/frontend-common/types'
import { Country, Language } from 'shared/types'

export interface Organization {
  name: string
  type?: string
  id: number
  organizationGroupId: number
  isAssignedToGivenOrganization: true
}

export interface GetOrganizationsResponse {
  data: Organization[]
}

export enum OrganizationSearchBy {
  Name = 'name',
  OrganizationNumber = 'organizationNumber',
}

export interface GetOrganizationsParams {
  organizationType?: OrganizationType
  showOnlyOrganizationsWithActiveAccounts: boolean
  strictSearch?: string
  searchBy?: OrganizationSearchBy
  organizationId?: number
}

export enum AccessibleApplications {
  ViviumI = 'VIVIUM_I',
  ViviumX = 'VIVIUM_X',
}

export enum OrganizationType {
  StaffProvider = 'STAFF_PROVIDER',
  CareProvider = 'CARE_PROVIDER',
}

export enum SortBy {
  Name = 'name',
  ActiveAccountsCount = 'activeAccountsCount',
}

export interface GetOrganizationsDetailsParams
  extends PaginationParams,
    SortingParams {
  organizationTypes: OrganizationType
}

export interface OrganizationDetail {
  id: number
  name: string
  type: OrganizationType
  activeAccountsCount: number
  accessibleApplications: AccessibleApplications
}

export interface GetOrganizationsDetailsResponse extends PaginationData {
  data: OrganizationDetail[]
}

export interface StaffProviderSuggestion extends Entity {}

export interface GetStaffProviderSuggestionsResponse {
  data: StaffProviderSuggestion[]
}

export interface GetStaffProviderSuggestionsParams
  extends SortingParams,
    FilteringParams {}

export interface PostOrganizationScheduling {
  name: string
  description: string
  organizationNumber: string
  contactPersonName: string
  contactAddress: string
  contactPostalCode: string
  contactPhoneNumber: string
  contactEmail: string
  contactRegionId: number
  billingReferenceNumber: string
  billingAddress: string
  billingPostalCode: string
  billingPhoneNumber: string
  billingEmail: string
  billingRegionId: number
  organizationGroupId: number
  staffProviderIds: number[]
  adminIds: number[]
  accountIds: number[]
  accessibleApplications: AccessibleApplications[]
  language?: Language
}

export interface PostOrganizationStaffing {
  name: string
  description: string
  organizationNumber: string
  contactPersonName: string
  contactAddress: string
  contactPostalCode: string
  contactPhoneNumber: string
  contactEmail: string
  contactRegionId: number
  billingReferenceNumber: string
  billingAddress: string
  billingPostalCode: string
  billingPhoneNumber: string
  billingEmail: string
  billingRegionId: number
  staffProviderIds: number[]
  adminIds: number[]
  accountIds: number[]
  accessibleApplications: AccessibleApplications[]
  language?: Language
}

export interface OrganizationAccount extends Entity {
  role: UserRole
}

export interface GetOrganizationResponse {
  id: number
  name: string
  organizationNumber: string
  description: string
  contactRegion: Entity
  contactPersonName: string
  contactAddress: string
  contactPostalCode: string
  contactPhoneNumber: string | null
  contactEmail: string
  country: Country
  billingRegion: Entity
  billingReferenceNumber: string
  billingAddress: string
  billingPostalCode: string
  billingPhoneNumber: string | null
  billingEmail: string
  language: string
  accessibleApplications: AccessibleApplications[]
  accounts: OrganizationAccount[]
}

export interface GetStaffProviderOrganizationResponse
  extends GetOrganizationResponse {}

export interface GetCareProviderOrganizationResponse
  extends GetOrganizationResponse {
  organizationGroup: Entity
}

export interface AssignUnassignStaffProvidersPayload {
  careProviderId: number
  staffProviderIds: number[]
}

export interface AssignStaffProvidersToCareProviderPayload
  extends AssignUnassignStaffProvidersPayload {}

export interface UnassignStaffProvidersFromCareProviderPayload
  extends AssignUnassignStaffProvidersPayload {}

export interface AssignUnassignCareProvidersPayload {
  staffProviderId: number
  careProviderIds: number[]
}

export type AssignCareProvidersToStaffProviderPayload =
  AssignUnassignCareProvidersPayload

export type UnassignCareProvidersFromStaffProviderPayload =
  AssignUnassignCareProvidersPayload
