import Api from '../api'
import {
  GetRegionsSuggestionsParams,
  GetRegionsSuggestionsResponse,
  GetAccountsSuggestionsParams,
  GetAccountsSuggestionsResponse,
} from './Suggestions.types'

class Suggestions extends Api {
  public async getRegions(params?: GetRegionsSuggestionsParams) {
    const { data } = await this.api.get<GetRegionsSuggestionsResponse>(
      '/suggestions/regions',
      { params }
    )
    return data.data
  }
  public getAccounts = async (params?: GetAccountsSuggestionsParams) => {
    const { data } = await this.api.get<GetAccountsSuggestionsResponse>(
      '/admin/suggestions/accounts',
      { params }
    )
    return data.data
  }
}

export default Suggestions
