import crypto from 'crypto-js'

class CryptoService {
  /**
   * Encryption performed according to AES-256-CBC algorithm
   */
  public static encryptMessage(message: string, secretKey: string, iv: string) {
    const _secretKey = crypto.enc.Hex.parse(secretKey)
    const _iv = crypto.enc.Hex.parse(iv)

    const encrypted = crypto.AES.encrypt(message, _secretKey, {
      iv: _iv,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7,
    })

    return encrypted.ciphertext.toString(crypto.enc.Hex)
  }

  /**
   * Decryption performed according to AES-256-CBC algorithm
   */
  public static decryptMessage(message: string, secretKey: string, iv: string) {
    const _secretKey = crypto.enc.Hex.parse(secretKey)
    const _iv = crypto.enc.Hex.parse(iv)

    const decrypted = crypto.AES.decrypt(message, _secretKey, {
      iv: _iv,
    })

    return decrypted
  }
}

export default CryptoService
