import { AutocompleteValue } from '@vivium/frontend-common/components'
import { useTranslation } from 'react-i18next'
import { SystemOrganizationTypes, SystemModulesTypes } from 'shared/types'
import { MODULE_NAME } from '../../modules/system/strings'

export const useConfigurationAutocompleteOptions = () => {
  const { t } = useTranslation(MODULE_NAME)

  const organizationTypeOptions = [
    {
      label: t('system.organizationTable.staffing'),
      value: SystemOrganizationTypes.Staffing,
    },
    {
      label: t('system.organizationTable.scheduling'),
      value: SystemOrganizationTypes.Scheduling,
    },
  ]

  const defaultOrganizationTypeOption = {
    label: t('system.organizationTable.scheduling'),
    value: SystemOrganizationTypes.Scheduling,
  }

  const moduleOptions = [
    {
      label: t('system.organizationTable.viviumI'),
      value: SystemModulesTypes.ViviumI,
    },
    {
      label: t('system.organizationTable.viviumX'),
      value: SystemModulesTypes.ViviumX,
    },
  ]

  const defaultModuleOption = {
    label: t('system.organizationTable.all'),
    value: SystemModulesTypes.All,
  }

  enum Modules {
    VIVIUMI = 'VIVIUMI',
    VIVIUMX = 'VIVIUMX',
  }

  const ModuleOptions: Record<Modules, AutocompleteValue> = {
    [Modules.VIVIUMI]: moduleOptions[0],
    [Modules.VIVIUMX]: moduleOptions[1],
  }

  return {
    organizationTypeOptions,
    defaultOrganizationTypeOption,
    moduleOptions,
    defaultModuleOption,
    ModuleOptions,
    Modules,
  }
}
