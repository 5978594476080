import { createReducer } from '@reduxjs/toolkit'
import { ResourceDocument, Document, Tag } from '@vivium/frontend-common/types'
import resource, { Resource } from 'shared/resource'
import {
  Account,
  AccountDetail,
  ActivateDeactivateAccountResponse,
} from 'shared/services/accounts'
import { ResourceDetails } from 'shared/services/careProviderResource'
import {
  CpOrganizationGroup,
  CpOrganizationGroupAdmins,
} from 'shared/services/organizationGroups'
import {
  resetActivateAccount,
  resetDeleteAccount,
  resetChangeLoginInfo,
  deleteAccount,
  changeLoginInfo,
  getAllAccountsDetails,
  activateAccount,
  deactivateAccount,
  getAccountsDetails,
  updateAccount,
  addAccount,
  getAccount,
  resetDeactivateAccount,
  uploadResourceDocument, // CARE PROVIDER RESOURCE
  deleteResourceDocument,
  getTags,
  resetGetTags,
  createTag,
  resetCreateTag,
  createCareProviderResource,
  resetCareProviderCreateResource,
  getResourceDetails,
  updateCareProviderResource,
  resetUpdateCareProviderResource,
} from './actions'

export interface State {
  changeLoginInfo: Resource
  addAccount: Resource<number | null>
  getAccount: Resource<Account | null>
  updateAccount: Resource<Account | null>
  getAccountsDetails: Resource<AccountDetail[]>
  deactivateAccount: Resource<ActivateDeactivateAccountResponse | null>
  activateAccount: Resource<ActivateDeactivateAccountResponse | null>
  deleteAccount: Resource<number>
  getAllAccountsDetails: Resource<AccountDetail[]>

  // CARE PROVIDER RESOURCE
  deleteCareProviderResource: Resource<number>
  activateCareProviderResource: Resource<string>
  deactivateCareProviderResource: Resource<string>
  updateCareProviderResource: Resource<string>
  getResourceDetails: Resource<ResourceDetails | null>
  createTag: Resource<string>
  createCareProviderResource: Resource<string>
  getTags: Resource<Tag[]>
  resourceDocuments: Document[]
  uploadResourceDocument: Resource<ResourceDocument | null>
  updateCpOrganizationGroup: Resource<CpOrganizationGroup | null>
  getCpOrganizationGroups: Resource<CpOrganizationGroup[]>
  getCpOrganizationGroup: Resource<CpOrganizationGroup | null>
  getCpOrganizationGroupAdmins: Resource<CpOrganizationGroupAdmins[]>
  addCpOrganizationGroup: Resource<number | null>
  deleteResourceDocument: Resource<number>
  resourcePhoneNumber: string
}

const initialState: State = {
  changeLoginInfo: resource.getInitial(),
  getAllAccountsDetails: resource.getInitial<AccountDetail[]>(),
  deleteAccount: resource.getInitial<number>(),
  addAccount: resource.getInitial<number | null>(),
  getAccount: resource.getInitial<Account | null>(null),
  updateAccount: resource.getInitial<Account | null>(),
  getAccountsDetails: resource.getInitial<AccountDetail[]>([]),
  deactivateAccount:
    resource.getInitial<ActivateDeactivateAccountResponse | null>(),
  activateAccount:
    resource.getInitial<ActivateDeactivateAccountResponse | null>(),

  // CARE PROVIDER RESOURCE
  activateCareProviderResource: resource.getInitial(''),
  deactivateCareProviderResource: resource.getInitial(''),
  deleteCareProviderResource: resource.getInitial<number>(),
  updateCareProviderResource: resource.getInitial(''),
  getResourceDetails: resource.getInitial<ResourceDetails | null>(null),
  createCareProviderResource: resource.getInitial(''),
  resourcePhoneNumber: '',
  getTags: resource.getInitial<Tag[]>([]),
  createTag: resource.getInitial(''),
  deleteResourceDocument: resource.getInitial<number>(),
  resourceDocuments: [],
  uploadResourceDocument: resource.getInitial<ResourceDocument | null>(null),
  updateCpOrganizationGroup: resource.getInitial<CpOrganizationGroup | null>(),
  getCpOrganizationGroups: resource.getInitial<CpOrganizationGroup[]>([]),
  getCpOrganizationGroupAdmins: resource.getInitial<
    CpOrganizationGroupAdmins[]
  >([]),
  addCpOrganizationGroup: resource.getInitial<number | null>(),
  getCpOrganizationGroup: resource.getInitial<CpOrganizationGroup | null>(null),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(changeLoginInfo.pending, state => {
      resource.setPending(state.changeLoginInfo)
    })
    .addCase(changeLoginInfo.fulfilled, state => {
      resource.setSucceeded(state.changeLoginInfo)
    })
    .addCase(changeLoginInfo.rejected, (state, action) => {
      resource.setFailed(state.changeLoginInfo, action.error.message)
    })
    .addCase(addAccount.pending, state => {
      resource.setPending(state.addAccount)
    })
    .addCase(addAccount.fulfilled, (state, action) => {
      resource.setSucceeded(state.addAccount, action.payload)
    })
    .addCase(addAccount.rejected, (state, action) => {
      resource.setFailed(state.addAccount, action.error.message)
    })
    .addCase(getAccount.pending, state => {
      resource.setPending(state.getAccount)
    })
    .addCase(getAccount.fulfilled, (state, action) => {
      resource.setSucceeded(state.getAccount, action.payload)
    })
    .addCase(getAccount.rejected, (state, action) => {
      resource.setFailed(state.getAccount, action.error.message)
    })
    .addCase(updateAccount.pending, state => {
      resource.setPending(state.updateAccount)
    })
    .addCase(updateAccount.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateAccount, action.payload)
    })
    .addCase(updateAccount.rejected, (state, action) => {
      resource.setFailed(state.updateAccount, action.error.message)
    })
    .addCase(getAccountsDetails.pending, state => {
      resource.setPending(state.getAccountsDetails)
    })
    .addCase(getAccountsDetails.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getAccountsDetails,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getAccountsDetails.rejected, (state, action) => {
      resource.setFailed(state.getAccountsDetails, action.error.message)
    })
    .addCase(resetDeactivateAccount, state => {
      resource.reset(
        state.deactivateAccount,
        initialState.deactivateAccount.data
      )
    })
    .addCase(deactivateAccount.pending, state => {
      resource.setPending(state.deactivateAccount)
    })
    .addCase(deactivateAccount.fulfilled, (state, action) => {
      resource.setSucceeded(state.deactivateAccount, action.payload)
    })
    .addCase(deactivateAccount.rejected, (state, action) => {
      resource.setFailed(state.deactivateAccount, action.error.message)
    })
    .addCase(activateAccount.pending, state => {
      resource.setPending(state.activateAccount)
    })
    .addCase(activateAccount.fulfilled, (state, action) => {
      resource.setSucceeded(state.activateAccount, action.payload)
    })
    .addCase(activateAccount.rejected, (state, action) => {
      resource.setFailed(state.activateAccount, action.error.message)
    })
    .addCase(resetActivateAccount, state => {
      resource.reset(state.activateAccount, initialState.activateAccount.data)
    })
    .addCase(resetDeleteAccount, state => {
      resource.reset(state.deleteAccount)
    })
    .addCase(resetChangeLoginInfo, state => {
      resource.reset(state.changeLoginInfo)
    })
    .addCase(deleteAccount.pending, state => {
      resource.setPending(state.deleteAccount)
    })
    .addCase(deleteAccount.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteAccount, action.payload)
    })
    .addCase(deleteAccount.rejected, (state, action) => {
      resource.setFailed(state.deleteAccount, action.error.message)
    })
    .addCase(getAllAccountsDetails.pending, state => {
      resource.setPending(state.getAllAccountsDetails)
    })
    .addCase(getAllAccountsDetails.fulfilled, (state, action) => {
      resource.setSucceeded(state.getAllAccountsDetails, action.payload.data)
    })
    .addCase(getAllAccountsDetails.rejected, (state, action) => {
      resource.setFailed(state.getAllAccountsDetails, action.error.message)
    })

    // CARE PROVIDER RESOURCE
    .addCase(updateCareProviderResource.pending, state => {
      resource.setPending(state.updateCareProviderResource)
    })
    .addCase(updateCareProviderResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateCareProviderResource, action.payload)
      if (state.getResourceDetails.data)
        state.getResourceDetails.data.name = action.meta.arg.name
      state.resourcePhoneNumber = action.meta.arg.contactPhoneNumber
    })
    .addCase(updateCareProviderResource.rejected, (state, action) => {
      resource.setFailed(
        state.updateCareProviderResource,
        (action.payload as string) || action.error.message
      )
    })
    .addCase(resetUpdateCareProviderResource, state => {
      resource.reset(
        state.updateCareProviderResource,
        initialState.updateCareProviderResource.data
      )
      state.resourceDocuments = initialState.resourceDocuments
    })
    .addCase(getResourceDetails.pending, state => {
      resource.setPending(state.getResourceDetails)
    })
    .addCase(getResourceDetails.fulfilled, (state, action) => {
      resource.setSucceeded(state.getResourceDetails, action.payload)
      state.resourceDocuments = action.payload.documents
      state.resourcePhoneNumber = action.payload.contactPhoneNumber
    })
    .addCase(getResourceDetails.rejected, (state, action) => {
      resource.setFailed(state.getResourceDetails, action.error.message)
    })
    .addCase(createCareProviderResource.pending, state => {
      resource.setPending(state.createCareProviderResource)
    })
    .addCase(createCareProviderResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.createCareProviderResource, action.payload)
    })
    .addCase(createCareProviderResource.rejected, (state, action) => {
      resource.setFailed(
        state.createCareProviderResource,
        (action.payload as string) || action.error.message
      )
    })
    .addCase(resetCareProviderCreateResource, state => {
      resource.reset(
        state.createCareProviderResource,
        initialState.createCareProviderResource.data
      )
      state.resourceDocuments = initialState.resourceDocuments
    })
    .addCase(createTag.pending, state => {
      resource.setPending(state.createTag)
    })
    .addCase(createTag.fulfilled, (state, action) => {
      resource.setSucceeded(state.createTag, action.payload)
    })
    .addCase(createTag.rejected, (state, action) => {
      resource.setFailed(state.createTag, action.error.message)
    })
    .addCase(resetCreateTag, state => {
      resource.reset(state.createTag)
    })
    .addCase(getTags.pending, state => {
      resource.setPending(state.getTags)
    })
    .addCase(getTags.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getTags,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getTags.rejected, (state, action) => {
      resource.setFailed(state.getTags, action.error.message)
    })
    .addCase(resetGetTags, state => {
      resource.reset(state.getTags)
    })
    .addCase(deleteResourceDocument.pending, state => {
      resource.setPending(state.uploadResourceDocument)
    })
    .addCase(deleteResourceDocument.fulfilled, (state, action) => {
      resource.setSucceeded(state.uploadResourceDocument)
      state.resourceDocuments = state.resourceDocuments.filter(
        document => document.id !== action.meta.arg.params.documentId
      )
    })
    .addCase(deleteResourceDocument.rejected, (state, action) => {
      resource.setFailed(state.uploadResourceDocument, action.error.message)
    })
    .addCase(uploadResourceDocument.pending, state => {
      resource.setPending(state.uploadResourceDocument)
    })
    .addCase(uploadResourceDocument.fulfilled, (state, action) => {
      resource.setSucceeded(state.uploadResourceDocument)
      if (action.payload) {
        state.resourceDocuments.push(action.payload)
      }
    })
    .addCase(uploadResourceDocument.rejected, (state, action) => {
      resource.setFailed(state.uploadResourceDocument, action.error.message)
    })
)
