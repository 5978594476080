import Api from '../api'
import { PostEmail } from './Email.types'

class Email extends Api {
  public sendEmail = async ({ ...payload }: PostEmail) => {
    const { data } = await this.api.post<number>('/admin/emails', payload)
    return data
  }
}

export default Email
