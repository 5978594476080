import Api from '../api'
import {
  GetOrganizationsResponse,
  GetOrganizationsParams,
  GetOrganizationsDetailsParams,
  GetOrganizationsDetailsResponse,
  GetStaffProviderSuggestionsParams,
  GetStaffProviderSuggestionsResponse,
  PostOrganizationScheduling,
  PostOrganizationStaffing,
  GetStaffProviderOrganizationResponse,
  GetCareProviderOrganizationResponse,
  AssignStaffProvidersToCareProviderPayload,
  UnassignStaffProvidersFromCareProviderPayload,
  AssignCareProvidersToStaffProviderPayload,
  UnassignCareProvidersFromStaffProviderPayload,
} from './Organizations.types'

class Organizations extends Api {
  public assignCareProvidersToStaffProvider = async ({
    staffProviderId,
    ...payload
  }: AssignCareProvidersToStaffProviderPayload) => {
    const { data } = await this.api.post<number>(
      `/admin/staff-providers/${staffProviderId}/care-providers/assign`,
      payload
    )
    return data
  }
  public unassignCareProvidersFromStaffProvider = async ({
    staffProviderId,
    ...payload
  }: UnassignCareProvidersFromStaffProviderPayload) => {
    const { data } = await this.api.post<number>(
      `/admin/staff-providers/${staffProviderId}/care-providers/unassign`,
      payload
    )
    return data
  }
  public assignStaffProvidersToCareProvider = async ({
    careProviderId,
    ...payload
  }: AssignStaffProvidersToCareProviderPayload) => {
    const { data } = await this.api.post<number>(
      `/admin/care-providers/${careProviderId}/staff-providers/assign`,
      payload
    )
    return data
  }
  public unassignStaffProvidersFromCareProvider = async ({
    careProviderId,
    ...payload
  }: UnassignStaffProvidersFromCareProviderPayload) => {
    const { data } = await this.api.post<number>(
      `/admin/care-providers/${careProviderId}/staff-providers/unassign`,
      payload
    )
    return data
  }
  public addOrganizationStaffing = async ({
    ...payload
  }: PostOrganizationStaffing) => {
    const { data } = await this.api.post<number>(
      '/admin/staff-providers/organizations',
      payload
    )
    return data
  }
  public addOrganizationScheduling = async ({
    ...payload
  }: PostOrganizationScheduling) => {
    const { data } = await this.api.post<number>(
      '/admin/care-providers/organizations',
      payload
    )
    return data
  }
  public getStaffProviderOrganization = async (id: number) => {
    const { data } = await this.api.get<GetStaffProviderOrganizationResponse>(
      `/admin/staff-providers/organizations/${id}`
    )

    return data
  }
  public getCareProviderOrganization = async (id: number) => {
    const { data } = await this.api.get<GetCareProviderOrganizationResponse>(
      `/admin/care-providers/organizations/${id}`
    )

    return data
  }
  public getOrganizations = async (params?: GetOrganizationsParams) => {
    const { data } = await this.api.get<GetOrganizationsResponse>(
      '/admin/organizations',
      { params }
    )
    return data.data
  }
  public getOrganizationsDetails = async (
    params: GetOrganizationsDetailsParams
  ) => {
    const response = await this.api.get<GetOrganizationsDetailsResponse>(
      '/admin/organizations/details',
      { params }
    )
    const { data, ...meta } = response.data

    return { data, meta }
  }
  public getStaffProviderSuggestions = async (
    params: GetStaffProviderSuggestionsParams
  ) => {
    const { data } = await this.api.get<GetStaffProviderSuggestionsResponse>(
      '/admin/suggestions/staff-providers',
      { params }
    )

    return data.data
  }
}

export default Organizations
