import { Button, Grid, CircularProgress } from '@material-ui/core'
import { WatchLater } from '@material-ui/icons'
import {
  TextField,
  Form,
  DateField,
  TimeField,
  ErrorHandler,
} from '@vivium/frontend-common/components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RichTextEditor } from 'shared/components'
import {
  useInformationAutocompleteOptions,
  useGetMessageUpdate,
} from '../../pages'
import { MODULE_NAME } from '../../strings'
import {
  DateFieldWrapper,
  EmailBodyWrapper,
  ActionsWrapper,
  StyledLoadingButton,
  GridDateWrapper,
  TextFieldWrapper,
  Container,
  StyledAutocompleteField,
} from './UpdatePutForm.styles'
import {
  UpdateFields,
  useFormProps,
  useOnSubmit,
  MAX_TITLE_LENGTH,
  MAX_MESSAGE_LENGTH,
  useConvertFetchedDataToFormValues,
} from './UpdatePutForm.utils'

interface UpdateFormProps {
  onCloseModal: () => void
  id: number
}

const UpdatePutForm = ({ onCloseModal, id }: UpdateFormProps) => {
  const { t } = useTranslation(MODULE_NAME)
  const formProps = useFormProps()
  const { accessibleApplicationsOptions } = useInformationAutocompleteOptions()
  const { isSubmitting, handleSubmit } = useOnSubmit(id, onCloseModal)
  const { messageUpdate, error, isLoading } = useGetMessageUpdate(id)
  const formValues = useConvertFetchedDataToFormValues(messageUpdate)
  useEffect(() => {
    if (messageUpdate !== null && !error) {
      formProps.reset(formValues)
    }
  }, [messageUpdate, isLoading]) //eslint-disable-line

  return (
    <>
      <Form {...formProps} onSubmit={handleSubmit}>
        {isLoading && (
          <Container container alignItems="center" justifyContent="center">
            <CircularProgress />
          </Container>
        )}
        {error && (
          <Container container alignItems="center" justifyContent="center">
            <ErrorHandler message={error} />
          </Container>
        )}
        {!error && !isLoading && messageUpdate && (
          <>
            <Grid container justifyContent="center" alignItems="center">
              <TextFieldWrapper container>
                <StyledAutocompleteField
                  disableClearable
                  label={t('informationsForms.recipients')}
                  options={accessibleApplicationsOptions}
                  name={UpdateFields.AccessibleApplications}
                />
              </TextFieldWrapper>
              <DateFieldWrapper container item xs={12}>
                <GridDateWrapper item xs={4}>
                  <DateField
                    name={UpdateFields.PublicationDate}
                    label={t('informationsForms.publicationDate')}
                    placeholder={t('informationsForms.datePlaceholder')}
                    formatToIsoString
                    disablePast
                  />
                </GridDateWrapper>
                <Grid item xs={2}>
                  <TimeField
                    label=" "
                    IconComponent={WatchLater}
                    name={UpdateFields.PublicationTime}
                  />
                </Grid>
              </DateFieldWrapper>
              <TextFieldWrapper item xs={12}>
                <TextField
                  required
                  label={t('informationsForms.message')}
                  name={UpdateFields.UpdateMessage}
                  charactersCounter={MAX_MESSAGE_LENGTH}
                  inputProps={{ maxLength: MAX_MESSAGE_LENGTH }}
                />
              </TextFieldWrapper>
              <TextFieldWrapper item xs={12}>
                <TextField
                  required
                  label={t('informationsForms.tittle')}
                  name={UpdateFields.Title}
                  charactersCounter={MAX_TITLE_LENGTH}
                  inputProps={{ maxLength: MAX_TITLE_LENGTH }}
                />
              </TextFieldWrapper>
              <EmailBodyWrapper container item md={11} sm={12}>
                <RichTextEditor
                  defaultValue={messageUpdate.body}
                  name={UpdateFields.MessageBody}
                />
              </EmailBodyWrapper>
            </Grid>
            <ActionsWrapper container justifyContent="flex-end">
              <Button fullWidth={false} onClick={onCloseModal}>
                {t('informationsForms.cancel')}
              </Button>
              <StyledLoadingButton
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={false}
                loading={isSubmitting}
              >
                {t('informations.create')}
              </StyledLoadingButton>
            </ActionsWrapper>
          </>
        )}
      </Form>
    </>
  )
}

export default UpdatePutForm
