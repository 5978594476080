import { Container, Grid } from '@material-ui/core'
import { ErrorHandler } from '@vivium/frontend-common/components'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MessageDeliveryType } from 'shared/types'
import { RecipientsFields } from '../RecipientsSection'
import { StyledAutocompleteField } from './SelectRecipientsAutocomplete.style'
import {
  useAutocompleteRecipientsOptions,
  useGetRecipients,
} from './SelectRecipientsAutocomplete.utils'

interface SelectRecipientsAutocompleteProps {
  name: string
  messageDeliveryType: MessageDeliveryType
}

const SelectRecipientsAutocomplete = ({
  name,
  messageDeliveryType,
}: SelectRecipientsAutocompleteProps) => {
  const [
    selectedOrganizationType,
    selectedOrganization,
    selectedOrganizationStatus,
  ] = useWatch({
    name: [
      RecipientsFields.OrganizationType,
      RecipientsFields.Organization,
      RecipientsFields.AccountStatus,
    ],
  })
  const { t } = useTranslation()
  const { setValue } = useFormContext()
  const { isLoading, error, recipients } = useGetRecipients(
    selectedOrganizationType.value,
    selectedOrganization,
    selectedOrganizationStatus.value,
    messageDeliveryType
  )
  const autocompleteOptions = useAutocompleteRecipientsOptions()
  useEffect(() => {
    if (autocompleteOptions.length > 0)
      setValue(RecipientsFields.Recipients, autocompleteOptions[0])
    if (autocompleteOptions.length === 0)
      setValue(RecipientsFields.Recipients, [])
  }, [recipients]) //eslint-disable-line

  return (
    <>
      {error ? (
        <Container>
          <ErrorHandler message={error} />
        </Container>
      ) : (
        <Grid container item xs={12} sm={6} md={5} lg={3}>
          <StyledAutocompleteField
            optionsLength={autocompleteOptions.length}
            disableClearable
            name={name}
            options={autocompleteOptions}
            loading={isLoading}
            fullWidth
            label={t('sendMessage.filters.recipients')}
            placeholder={t('sendMessage.noRecipients')}
          />
        </Grid>
      )}
    </>
  )
}

export default SelectRecipientsAutocomplete
