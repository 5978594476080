import type { RootState } from 'app'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { ResourceActionCreator, Resource, ResourceData } from './types'

export const useResource = <
  Data = unknown,
  Payload = unknown,
  AdditionalPayload = unknown
>(
  actionCreator: ResourceActionCreator<Payload>,
  selector: (state: RootState) => Resource<Data>,
  additionalPayload?: AdditionalPayload
): ResourceData<Data, Payload> => {
  const dispatch = useDispatch()
  const data = useSelector<RootState, Resource<Data>>(selector)
  const getData = useCallback(
    (payload: Payload) =>
      dispatch(actionCreator({ ...additionalPayload, ...payload })),
    [dispatch, actionCreator, additionalPayload]
  )
  return { ...data, getData }
}
