import { styled, Theme } from '@material-ui/core'
import { theme } from 'config'
import { ModulePillTypes } from 'shared/types'

interface ModulePillProps {
  pillType: ModulePillTypes
}

const modulePillColors: Record<ModulePillTypes, string> = {
  [ModulePillTypes.ViviumI]: theme.palette.pill.viviumI.main,
  [ModulePillTypes.ViviumX]: theme.palette.pill.viviumX.main,
}

export const ModulePillWrapper = styled('div')<Theme, ModulePillProps>(
  ({ theme, pillType }) => ({
    borderRadius: 25,
    width: 130,
    padding: theme.spacing(0, 4),
    '& p:before': {
      content: '',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
    },
    color: theme.palette.pill.contrastText.main,
    backgroundColor: modulePillColors[pillType],
  })
)
