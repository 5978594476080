import { createAsyncThunk } from '@reduxjs/toolkit'
import { createAsyncThunkWithErrorHandling } from '@vivium/frontend-common/utils'
import {
  GetCpOrganizationGroupsParams,
  GetCpOrganizationGroupParams,
  GetCpOrganizationGroupAdminsParams,
  PostCpOrganizationGroup,
  PutCpOrganizationGroup,
  OrganizationGroups,
} from 'shared/services/organizationGroups'
import { MODULE_NAME } from '../../strings'

const cpOrganizationGroups = new OrganizationGroups()

export const getCpOrganizationGroups = createAsyncThunk(
  `${MODULE_NAME}/getCpOrganizationGroups`,
  (payload?: GetCpOrganizationGroupsParams) =>
    cpOrganizationGroups.getCpOrganizationGroups(payload)
)

export const getCpOrganizationGroup = createAsyncThunk(
  `${MODULE_NAME}/getCpOrganizationGroup`,
  (payload: GetCpOrganizationGroupParams) =>
    cpOrganizationGroups.getCpOrganizationGroup(payload)
)

export const getCpOrganizationGroupAdmins = createAsyncThunk(
  `${MODULE_NAME}/getCpOrganizationGroupAdmins`,
  (payload: GetCpOrganizationGroupAdminsParams) =>
    cpOrganizationGroups.getCpOrganizationGroupAdmins(payload)
)

export const addCpOrganizationGroup = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/addCpOrganizationGroup`,
  (payload: PostCpOrganizationGroup) =>
    cpOrganizationGroups.addCpOrganizationGroup(payload)
)

export const updateCpOrganizationGroup = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/updateCpOrganizationGroup`,
  (payload: PutCpOrganizationGroup) =>
    cpOrganizationGroups.putCpOrganizationGroup(payload)
)
