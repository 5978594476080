import Api from '../api'
import {
  GetStaffProviderResourceParams,
  GetStaffProviderResourceResponse,
  DeleteStaffProviderResourceParams,
  DeleteStaffProviderResourceResponse,
  GetAllStaffProviderResourceParams,
  CreateStaffProviderResourceParams,
  CreateStaffProviderResourceResponse,
} from './StaffProviderResource.types'

class StaffProviderResource extends Api {
  public createResource = async ({
    staffProviderId,
    ...payload
  }: CreateStaffProviderResourceParams) => {
    const headers = await this.getCsrfHeaders()
    const { data } = await this.api.post<CreateStaffProviderResourceResponse>(
      `/mock/staff-providers/${staffProviderId}/resources`,
      payload,
      { headers }
    )
    return data
  }
  public deleteStaffProviderResource = async (
    params: DeleteStaffProviderResourceParams
  ) => {
    const { data } = await this.api.delete<DeleteStaffProviderResourceResponse>(
      `/mock/admin/staff-providers/resources`,
      { params }
    )
    return data
  }
  public getStaffProviderResourceDetails = async (
    params: GetStaffProviderResourceParams
  ) => {
    const response = await this.api.get<GetStaffProviderResourceResponse>(
      '/mock/admin/staff-providers/resources/details',
      { params }
    )
    const { data, ...meta } = response.data

    return { data, meta }
  }
  public getAllStaffProviderResourceDetails = async (
    params: GetAllStaffProviderResourceParams
  ) => {
    const { data } = await this.api.get<GetStaffProviderResourceResponse>(
      '/mock/admin/staff-providers/resources/details',
      { params }
    )
    return data
  }
}

export default StaffProviderResource
