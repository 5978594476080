import { Button, Grid, InputLabel } from '@material-ui/core'
import { ConfirmationModal, Tooltip } from '@vivium/frontend-common/components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledCheck } from './LoginInfoReset.styles'
import { useLoginInfoReset } from './LoginInfoReset.utils'

interface LoginInfoResetProps {
  accountId: number
  accountName?: string
  hasPhoneNumber: boolean
  withLabel?: boolean
}

const LoginInfoReset = ({
  withLabel,
  hasPhoneNumber,
  accountName,
  accountId,
}: LoginInfoResetProps) => {
  const { t } = useTranslation()

  const resetConfirmationQuestion = t(
    'resetLoginInformation.confirmationQuestion',
    { accountName }
  )

  const {
    isDirty,
    hasBeenReset,
    isActivationModalOpen,
    successText,
    onResetViaEmailClick,
    onResetViaSmsClick,
    resetLoginInfo,
    closeActivationModal,
  } = useLoginInfoReset(accountId)

  const isDisabled = useMemo(
    () => hasBeenReset || isDirty || !hasPhoneNumber,
    [hasBeenReset, hasPhoneNumber, isDirty]
  )
  return (
    <Grid container spacing={2} direction="column">
      {withLabel && (
        <Grid item xs={12}>
          <InputLabel>{t('resetLoginInformation.label')}</InputLabel>
        </Grid>
      )}
      <Grid item>
        <Tooltip
          title={t('resetLoginInformation.saveFirst') || ''}
          disabled={!isDirty}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                fullWidth={false}
                disabled={isDisabled}
                onClick={onResetViaSmsClick}
              >
                {t('resetLoginInformation.sms')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                fullWidth={false}
                onClick={onResetViaEmailClick}
                disabled={hasBeenReset || isDirty}
              >
                {t('resetLoginInformation.email')}
              </Button>
            </Grid>
          </Grid>
        </Tooltip>
      </Grid>
      <Grid item>
        {hasBeenReset && (
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <StyledCheck />
            </Grid>
            <Grid item xs>
              {successText}
            </Grid>
          </Grid>
        )}
      </Grid>
      <ConfirmationModal
        open={isActivationModalOpen}
        mainContent={resetConfirmationQuestion}
        confirmationButtonLabel={t('resetLoginInformation.confirmationText')}
        onConfirmation={resetLoginInfo}
        onCancel={closeActivationModal}
      />
    </Grid>
  )
}

export default LoginInfoReset
