import { styled, Theme } from '@material-ui/core'
import { AutocompleteField } from '@vivium/frontend-common/components'

interface AutocompleteFieldProps {
  optionsLength: number
}

export const StyledAutocompleteField = styled(AutocompleteField)<
  Theme,
  AutocompleteFieldProps
>(({ theme, optionsLength }) => ({
  '& input': {
    ...(optionsLength === 1 && {
      color: theme.palette.text.secondary,
    }),
  },
}))
