import { styled } from '@material-ui/core'
import {
  ExitToApp as BaseExitToApp,
  AccountCircleOutlined,
} from '@material-ui/icons'

export const ExitToApp = styled(BaseExitToApp)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

export const AccountCircle = styled(AccountCircleOutlined)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))
