import { styled, Grid, Theme } from '@material-ui/core'
import { theme } from 'config'
import { InformationStatuses } from 'shared/types'

interface InformationStatusPillProps {
  status: InformationStatuses
}

const informationStatusColors: Record<InformationStatuses, string> = {
  [InformationStatuses.Published]: theme.palette.pill.green.main,
  [InformationStatuses.Upcoming]: theme.palette.pill.yellow.main,
  [InformationStatuses.Expired]: theme.palette.pill.gray.main,
}

export const InfromationStatusPill = styled(Grid)<
  Theme,
  InformationStatusPillProps
>(({ theme, status }) => ({
  width: 120,
  borderRadius: 25,
  padding: theme.spacing(0, 1),
  marginLeft: theme.spacing(0.5),
  color: theme.palette.pill.contrastText.main,
  backgroundColor: informationStatusColors[status],
}))
