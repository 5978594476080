import { Loadable } from '@vivium/frontend-common/components'
import { ModuleRoute } from '@vivium/frontend-common/types'
import { paths } from 'config'

const routes: ModuleRoute[] = [
  {
    path: paths.signIn,
    exact: true,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/Login'),
    }),
  },
  {
    path: paths.activateAccount,
    exact: true,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/ActivateAccount'),
    }),
  },
  {
    path: paths.newPassword,
    exact: true,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/NewPassword'),
    }),
  },
  {
    path: paths.forgotPassword,
    exact: true,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/ForgotPassword'),
    }),
  },
  {
    path: paths.multifactorAuthentication,
    exact: true,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/Multifactor'),
    }),
  },
  {
    path: paths.unauthorized,
    exact: true,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/Unauthorized'),
    }),
  },
  {
    path: paths.forbidden,
    exact: true,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/Forbidden'),
    }),
  },
]

export default routes
