import { createAsyncThunk } from '@reduxjs/toolkit'
import Email from 'shared/services/email'
import { MODULE_NAME } from '../strings'
import { SendEmailPayload } from './actions.types'

const email = new Email()

export const sendEmail = createAsyncThunk(
  `${MODULE_NAME}/sendEmail`,
  async ({ payload, onSuccess, onError }: SendEmailPayload) => {
    try {
      const data = await email.sendEmail(payload)
      if (onSuccess) onSuccess()
      return data
    } catch (error) {
      if (onError) onError()
    }
  }
)
