import { createAsyncThunk } from '@reduxjs/toolkit'
import { createAsyncThunkWithErrorHandling } from '@vivium/frontend-common/utils'
import { Faqs } from 'shared/services/faqs'
import {
  GetFaqsParams,
  FaqUpdateParams,
  PostFaqParams,
} from 'shared/services/faqs'
import { MODULE_NAME } from '../strings'

const faqs = new Faqs()

export const updateFaq = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/updateFaq`,
  (payload: FaqUpdateParams) => faqs.updateFaq(payload)
)

export const addFaq = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/addFaq`,
  (payload: PostFaqParams) => faqs.addFaq(payload)
)

export const getFaqs = createAsyncThunk(
  `${MODULE_NAME}/getFaqs`,
  (payload?: GetFaqsParams) => faqs.getFaqs(payload)
)

export const getFaq = createAsyncThunk(
  `${MODULE_NAME}/getFaq`,
  (payload: number) => faqs.getFaq(payload)
)

export const deleteFaq = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deleteFaq`,
  (payload: number) => faqs.deleteFaq(payload)
)
