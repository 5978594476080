import { LoadingStatus } from '@vivium/frontend-common/types'
import { format, parseISO } from 'date-fns'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateSortBy, UpdateSortOrder } from 'shared/services/messageUpdate'
import { actions, selectors } from '../../store'

export const useGetMessagesUpdates = () => {
  const dispatch = useDispatch()

  const {
    data: messagesUpdates,
    loading,
    error,
  } = useSelector(selectors.getMessagesUpdates)

  useEffect(() => {
    dispatch(
      actions.getMessagesUpdates({
        sortBy: UpdateSortBy.CreatedAt,
        sortOrder: UpdateSortOrder.Descending,
      })
    )
  }, []) //eslint-disable-line

  return {
    messagesUpdates,
    isLoading: loading === LoadingStatus.Pending,
    error,
  }
}

export const formatDisplayedDate = (publicationDate: string) => {
  const dateFormat = 'MM/dd'
  const formattedPublicationDate = format(parseISO(publicationDate), dateFormat)
  return formattedPublicationDate
}
