import {
  Typography,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  CircularProgress,
  Container,
} from '@material-ui/core'
import { EditOutlined, DeleteForeverOutlined } from '@material-ui/icons'
import {
  ErrorHandler,
  DataTableEmptyMessage,
} from '@vivium/frontend-common/components'
import { useTranslation } from 'react-i18next'
import { ModulePill } from 'shared/components'
import { useModal } from 'shared/hooks'
import {
  ModalTypes,
  InformationStatuses,
  Messages,
  ModulePillTypes,
} from 'shared/types'
import {
  InformationStatusPill,
  DeleteActualizationModal,
  InformationUpdateForm,
} from '..'
import { MODULE_NAME } from '../../strings'
import {
  GridContainer,
  FormHeaderWrapper,
  SectionHeaderWrapper,
  Table,
  StyledTableRow,
  TableWrapper,
  FormWrapper,
  ModalContainer,
  TablePaper,
  ActionsWrapper,
  LoaderWrapper,
  EmptyDataWrapper,
  PillWrapper,
} from './InformationsSection.styles'
import {
  useGetMessagesInformations,
  formatDisplayedDate,
} from './informationsSection.utils'

const InformationsSection = () => {
  const { t } = useTranslation(MODULE_NAME)
  const { openModal, closeModal, isModalOpen, modalType, id } = useModal()
  const { messagesInformations, isLoading, error } =
    useGetMessagesInformations()

  const showDeleteModal = modalType === ModalTypes.Delete && isModalOpen
  const showUpdateModal = isModalOpen && modalType === ModalTypes.Update

  return (
    <GridContainer>
      <SectionHeaderWrapper>
        <Typography variant="h5" component="h5">
          {t('informationsTable.informationMessage')}
        </Typography>
      </SectionHeaderWrapper>
      {error && (
        <Container>
          <ErrorHandler message={error} />
        </Container>
      )}
      <TablePaper>
        {isLoading && (
          <LoaderWrapper container alignItems="center" justifyContent="center">
            <CircularProgress />
          </LoaderWrapper>
        )}
        {!isLoading && !error && (
          <TableWrapper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{t('informationsTable.status')}</TableCell>
                  <TableCell>
                    {t('informationsTable.publicationDate')}
                  </TableCell>
                  <TableCell>{t('informationsTable.module')}</TableCell>
                  <TableCell>{t('informationsTable.messageContent')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messagesInformations.length > 0 &&
                  messagesInformations.map(
                    ({
                      accessibleApplications,
                      status,
                      startDate,
                      endDate,
                      message,
                      id: messageInfoId,
                    }) => (
                      <StyledTableRow key={`key-${messageInfoId}`}>
                        <TableCell>
                          <InformationStatusPill
                            label={t(`statusPills.${status}`)}
                            status={status}
                          />
                        </TableCell>
                        <TableCell>
                          {formatDisplayedDate(startDate, endDate)}
                        </TableCell>
                        <TableCell>
                          <Grid container>
                            {accessibleApplications.map(module => (
                              <PillWrapper
                                key={`key-${messageInfoId}-${module}`}
                              >
                                <ModulePill
                                  label={t(`accessibleApplications.${module}`)}
                                  pillType={module as any as ModulePillTypes}
                                />
                              </PillWrapper>
                            ))}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid>{message}</Grid>
                          {showDeleteModal && messageInfoId === id && (
                            <DeleteActualizationModal
                              messageType={Messages.Information}
                              onCloseModal={closeModal}
                              id={messageInfoId}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <ActionsWrapper
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            {status !== InformationStatuses.Expired && (
                              <EditOutlined
                                onClick={e =>
                                  openModal(e, ModalTypes.Update, messageInfoId)
                                }
                              />
                            )}
                            <DeleteForeverOutlined
                              onClick={e =>
                                openModal(e, ModalTypes.Delete, messageInfoId)
                              }
                            />
                          </ActionsWrapper>
                        </TableCell>
                      </StyledTableRow>
                    )
                  )}
              </TableBody>
            </Table>
            {messagesInformations.length == 0 && (
              <EmptyDataWrapper
                container
                alignItems="center"
                justifyContent="center"
              >
                <DataTableEmptyMessage colSpan={6} />
              </EmptyDataWrapper>
            )}
          </TableWrapper>
        )}
      </TablePaper>
      {showUpdateModal && (
        <ModalContainer open={isModalOpen} onClose={closeModal}>
          <FormWrapper>
            <FormHeaderWrapper>
              <Typography variant="h4" component="h4">
                {t('informations.newUpdate')}
              </Typography>
            </FormHeaderWrapper>
            <InformationUpdateForm onCloseModal={closeModal} id={id} />
          </FormWrapper>
        </ModalContainer>
      )}
    </GridContainer>
  )
}

export default InformationsSection
