import Api from '../api'
import { GetRecipientsResponse, GetRecipientsParams } from './Recipients.types'

class Recipients extends Api {
  public getRecipients = async (params?: GetRecipientsParams) => {
    const { data } = await this.api.get<GetRecipientsResponse>(
      '/admin/organizations/recipients',
      { params }
    )
    return data
  }
}

export default Recipients
