import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { createAsyncThunkWithErrorHandling } from '@vivium/frontend-common/utils'
import { AxiosError } from 'axios'
import {
  GetAccountParams,
  PutAccount,
  Accounts,
  GetAccountsDetailsParams,
  ActivateDeactivateAccountParams,
  GetAllAccountsDetailsParams,
  ResetLoginInfoParams,
  DeleteAccountParams,
} from 'shared/services/accounts'
import {
  CareProviderResource,
  CreateResourceParams,
  GetResourceDetailsParams,
  UpdateResourceParams,
} from 'shared/services/careProviderResource'
import {
  Documents,
  UploadResourceDocumentParams,
  DeleteResourceDocumentPayload,
} from 'shared/services/documents'
import { Tags, GetTagsParams, CreateTagParams } from 'shared/services/tags'
import { ToggleActivation } from 'shared/types'
import { MODULE_NAME } from '../../strings'
import { ChangeResourceStatusPayload, AddAccountPayload } from './action.types'

const accounts = new Accounts()

// CARE PROVIDER RESOURCE
const documents = new Documents()
const tags = new Tags()
const careProviderResource = new CareProviderResource()

export const resetActivateAccount = createAction(
  `${MODULE_NAME}/resetActivateAccount`
)

export const resetDeactivateAccount = createAction(
  `${MODULE_NAME}/resetDeactivateAccount`
)

export const resetChangeAccountStatus = createAction(
  `${MODULE_NAME}/resetChangeAccountStatus`
)

export const resetDeleteAccount = createAction(
  `${MODULE_NAME}/resetDeleteAccount`
)

export const resetChangeLoginInfo = createAction(
  `${MODULE_NAME}/resetChangeLoginInfo`
)

export const selectedAccountsIds = createAction(
  `${MODULE_NAME}/selectedAccountIds`
)

export const changeAccountStatus = createAsyncThunk(
  `${MODULE_NAME}/changeAccountStatus`,
  (payload: ChangeResourceStatusPayload) =>
    accounts.changeAccountStatus({
      ...payload,
      action: ToggleActivation.Activate,
    })
)

export const deleteAccount = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deleteAccount`,
  (payload: DeleteAccountParams) => accounts.deleteAccount(payload)
)

export const changeLoginInfo = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/changeLoginInfo`,
  async (payload: ResetLoginInfoParams) => {
    const data = await accounts.resetLoginInfo(payload)
    return data
  }
)

export const getAllAccountsDetails = createAsyncThunk(
  `${MODULE_NAME}/getAllAccountsDetails`,
  (payload: GetAllAccountsDetailsParams) => {
    return accounts.getAllAccountsDetails(payload)
  }
)

export const activateAccount = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/activateAccount`,
  (payload: ActivateDeactivateAccountParams) => {
    return accounts.activateAccount(payload)
  }
)

export const deactivateAccount = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deactivateAccount`,
  (payload: ActivateDeactivateAccountParams) => {
    return accounts.deactivateAccount(payload)
  }
)

export const getAccountsDetails = createAsyncThunk(
  `${MODULE_NAME}/getAccountsDetails`,
  (payload: GetAccountsDetailsParams) => {
    return accounts.getAccountsDetails(payload)
  }
)

export const updateAccount = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/updateAccount`,
  (payload: PutAccount) => accounts.putAccount(payload)
)

export const addAccount = createAsyncThunk(
  `${MODULE_NAME}/addAccount`,
  async ({ params, onSuccess, onFailure }: AddAccountPayload) => {
    try {
      const data = await accounts.addAccount(params)
      onSuccess(data)
      return data
    } catch {
      onFailure()
    }
  }
)

export const getAccount = createAsyncThunk(
  `${MODULE_NAME}/getAccount`,
  (payload: GetAccountParams) => accounts.getAccount(payload)
)

// CARE PROVIDER RESOURCE

export const resetUpdateCareProviderResource = createAction(
  `${MODULE_NAME}/resetUpdateResource`
)

export const updateCareProviderResource = createAsyncThunk(
  `${MODULE_NAME}/updateResource`,
  (payload: UpdateResourceParams, { rejectWithValue }) =>
    careProviderResource.updateResource(payload).catch((error: AxiosError) => {
      if (!error.response) throw error
      return rejectWithValue(error.response.data.message)
    })
)

export const getResourceDetails = createAsyncThunk(
  `${MODULE_NAME}/getResourceDetails`,
  (payload: GetResourceDetailsParams) =>
    careProviderResource.getResourceDetails(payload)
)

export const createCareProviderResource = createAsyncThunk(
  `${MODULE_NAME}/createCareProviderResource`,
  (payload: CreateResourceParams, { rejectWithValue }) =>
    careProviderResource.createResource(payload).catch((error: AxiosError) => {
      if (!error.response) throw error
      return rejectWithValue(error.response.data.message)
    })
)

export const resetCareProviderCreateResource = createAction(
  `${MODULE_NAME}/resetCreateCareProviderResource`
)

export const getTags = createAsyncThunk(
  `${MODULE_NAME}/getTags`,
  (payload: GetTagsParams) => tags.getTags(payload)
)

export const resetGetTags = createAction(`${MODULE_NAME}/resetGetTags`)

export const createTag = createAsyncThunk(
  `${MODULE_NAME}/createTag`,
  (payload: CreateTagParams) => tags.createTag(payload)
)

export const resetCreateTag = createAction(`${MODULE_NAME}/resetCreateTag`)

export const deleteResourceDocument = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deleteResourceDocument`,
  (payload: DeleteResourceDocumentPayload) =>
    documents.deleteResourceDocument(payload)
)

export const uploadResourceDocument = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/uploadResourceDocument`,
  async ({ careProviderId, type, file }: UploadResourceDocumentParams) => {
    const documentId = await documents.uploadResourceDocument(
      careProviderId,
      type,
      file.file
    )
    const data = await documents.getResourceDocument({
      careProviderId,
      documentId,
    })
    return data
  }
)
