import {
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  CircularProgress,
} from '@material-ui/core'
import { useModal } from '@vivium/frontend-common/hooks'
import { LoadingStatus } from '@vivium/frontend-common/types'
import { useTranslation } from 'react-i18next'
import { useAuth, useSignOut } from 'shared/hooks'
import { ExitToApp } from './UserMenu.styles'
import { getInitials } from './UserMenu.utils'

const LOADER_SIZE = 20

const UserMenu = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { loading, signOut } = useSignOut()
  const { openModal, closeModal, anchorEl, isModalOpen } = useModal()

  const initials = getInitials(user?.name)
  const isSigningOut = loading === LoadingStatus.Pending

  const handleSignOut = () => {
    signOut()
    closeModal()
  }

  return (
    <>
      <IconButton
        edge="start"
        data-test-id="icon-avatar-button"
        color="inherit"
        onClick={openModal}
      >
        <Avatar>
          {isSigningOut ? <CircularProgress size={LOADER_SIZE} /> : initials}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={isModalOpen}
        onClose={closeModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          disabled={isSigningOut}
          data-test-id="log-out-button"
          onClick={handleSignOut}
        >
          <ExitToApp />
          {t('topbar.logout')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
