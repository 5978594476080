import { LoadingStatus } from '@vivium/frontend-common/types'
import { RootState } from 'app'

export const getSpecializationsSuggestions = (state: RootState) =>
  state.system.rolesAndSpecializations.getSpecializationsSuggestions

export const unassignRoleToCareProvider = (state: RootState) =>
  state.system.rolesAndSpecializations.unassignRoleFromCareProvider

export const unassignRoleToCareProviderLoading = (state: RootState) =>
  state.system.rolesAndSpecializations.unassignRoleFromCareProvider.loading

export const assignRoleToCareProvider = (state: RootState) =>
  state.system.rolesAndSpecializations.assignRoleToCareProvider

export const assignSpecialtiesToSystemRole = (state: RootState) =>
  state.system.rolesAndSpecializations.assignSpecialtiesToSystemRole.loading

export const unassignSpecialtyFromSystemRoleLoading = (state: RootState) =>
  state.system.rolesAndSpecializations.unassignSpecialtyFromSystemRole.loading

export const assignRoleToCareProviderLoading = (state: RootState) =>
  state.system.rolesAndSpecializations.assignRoleToCareProvider.loading

export const getCareProviderRolesWithSpecialties = (state: RootState) =>
  state.system.rolesAndSpecializations.getCareProviderRolesWithSpecialties

export const getCareProviderRolesWithSpecialtiesIsLoading = (
  state: RootState
) =>
  state.system.rolesAndSpecializations.getCareProviderRolesWithSpecialties
    .loading === LoadingStatus.Pending

export const getCareProviderRolesDetails = (state: RootState) =>
  state.system.rolesAndSpecializations.getCareProviderRolesDetails

export const assignSpecialtiesToCareProvider = (state: RootState) =>
  state.system.rolesAndSpecializations.assignSpecialtiesToCareProvider

export const assignSpecialtiesToCareProviderLoading = (state: RootState) =>
  state.system.rolesAndSpecializations.assignSpecialtiesToCareProvider.loading

export const unassignSpecialtiesFromCareProvider = (state: RootState) =>
  state.system.rolesAndSpecializations.unassignSpecialtiesFromCareProvider

export const unassignSpecialtiesFromCareProviderLoading = (state: RootState) =>
  state.system.rolesAndSpecializations.unassignSpecialtiesFromCareProvider
    .loading

export const getSystemSpecializations = (state: RootState) =>
  state.system.rolesAndSpecializations.getSystemSpecializations

export const getSystemSpecializationsByRoleId = (state: RootState) =>
  state.system.rolesAndSpecializations.getSystemSpecializationsByRoleId

export const addSystemSpecialization = (state: RootState) =>
  state.system.rolesAndSpecializations.addSystemSpecialization

export const deleteSystemSpecializationLoading = (state: RootState) =>
  state.system.rolesAndSpecializations.deleteSystemSpecialization.loading ===
  LoadingStatus.Pending

export const getSystemRolesWithSpecialties = (state: RootState) =>
  state.system.rolesAndSpecializations.getSystemRolesWithSpecialties

export const updateSystemRole = (state: RootState) =>
  state.system.rolesAndSpecializations.updateRole

export const updateSystemRoleLoading = (state: RootState) =>
  state.system.rolesAndSpecializations.updateRole.loading

export const getSystemRoles = (state: RootState) =>
  state.system.rolesAndSpecializations.getSystemRoles

export const addSystemRole = (state: RootState) =>
  state.system.rolesAndSpecializations.addSystemRole

export const deleteSystemRoleLoading = (state: RootState) =>
  state.system.rolesAndSpecializations.deleteSystemRole.loading ===
  LoadingStatus.Pending

export const validationErrorModal = (state: RootState) =>
  state.system.rolesAndSpecializations.validationErrorModal
