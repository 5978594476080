const env = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  apiKey: process.env.REACT_APP_API_KEY || '',
  applicationId: process.env.REACT_APP_APPLICATION_ID || '',
  requestEncryptionAESSecretKey:
    process.env.REACT_APP_REQUEST_ENCRYPTION_AES_SECRET_KEY || '',
  requestEncryptionAESIv: process.env.REACT_APP_REQUEST_ENCRYPTION_AES_IV || '',
}

Object.entries(env).forEach(([key, value]) => {
  if (!value) {
    console.error(`Missing ${key} env variable.`)
  }
})

export default env
