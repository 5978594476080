import { useTranslation } from 'react-i18next'
import { OrganizationTypes } from 'shared/types'

export const defaultOrganizationType = OrganizationTypes.All

export const useAutocompleteOptions = () => {
  const { t } = useTranslation()

  const autocompleteOptions = [
    {
      label: t('organizationType.careProvider'),
      value: OrganizationTypes.CareProvider,
    },
    {
      label: t('organizationType.staffProvider'),
      value: OrganizationTypes.StaffProvider,
    },
    { label: t('organizationType.all'), value: null },
  ]

  const defaultAutocompleteOption = {
    label: t('organizationType.all'),
    value: null,
  }

  return {
    autocompleteOptions,
    defaultAutocompleteOption,
  }
}
