import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormProps,
  AutocompleteValue,
} from '@vivium/frontend-common/components'
import { LoadingStatus } from '@vivium/frontend-common/types'
import { addDays, formatISO } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { SchemaOf, object, string } from 'yup'
import {
  InformationSortBy,
  InformationSortOrder,
  PostMessageInformation,
} from 'shared/services/messageInformation'
import {
  useInformationAutocompleteOptions,
  dateAndTimeToIso,
  convertOptionToAccessibleApplicationArray,
  getDefaultTime,
} from '../../pages'
import { selectors, actions } from '../../store'

export enum InformationFields {
  AccessibleApplications = 'accessibleApplications',
  DateFrom = 'dateFrom',
  TimeFrom = 'timeFrom',
  DateTo = 'dateTo',
  TimeTo = 'timeTo',
  Message = 'message',
}

export const MAX_MESSAGE_LENGTH = 150

export interface InformationValues {
  [InformationFields.AccessibleApplications]: AutocompleteValue
  [InformationFields.DateFrom]: string
  [InformationFields.TimeFrom]: string
  [InformationFields.DateTo]: string
  [InformationFields.TimeTo]: string
  [InformationFields.Message]: string
}

export const useGetInformationDefaultValues = (): InformationValues => {
  const today = new Date()
  const defaultDateFrom = formatISO(today)
  const defaultDateTo = formatISO(addDays(today, 2))
  const defaultTime = getDefaultTime(today)
  const { defaultAccessibleApplicationOptions } =
    useInformationAutocompleteOptions()

  return {
    [InformationFields.AccessibleApplications]:
      defaultAccessibleApplicationOptions,
    [InformationFields.DateFrom]: defaultDateFrom,
    [InformationFields.TimeFrom]: defaultTime,
    [InformationFields.DateTo]: defaultDateTo,
    [InformationFields.TimeTo]: defaultTime,
    [InformationFields.Message]: '',
  }
}

export const useInformationSchema = (): SchemaOf<InformationValues> => {
  const { t } = useTranslation()
  return object()
    .shape({
      [InformationFields.DateFrom]: string().required(t('validation.required')),
      [InformationFields.DateTo]: string().required(t('validation.required')),
      [InformationFields.TimeFrom]: string().required(t('validation.required')),
      [InformationFields.TimeTo]: string().required(t('validation.required')),
      [InformationFields.Message]: string()
        .required(t('validation.required'))
        .max(
          MAX_MESSAGE_LENGTH,
          t('validation.characterLimit', {
            characterLimit: MAX_MESSAGE_LENGTH,
          })
        ),
    })
    .required()
}

const useValidationSchema = (): SchemaOf<InformationValues> => {
  const informationSchema = useInformationSchema()
  return object().shape({
    ...informationSchema.fields,
  })
}

const useGetDefaultValues = (): InformationValues => {
  const informationDefaultValues = useGetInformationDefaultValues()

  return {
    ...informationDefaultValues,
  }
}

export const useOnSubmit = (onCloseModal: () => void) => {
  const { loading } = useSelector(selectors.addMessageInformation)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onError = (message?: string) =>
    enqueueSnackbar(message || t('error.defaultMessage'), {
      variant: 'error',
    })

  const onSuccess = (message?: string) => {
    dispatch(
      actions.getMessagesInformations({
        sortBy: InformationSortBy.CreatedAt,
        sortOrder: InformationSortOrder.Descending,
      })
    )
    onCloseModal()
    return enqueueSnackbar(message || t('messages.addInformationSuccess'), {
      variant: 'success',
    })
  }

  const isSubmitting = loading === LoadingStatus.Pending

  const handleSubmit = (values: InformationValues) => {
    const endDate = dateAndTimeToIso(
      values[InformationFields.DateTo],
      values[InformationFields.TimeTo]
    )
    const startDate = dateAndTimeToIso(
      values[InformationFields.DateFrom],
      values[InformationFields.TimeFrom]
    )

    const payload: PostMessageInformation = {
      startDate,
      endDate,
      message: values[InformationFields.Message],
      accessibleApplications: convertOptionToAccessibleApplicationArray(
        values[InformationFields.AccessibleApplications]?.value
      ),
    }
    return dispatch(
      actions.addMessageInformation({ onError, onSuccess, payload })
    )
  }

  return { isSubmitting, handleSubmit }
}

export const useFormProps = (): Omit<
  FormProps<InformationValues>,
  'onSubmit'
> => {
  const schema = useValidationSchema()
  const defaultValues = useGetDefaultValues()
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  return methods
}
