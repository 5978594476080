import { styled, Grid } from '@material-ui/core'

export const PieChartWrapper = styled(Grid)(() => ({
  width: '100%',
  height: '100%',
  '& div': {
    flexDirection: 'row-reverse',
  },
  '& path': {
    strokeWidth: 0,
  },
  '& .MuiGrid-justify-content-xs-center': {
    justifyContent: 'flex-end',
  },
}))
