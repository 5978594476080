import { InformationStatuses, AccessibleApplications } from 'shared/types'

export interface PostMessageInformation {
  accessibleApplications: AccessibleApplications[]
  startDate: string | Date
  endDate: string | Date
  message: string
}

export enum InformationSortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export enum InformationSortBy {
  CreatedAt = 'createdAt',
  StartDate = 'startDate',
}

export interface GetMessagesInformationParams {
  sortOrder: InformationSortOrder
  sortBy: InformationSortBy
}

export interface MessageInfo {
  id: number
  accessibleApplications: AccessibleApplications[]
  status: InformationStatuses
  startDate: string
  endDate: string
  message: string
}

export interface MessageInfoUpdateParams {
  id: number
  accessibleApplications: AccessibleApplications[]
  startDate: string
  endDate: string
  message: string
}

export interface GetMessagesInformationResponse {
  data: MessageInfo[]
}

export interface GetMessageInformationResponse {
  data: MessageInfo
}
