import { AutocompleteFieldOption } from '@vivium/frontend-common/components'
import { useTranslation } from 'react-i18next'
import { MessageRecipientStatus } from 'shared/types'

export const useStatusAutocompleteOptions = () => {
  const { t } = useTranslation()

  const statusAutocompleteOptions = Object.values(MessageRecipientStatus).map(
    status => {
      switch (status) {
        case MessageRecipientStatus.Active:
          return {
            label: t(`sendMessage.status.${status}`),
            value: true,
          }
        case MessageRecipientStatus.Inactive:
          return {
            label: t(`sendMessage.status.${status}`),
            value: false,
          }
        case MessageRecipientStatus.All:
          return {
            label: t(`sendMessage.status.${status}`),
            value: undefined,
          }
        default:
          return []
      }
    }
  ) as AutocompleteFieldOption[]

  return {
    statusAutocompleteOptions,
    defaultStatusAutocompleteOption: {
      label: t(`sendMessage.status.${MessageRecipientStatus.All}`),
      value: undefined,
    },
  }
}
