import { LoadingStatus } from '@vivium/frontend-common/types'
import {
  format,
  parseISO,
  isEqual,
  addDays,
  formatISO,
  startOfToday,
} from 'date-fns'
import { useEffect } from 'react'
import { Control, useWatch, UseFormSetValue } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  InformationSortOrder,
  InformationSortBy,
} from 'shared/services/messageInformation'
import { actions, selectors } from '../../store'
import {
  InformationValues,
  InformationFields,
} from '../InformationForm/InformationForm.utils'

export const useGetAvailableTimeRangeValues = (
  control: Control<InformationValues, object>,
  setValue: UseFormSetValue<InformationValues>
) => {
  const { dateFrom, dateTo, timeTo, timeFrom } = useWatch<InformationValues>({
    control,
  })
  const fifteenMinutesBeforeNextDay = '23.45'
  const isDateRangeValid = areDatesEqual(dateFrom as string, dateTo as string)
  const timeFromFieldRange = getTimeFromFieldRange(isDateRangeValid, timeTo)
  const timeToFieldRange = getTimeToFieldRange(isDateRangeValid, timeFrom)
  useEffect(() => {
    if (isDateRangeValid && timeToFieldRange && timeFromFieldRange) {
      setValue(InformationFields.TimeTo, timeToFieldRange)
      if (timeTo === fifteenMinutesBeforeNextDay) {
        setValue(InformationFields.DateTo, addDayToDate(dateTo))
      }
    }
  }, [isDateRangeValid]) // eslint-disable-line
  return {
    timeFromFieldRange,
    timeToFieldRange,
  }
}

export const areDatesEqual = (dateFrom: string, dateTo: string) => {
  const parseISOWithoutTime = (date: string) => parseISO(date.split('T')[0])
  const dateFromISO = parseISOWithoutTime(dateFrom)
  const dateToISO = parseISOWithoutTime(dateTo)
  return isEqual(dateFromISO, dateToISO)
}

const modifyTimeByMinutes = (
  time: string,
  modify: (time: number, minutesInMs: number) => number
) => {
  const today = startOfToday()
  const fifteenMinutesInMs = 900000
  const [hoursValue, minutesValue] = time.split('.').map(val => parseInt(val))
  const dateWithTimeRangeInMs = modify(
    today.setHours(hoursValue, minutesValue, 0),
    fifteenMinutesInMs
  )

  const dateWithTimeRange = new Date(dateWithTimeRangeInMs)
  return format(dateWithTimeRange, 'HH.mm')
}

export const getTimeToFieldRange = (
  isDateRangeValid: boolean,
  timeFrom?: string
) => {
  if (!isDateRangeValid || !timeFrom) return
  return modifyTimeByMinutes(
    timeFrom,
    (time, minutesInMs) => time + minutesInMs
  )
}

export const getTimeFromFieldRange = (
  isDateRangeValid: boolean,
  timeTo?: string
) => {
  if (!isDateRangeValid || !timeTo) return
  return modifyTimeByMinutes(timeTo, (time, minutesInMs) => time - minutesInMs)
}

export const addDayToDate = (date?: string) => {
  if (!date) return ''
  const dateInDateFormat = startOfToday()
  return formatISO(addDays(dateInDateFormat, 1))
}

export const useGetMessagesInformations = () => {
  const dispatch = useDispatch()

  const {
    data: messagesInformations,
    loading,
    error,
  } = useSelector(selectors.getMessagesInformations)

  useEffect(() => {
    dispatch(
      actions.getMessagesInformations({
        sortBy: InformationSortBy.CreatedAt,
        sortOrder: InformationSortOrder.Descending,
      })
    )
  }, []) //eslint-disable-line

  return {
    messagesInformations,
    isLoading: loading === LoadingStatus.Pending,
    error,
  }
}

export const formatDisplayedDate = (fromDate: string, toDate: string) => {
  const dateFormat = 'MM/dd'
  const formattedFromDate = format(parseISO(fromDate), dateFormat)
  const formattedToDate = format(parseISO(toDate), dateFormat)
  return `${formattedFromDate} - ${formattedToDate}`
}
