import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { MODULE_NAME } from '../../../strings'
import { ActualizationOptions } from './RadioSelect.utils'

interface RadioSelectProps {
  actualizationType: string
  onActualizationTypeChange: Dispatch<SetStateAction<string>>
}

const RadioSelect = ({
  actualizationType,
  onActualizationTypeChange,
}: RadioSelectProps) => {
  const { t } = useTranslation(MODULE_NAME)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onActualizationTypeChange((event.target as HTMLInputElement).value)
  }

  return (
    <FormControl>
      <FormLabel>{t('informations.radioLabel')}</FormLabel>
      <RadioGroup row value={actualizationType} onChange={handleChange}>
        <FormControlLabel
          value={ActualizationOptions.Information}
          control={<Radio />}
          label={t('informations.radioInformation')}
        />
        <FormControlLabel
          value={ActualizationOptions.Update}
          control={<Radio />}
          label={t('informations.radioUpdate')}
        />
      </RadioGroup>
    </FormControl>
  )
}

export default RadioSelect
