import { styled, AppBar as BaseAppBar } from '@material-ui/core'
import { OPEN_DRAWER_WIDTH } from '../Drawer'

export const StyledTopbar = styled(BaseAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.default,
}))

export const ToolbarActions = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
})

export const LogoContainer = styled('div')({
  width: OPEN_DRAWER_WIDTH,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const AppLabelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.primary,
  fontWeight: 500,

  '& p': {
    fontSize: '1.4rem',
  },
}))
