import { InformationStatuses } from 'shared/types'
import { AccessibleApplications } from 'shared/types'

export interface PostMessageUpdate {
  accessibleApplications: AccessibleApplications[]
  startDate: string | Date
  title: string
  message: string
  body: string
}

export enum UpdateSortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export enum UpdateSortBy {
  CreatedAt = 'createdAt',
  StartDate = 'startDate',
}

export interface GetMessageUpdatesParams {
  sortOrder: UpdateSortOrder
  sortBy: UpdateSortBy
}

export interface MessageUpd {
  id: number
  status: InformationStatuses
  startDate: string
  message: string
  title: string
  body: string
  accessibleApplications: AccessibleApplications[]
  views: {
    percentage: number
    readCount: number
    totalAccountsCount: number
  }
  feedback: {
    percentage: number
    ratedCount: number
    totalReadCount: number
  }
  feedbackDetails: {
    positive: {
      rate: number
      percentage: number
      ratedCount: number
      totalFeedbackCount: number
    }
    neutral: {
      rate: number
      percentage: number
      ratedCount: number
      totalFeedbackCount: number
    }
    negative: {
      rate: number
      percentage: number
      ratedCount: number
      totalFeedbackCount: number
    }
  }
}

export interface MessageUpdatePutParams {
  id: number
  accessibleApplications: AccessibleApplications[]
  startDate?: string | Date
  title?: string
  message?: string
  body?: string
}

export interface GetMessageUpdatesResponse {
  data: MessageUpd[]
}

export interface GetMessageUpdateResponse {
  data: MessageUpd
}
