import { createReducer } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import resource, { Resource } from 'shared/resource'
import { OrganizationDetail } from 'shared/services/organizations'
import {
  StaffProviderOrganization,
  CareProviderOrganization,
} from 'shared/types/Organizations'
import {
  getOrganizationsDetails,
  addOrganizationScheduling,
  addOrganizationStaffing,
  getOrganizationsByNameUniquenesValidation,
  getOrganizationsByOrganizationIdUniquenesValidation,
  resetGetOrganizationsByNameUniquenesValidation,
  resetGetOrganizationsByOrganizationIdUniquenesValidation,
  getCareProviderOrganization,
  getStaffProviderOrganization,
  assignStaffProvidersToCareProvider,
  unassignStaffProvidersFromCareProvider,
  assignCareProvidersToStaffProvider,
  unassignCareProvidersFromStaffProvider,
} from './actions'

export interface State {
  assignCareProvidersToStaffProvider: Resource<number | null>
  unassignCareProvidersFromStaffProvider: Resource<number | null>
  assignStaffProvidersToCareProvider: Resource<number | null>
  unassignStaffProvidersFromCareProvider: Resource<number | null>
  getCareProviderOrganization: Resource<CareProviderOrganization | null>
  getStaffProviderOrganization: Resource<StaffProviderOrganization | null>
  getOrganizationsDetails: Resource<OrganizationDetail[]>
  addOrganizationScheduling: Resource<number | null>
  addOrganizationStaffing: Resource<number | null>
  getOrganizationsByNameUniquenesValidation: Resource<boolean>
  getOrganizationsByOrganizationIdUniquenesValidation: Resource<boolean>
}

const initialState: State = {
  assignCareProvidersToStaffProvider: resource.getInitial<number | null>(),
  unassignCareProvidersFromStaffProvider: resource.getInitial<number | null>(),
  assignStaffProvidersToCareProvider: resource.getInitial<number | null>(),
  unassignStaffProvidersFromCareProvider: resource.getInitial<number | null>(),
  getCareProviderOrganization:
    resource.getInitial<CareProviderOrganization | null>(),
  getStaffProviderOrganization:
    resource.getInitial<StaffProviderOrganization | null>(),
  getOrganizationsDetails: resource.getInitial<OrganizationDetail[]>([]),
  addOrganizationScheduling: resource.getInitial<number | null>(),
  addOrganizationStaffing: resource.getInitial<number | null>(),
  getOrganizationsByNameUniquenesValidation: resource.getInitial<boolean>(true),
  getOrganizationsByOrganizationIdUniquenesValidation:
    resource.getInitial<boolean>(true),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(assignCareProvidersToStaffProvider.pending, state => {
      resource.setPending(state.assignCareProvidersToStaffProvider)
    })
    .addCase(assignCareProvidersToStaffProvider.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.assignCareProvidersToStaffProvider,
        action.payload
      )
    })
    .addCase(assignCareProvidersToStaffProvider.rejected, (state, action) => {
      resource.setFailed(
        state.assignCareProvidersToStaffProvider,
        action.error.message
      )
    })
    .addCase(unassignCareProvidersFromStaffProvider.pending, state => {
      resource.setPending(state.unassignCareProvidersFromStaffProvider)
    })
    .addCase(
      unassignCareProvidersFromStaffProvider.fulfilled,
      (state, action) => {
        resource.setSucceeded(
          state.unassignCareProvidersFromStaffProvider,
          action.payload
        )
      }
    )
    .addCase(
      unassignCareProvidersFromStaffProvider.rejected,
      (state, action) => {
        resource.setFailed(
          state.unassignCareProvidersFromStaffProvider,
          action.error.message
        )
      }
    )
    .addCase(unassignStaffProvidersFromCareProvider.pending, state => {
      resource.setPending(state.unassignStaffProvidersFromCareProvider)
    })
    .addCase(
      unassignStaffProvidersFromCareProvider.fulfilled,
      (state, action) => {
        resource.setSucceeded(
          state.unassignStaffProvidersFromCareProvider,
          action.payload
        )
      }
    )
    .addCase(
      unassignStaffProvidersFromCareProvider.rejected,
      (state, action) => {
        resource.setFailed(
          state.unassignStaffProvidersFromCareProvider,
          action.error.message
        )
      }
    )
    .addCase(assignStaffProvidersToCareProvider.pending, state => {
      resource.setPending(state.assignStaffProvidersToCareProvider)
    })
    .addCase(assignStaffProvidersToCareProvider.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.assignStaffProvidersToCareProvider,
        action.payload
      )
    })
    .addCase(assignStaffProvidersToCareProvider.rejected, (state, action) => {
      resource.setFailed(
        state.assignStaffProvidersToCareProvider,
        action.error.message
      )
    })
    .addCase(getCareProviderOrganization.pending, state => {
      resource.setPending(state.getCareProviderOrganization)
    })
    .addCase(getCareProviderOrganization.fulfilled, (state, action) => {
      resource.setSucceeded(state.getCareProviderOrganization, action.payload)
    })
    .addCase(getCareProviderOrganization.rejected, (state, action) => {
      resource.setFailed(
        state.getCareProviderOrganization,
        action.error.message
      )
    })
    .addCase(getStaffProviderOrganization.pending, state => {
      resource.setPending(state.getStaffProviderOrganization)
    })
    .addCase(getStaffProviderOrganization.fulfilled, (state, action) => {
      resource.setSucceeded(state.getStaffProviderOrganization, action.payload)
    })
    .addCase(getStaffProviderOrganization.rejected, (state, action) => {
      resource.setFailed(
        state.getStaffProviderOrganization,
        action.error.message
      )
    })
    .addCase(
      getOrganizationsByOrganizationIdUniquenesValidation.pending,
      state => {
        resource.setPending(
          state.getOrganizationsByOrganizationIdUniquenesValidation
        )
      }
    )
    .addCase(
      getOrganizationsByOrganizationIdUniquenesValidation.fulfilled,
      (state, action) => {
        const isUnique = isEmpty(action.payload)
        resource.setSucceeded(
          state.getOrganizationsByOrganizationIdUniquenesValidation,
          isUnique
        )
      }
    )
    .addCase(
      getOrganizationsByOrganizationIdUniquenesValidation.rejected,
      (state, action) => {
        resource.setFailed(
          state.getOrganizationsByOrganizationIdUniquenesValidation,
          action.error.message
        )
      }
    )
    .addCase(
      resetGetOrganizationsByOrganizationIdUniquenesValidation,
      state => {
        resource.reset(
          state.getOrganizationsByOrganizationIdUniquenesValidation,
          true
        )
      }
    )
    .addCase(getOrganizationsByNameUniquenesValidation.pending, state => {
      resource.setPending(state.getOrganizationsByNameUniquenesValidation)
    })
    .addCase(
      getOrganizationsByNameUniquenesValidation.fulfilled,
      (state, action) => {
        const isUnique = isEmpty(action.payload)
        resource.setSucceeded(
          state.getOrganizationsByNameUniquenesValidation,
          isUnique
        )
      }
    )
    .addCase(
      getOrganizationsByNameUniquenesValidation.rejected,
      (state, action) => {
        resource.setFailed(
          state.getOrganizationsByNameUniquenesValidation,
          action.error.message
        )
      }
    )
    .addCase(resetGetOrganizationsByNameUniquenesValidation, state => {
      resource.reset(state.getOrganizationsByNameUniquenesValidation, true)
    })
    .addCase(addOrganizationScheduling.pending, state => {
      resource.setPending(state.addOrganizationScheduling)
    })
    .addCase(addOrganizationScheduling.fulfilled, (state, action) => {
      resource.setSucceeded(state.addOrganizationScheduling, action.payload)
    })
    .addCase(addOrganizationScheduling.rejected, (state, action) => {
      resource.setFailed(state.addOrganizationScheduling, action.error.message)
    })
    .addCase(getOrganizationsDetails.pending, state => {
      resource.setPending(state.getOrganizationsDetails)
    })
    .addCase(getOrganizationsDetails.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getOrganizationsDetails,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getOrganizationsDetails.rejected, (state, action) => {
      resource.setFailed(state.getOrganizationsDetails, action.error.message)
    })
    .addCase(addOrganizationStaffing.pending, state => {
      resource.setPending(state.addOrganizationStaffing)
    })
    .addCase(addOrganizationStaffing.fulfilled, (state, action) => {
      resource.setSucceeded(state.addOrganizationStaffing, action.payload)
    })
    .addCase(addOrganizationStaffing.rejected, (state, action) => {
      resource.setFailed(state.addOrganizationStaffing, action.error.message)
    })
)
