import Api from '../api'
import {
  GetCpOrganizationGroupsParams,
  GetCpOrganizationGroupsResponse,
  PostCpOrganizationGroup,
  CpOrganizationGroup,
  PutCpOrganizationGroup,
  GetCpOrganizationGroupParams,
  GetCpOrganizationGroupResponse,
  GetCpOrganizationGroupAdminsParams,
  GetCpOrganizationGroupAdminsResponse,
  GetCpOrganizationGroupsSuggestionsParams,
  GetCpOrganizationGroupsSuggestionsResponse,
} from './OrganizationGroups.types'

class CpOrganizationGroups extends Api {
  public getCpOrganizationGroups = async (
    params?: GetCpOrganizationGroupsParams
  ) => {
    const { data } = await this.api.get<GetCpOrganizationGroupsResponse>(
      '/admin/care-providers/organization-groups/details',
      { params }
    )
    return data
  }
  public addCpOrganizationGroup = async ({
    ...payload
  }: PostCpOrganizationGroup) => {
    const { data } = await this.api.post<number>(
      '/admin/care-providers/organization-groups',
      payload
    )
    return data
  }

  public getCpOrganizationGroup = async ({
    groupId,
  }: GetCpOrganizationGroupParams) => {
    const { data } = await this.api.get<GetCpOrganizationGroupResponse>(
      `/admin/care-providers/organization-groups/${groupId}`
    )
    return data
  }

  public getCpOrganizationGroupAdmins = async ({
    organizationGroupId,
    ...params
  }: GetCpOrganizationGroupAdminsParams) => {
    const { data } = await this.api.get<GetCpOrganizationGroupAdminsResponse>(
      `/admin/care-providers/organization-groups/${organizationGroupId}/admins`,
      { params }
    )
    const result = data.admins
    return { data: result }
  }

  public putCpOrganizationGroup = async ({
    id,
    ...payload
  }: PutCpOrganizationGroup) => {
    const { data } = await this.api.put<CpOrganizationGroup>(
      `/admin/care-providers/organization-groups/${id}`,
      payload
    )
    return data
  }

  public getCpOrganizationGroupsSuggestions = async (
    params?: GetCpOrganizationGroupsSuggestionsParams
  ) => {
    const { data } =
      await this.api.get<GetCpOrganizationGroupsSuggestionsResponse>(
        '/admin/suggestions/care-providers/organization-groups',
        { params }
      )
    return data.data
  }
}

export default CpOrganizationGroups
