import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AccountStatusChip } from './AccountStatusChips.styles'

interface AccountStatusChipsProps {
  isActive: boolean
  isBlocked: boolean
  showBlockedInfo?: boolean
}

const AccountStatusChips = ({
  isBlocked,
  isActive,
  showBlockedInfo = false,
}: AccountStatusChipsProps) => {
  const { t } = useTranslation()

  return (
    <>
      {isBlocked && (
        <>
          <Grid item>
            <AccountStatusChip
              label={t('accountStatus.blocked')}
              size="small"
              blocked
            />
          </Grid>
          {showBlockedInfo && (
            <Grid item>
              <Typography variant="caption">
                <i>{t('accountStatus.blockedInfo')}</i>
              </Typography>
            </Grid>
          )}
        </>
      )}
      {!isActive && !isBlocked && (
        <Grid item>
          <AccountStatusChip label={t('accountStatus.inactive')} size="small" />
        </Grid>
      )}
    </>
  )
}

export default AccountStatusChips
