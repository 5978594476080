export enum Language {
  Swedish = 'sv',
  Norwegian = 'no',
  English = 'en',
}

export enum MessageRecipientStatus {
  All = 'ALL',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum ToggleActivation {
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export enum SystemPages {
  Organizations = 0,
  OrganizationGroups = 1,
  RolesAndSpecializations = 2,
  Accounts = 3,
  Consultants = 4,
}

export enum MessageDeliveryType {
  Sms = 'SMS',
  Email = 'EMAIL',
}

export enum AvailableConsultantsActions {
  Delete = 'delete',
}

export enum AvailableAccountsActions {
  Activate = 'activate',
  Deactivate = 'deactivate',
  Anonymize = 'anonymize',
  Delete = 'delete',
}

export enum SystemRoler {
  SystemAdmin = 'SYSTEM_ADMIN',
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  UnitAdmin = 'UNIT_ADMIN',
  Resource = 'Resource',
}

export enum OrganizationType {
  StaffProvider = 'STAFF_PROVIDER',
  CareProvider = 'CARE_PROVIDER',
}

export enum AccountStatuses {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Inactive = 'INACTIVE',
}

export enum AccountType {
  User = 'SYSTEM_USER',
  Resource = 'RESOURCE',
}

export enum SystemRole {
  All = 'ALL',
  SystemAdmin = 'SYSTEM_ADMIN',
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  UnitAdmin = 'UNIT_ADMIN',
  Resource = 'RESOURCE',
  Admin = 'ADMIN',
}

export enum AccountRole {
  SuperCareProvider = 'SYSTEM_ADMIN',
  CareStaffProvider = 'ORGANIZATION_ADMIN',
  UnitAdmin = 'UNIT_ADMIN',
  Resource = 'RESOURCE',
}

export enum AdminRoles {
  Dev = 'DEV',
  Edu = 'EDU',
}

export enum AccountTypes {
  SystemAdmin = 'SYSTEM_ADMIN',
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  UnitAdmin = 'UNIT_ADMIN',
  Resource = 'RESOURCE',
}

export enum AdminStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum SystemOrganizationTypes {
  All = '',
  Scheduling = 'CARE_PROVIDER',
  Staffing = 'STAFF_PROVIDER',
}

export enum SystemModulesTypes {
  All = '',
  ViviumI = 'VIVIUM_I',
  ViviumX = 'VIVIUM_X',
}

export enum CareProviderResourceDocumentType {
  CV = 'CV',
  Reference = 'REFERENCE',
  HOSP = 'HOSP',
  IVO = 'IVO',
  QualifyingPeriod = 'QUALIFYING_PERIOD',
  Other = 'OTHER',
}

export enum ModulePillTypes {
  ViviumI = 'VIVIUM_I',
  ViviumX = 'VIVIUM_X',
}

export enum OrganizationTypePill {
  Scheduling = 'CARE_PROVIDER',
  Staffing = 'STAFF_PROVIDER',
}

export enum SystemOrganizationPillTypes {
  Scheduling = 'CARE_PROVIDER',
  Staffing = 'STAFF_PROVIDER',
  ViviumI = 'VIVIUM_I',
  ViviumX = 'VIVIUM_X',
}

export enum OrganizationTypes {
  All = '',
  CareProvider = 'CARE_PROVIDER',
  StaffProvider = 'STAFF_PROVIDER',
}

export enum InformationStatuses {
  Upcoming = 'UPCOMING',
  Published = 'PUBLISHED',
  Expired = 'EXPIRED',
}

export enum FormType {
  Update = 'update',
  Post = 'post',
}

export enum ModalTypes {
  Create = 'CREATE',
  Update = 'UPDATE',
  Preview = 'PREVIEW',
  Delete = 'DELETE',
}

export enum Messages {
  Information = 'information',
  Update = 'update',
}

export enum AuthRoles {
  All = 'ALL',
  SuperCareProvider = 'SUPER_CARE_PROVIDER',
  CareProvider = 'CARE_PROVIDER',
  SuperStaffProvider = 'SUPER_STAFF_PROVIDER',
  StaffProvider = 'STAFF_PROVIDER',
  UnitAdmin = 'UNIT_ADMIN',
  CareProviderResource = 'CARE_PROVIDER_RESOURCE',
  Admin = 'ADMIN',
}

export enum AccessibleApplications {
  All = 'ALL',
  ViviumI = 'VIVIUM_I',
  ViviumX = 'VIVIUM_X',
}

export enum Country {
  Sweden = 'Sweden',
  Norway = 'Norway',
}
