import { RequestHandler, rest } from 'msw'
import { apiUrl, search, sort } from '../server.utils'

const handlers: RequestHandler[] = [
  rest.post(
    apiUrl('/mock/staff-providers/:staffProviderId/resources'),
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          name: 'test cp resource',
          socialSecurityNumber: '1248995-13',
          legitimizationYear: 0,
          contractExpiresAt: null,
          monthlyWorkingHours: 15,
          medicalRecordSystem: '',
          prescriberCode: '',
          HSAID: '12354123213',
          hasSITHSCard: false,
          contactAddress: '',
          contactPostalCode: '',
          contactCity: '',
          contactPhoneNumber: '',
          contactEmail: 'testemail@email.com',
          contactDescription: '',
          preferredWorkTimes: ['MORNING', 'AFTERNOON', 'NIGHT', 'WEEKEND'],
          tagIds: [187],
          roleId: 5,
          specialtyIds: [],
          competenceIds: [],
          hideIdentity: false,
          documentIds: [],
          employmentType: 'FULL_TIME',
        })
      )
  ),
  rest.get(
    apiUrl('/mock/admin/staff-providers/resources/details'),
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          take: 10,
          count: 30,
          skip: 0,
          data: search(
            sort(
              [
                {
                  id: 180,
                  name: 'sp resource 1',
                  role: 'Läkare',
                  lastLoggedInDate: '2020-06-02T10:18:52.947Z',
                  organization: {
                    id: 1,
                    name: 'Staffers',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
                {
                  id: 185,
                  name: 'sp resource 2',
                  role: 'Sjuksköterska',
                  lastLoggedInDate: '2022-12-02T10:18:52.947Z',
                  organization: {
                    id: 2,
                    name: 'Staff Agency AB',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
                {
                  id: 48,
                  name: 'sp resource 3',
                  role: 'Undersköterska',
                  lastLoggedInDate: '2022-08-02T10:18:52.947Z',
                  organization: {
                    id: 3,
                    name: 'Medic AB',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
                {
                  id: 5,
                  name: 'sp resource 4',
                  role: 'Admin',
                  lastLoggedInDate: '2022-09-02T10:18:52.947Z',
                  organization: {
                    id: 4,
                    name: 'CP1',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
                {
                  id: 50,
                  name: 'sp resource 5',
                  role: 'Undersköterska',
                  lastLoggedInDate: '2022-07-02T10:18:52.947Z',
                  organization: {
                    id: 5,
                    name: 'CP2',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
                {
                  id: 52,
                  name: 'sp resource 6',
                  role: 'Admin',
                  lastLoggedInDate: '2022-09-02T10:18:52.947Z',
                  organization: {
                    id: 6,
                    name: 'Medic AB',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
                {
                  id: 82,
                  name: 'sp resource 7',
                  role: 'Läkare',
                  lastLoggedInDate: '2022-10-02T10:18:52.947Z',
                  organization: {
                    id: 7,
                    name: 'Medic AB',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
                {
                  id: 83,
                  name: 'sp resource 8',
                  role: 'Läkare',
                  lastLoggedInDate: '2022-11-02T10:18:52.947Z',
                  organization: {
                    id: 8,
                    name: 'Sjuksköterska',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
                {
                  id: 184,
                  name: 'sp resource 9',
                  role: 'Sjuksköterska',
                  lastLoggedInDate: '2022-12-12T10:18:52.947Z',
                  organization: {
                    id: 9,
                    name: 'CP6',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
                {
                  id: 179,
                  name: 'sp resource 10',
                  role: 'Undersköterska',
                  lastLoggedInDate: '2022-12-13T10:18:52.947Z',
                  organization: {
                    id: 10,
                    name: 'CP7',
                    organizationType: 'CARE_PROVIDER',
                  },
                },
              ],
              req.url.search
            ),
            {
              search: req.url.searchParams.get('search'),
              searchBy: req.url.searchParams.get('searchBy'),
            }
          ),
        })
      )
  ),
  rest.delete(
    apiUrl('/mock/admin/staff-providers/resources'),
    (req, res, ctx) => res(ctx.status(200), ctx.text('456'))
  ),
]

export default handlers
