import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormProps,
  AutocompleteValue,
} from '@vivium/frontend-common/components'
import { LoadingStatus } from '@vivium/frontend-common/types'
import { format, parseISO } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { SchemaOf, object, string } from 'yup'
import {
  InformationSortOrder,
  InformationSortBy,
  MessageInfo,
  MessageInfoUpdateParams,
} from 'shared/services/messageInformation'
import { AccessibleApplications } from 'shared/types'
import {
  useInformationAutocompleteOptions,
  InformationAutocompleteOption,
  dateAndTimeToIso,
  convertOptionToAccessibleApplicationArray,
} from '../../pages'
import { selectors, actions } from '../../store'

export enum InformationFields {
  AccessibleApplications = 'accessibleApplications',
  DateFrom = 'dateFrom',
  TimeFrom = 'timeFrom',
  DateTo = 'dateTo',
  TimeTo = 'timeTo',
  Message = 'message',
}

export const MAX_MESSAGE_LENGTH = 150

export interface InformationValues {
  [InformationFields.AccessibleApplications]: AutocompleteValue
  [InformationFields.DateFrom]: string
  [InformationFields.TimeFrom]: string
  [InformationFields.DateTo]: string
  [InformationFields.TimeTo]: string
  [InformationFields.Message]: string
}

export const useGetMessageInformation = (id: number) => {
  const dispatch = useDispatch()

  const {
    data: messageInformation,
    loading,
    error,
  } = useSelector(selectors.getMessageInformation)

  useEffect(() => {
    dispatch(actions.getMessageInformation(id))
  }, []) //eslint-disable-line

  return {
    messageInformation,
    isLoading: loading === LoadingStatus.Pending,
    error,
  }
}

export const useGetInformationDefaultValues = (): InformationValues => {
  const { defaultAccessibleApplicationOptions } =
    useInformationAutocompleteOptions()
  return {
    [InformationFields.AccessibleApplications]:
      defaultAccessibleApplicationOptions,
    [InformationFields.DateFrom]: '',
    [InformationFields.TimeFrom]: '',
    [InformationFields.DateTo]: '',
    [InformationFields.TimeTo]: '',
    [InformationFields.Message]: '',
  }
}

export const useInformationSchema = (): SchemaOf<InformationValues> => {
  const { t } = useTranslation()
  return object()
    .shape({
      [InformationFields.DateFrom]: string().required(t('validation.required')),
      [InformationFields.DateTo]: string().required(t('validation.required')),
      [InformationFields.TimeFrom]: string().required(t('validation.required')),
      [InformationFields.TimeTo]: string().required(t('validation.required')),
      [InformationFields.Message]: string()
        .required(t('validation.required'))
        .max(
          MAX_MESSAGE_LENGTH,
          t('validation.characterLimit', {
            characterLimit: MAX_MESSAGE_LENGTH,
          })
        ),
    })
    .required()
}

const useValidationSchema = (): SchemaOf<InformationValues> => {
  const informationSchema = useInformationSchema()
  return object().shape({
    ...informationSchema.fields,
  })
}

const useGetDefaultValues = (): InformationValues => {
  const informationDefaultValues = useGetInformationDefaultValues()

  return {
    ...informationDefaultValues,
  }
}

export const useOnSubmit = (id: number, onCloseModal: () => void) => {
  const { loading } = useSelector(selectors.updateMessageInformation)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onError = (message?: string) =>
    enqueueSnackbar(message || t('error.defaultMessage'), {
      variant: 'error',
    })

  const onSuccess = (message?: string) => {
    dispatch(
      actions.getMessagesInformations({
        sortBy: InformationSortBy.CreatedAt,
        sortOrder: InformationSortOrder.Descending,
      })
    )
    onCloseModal()
    enqueueSnackbar(message || t('messages.modifyInformationSuccess'), {
      variant: 'success',
    })
  }

  const isSubmitting = loading === LoadingStatus.Pending

  const handleSubmit = (values: InformationValues) => {
    const startDate = dateAndTimeToIso(values.dateFrom, values.timeFrom)
    const endDate = dateAndTimeToIso(values.dateTo, values.timeTo)

    const payload: MessageInfoUpdateParams = {
      id,
      startDate,
      endDate,
      message: values.message,
      accessibleApplications: convertOptionToAccessibleApplicationArray(
        values[InformationFields.AccessibleApplications]?.value
      ),
    }
    return dispatch(
      actions.updateMessageInformation({ onError, onSuccess, payload })
    )
  }

  return { isSubmitting, handleSubmit }
}

export const useConvertFetchedDataToFormValues = (
  messageInformation: MessageInfo | null
) => {
  const { accessibleApplicationsOptions, defaultAccessibleApplicationOptions } =
    useInformationAutocompleteOptions()
  if (!messageInformation) return undefined
  const { message, accessibleApplications, endDate, startDate } =
    messageInformation
  const timeFormat = 'HH.mm'
  const formattedTimeTo = format(parseISO(endDate), timeFormat)
  const formattedTimeFrom = format(parseISO(startDate), timeFormat)

  const findOptionValueCb =
    (val: AccessibleApplications) => (option: InformationAutocompleteOption) =>
      option.value == val

  const autocompleteAccessibleApplicationsOptions = accessibleApplications.map(
    accessibleApplication =>
      accessibleApplicationsOptions.find(
        findOptionValueCb(accessibleApplication)
      )
  )

  return {
    [InformationFields.AccessibleApplications]:
      autocompleteAccessibleApplicationsOptions.length > 1
        ? defaultAccessibleApplicationOptions
        : autocompleteAccessibleApplicationsOptions[0],
    [InformationFields.DateFrom]: startDate || '',
    [InformationFields.TimeFrom]: formattedTimeFrom || '',
    [InformationFields.DateTo]: endDate || '',
    [InformationFields.TimeTo]: formattedTimeTo || '',
    [InformationFields.Message]: message || '',
  }
}

export const useFormProps = (): Omit<
  FormProps<InformationValues>,
  'onSubmit'
> => {
  const schema = useValidationSchema()
  const defaultValues = useGetDefaultValues()
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  return methods
}
