import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { StylesProvider } from '@material-ui/styles'
import { Loader } from '@vivium/frontend-common/components'
import { generateClassName } from '@vivium/frontend-common/utils'
import 'date-fns'
import { SnackbarProvider } from 'notistack'
import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import config, { theme } from 'config'
import { Layout } from 'shared/components'
import { PickersProvider, Auth, routes } from './App.setup'
import { store, persistor } from './App.store'

const [directRoutes, modalRoutes] = routes
const notificationsElement = document.getElementById('notifications')

const App = () => (
  <StylesProvider generateClassName={generateClassName}>
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={config.maxSnackbarsCount}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        {...(notificationsElement && { domRoot: notificationsElement })}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<Loader />}>
            <PickersProvider>
              <PersistGate loading={<Loader />} persistor={persistor}>
                <Auth />
                <BrowserRouter>
                  <Layout routes={directRoutes} modalRoutes={modalRoutes} />
                </BrowserRouter>
              </PersistGate>
            </PickersProvider>
          </Suspense>
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  </StylesProvider>
)

export default App
