import { createReducer } from '@reduxjs/toolkit'
import { Faq } from 'shared/types/Faqs'
import resource, { Resource } from '../../../shared/resource'
import { addFaq, getFaq, getFaqs, updateFaq, deleteFaq } from './actions'

interface State {
  addFaq: Resource<number>
  getFaqs: Resource<Faq[]>
  getFaq: Resource<Faq | null>
  updateFaq: Resource<number>
  deleteFaq: Resource<number>
}

const initialState: State = {
  addFaq: resource.getInitial<number>(NaN),
  getFaqs: resource.getInitial<Faq[]>([]),
  getFaq: resource.getInitial<Faq | null>(),
  updateFaq: resource.getInitial<number>(NaN),
  deleteFaq: resource.getInitial<number>(NaN),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(deleteFaq.pending, state => {
      resource.setPending(state.deleteFaq)
    })
    .addCase(deleteFaq.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteFaq, action.payload)
    })
    .addCase(deleteFaq.rejected, (state, action) => {
      resource.setFailed(state.deleteFaq, action.error.message)
    })
    .addCase(addFaq.pending, state => {
      resource.setPending(state.addFaq)
    })
    .addCase(addFaq.fulfilled, (state, action) => {
      resource.setSucceeded(state.addFaq, action.payload)
    })
    .addCase(addFaq.rejected, (state, action) => {
      resource.setFailed(state.addFaq, action.error.message)
    })
    .addCase(getFaqs.pending, state => {
      resource.setPending(state.getFaqs)
    })
    .addCase(getFaqs.fulfilled, (state, action) => {
      resource.setSucceeded(state.getFaqs, action.payload.data)
    })
    .addCase(getFaqs.rejected, (state, action) => {
      resource.setFailed(state.getFaqs, action.error.message)
    })
    .addCase(getFaq.pending, state => {
      resource.setPending(state.getFaq)
    })
    .addCase(getFaq.fulfilled, (state, action) => {
      resource.setSucceeded(state.getFaq, action.payload)
    })
    .addCase(getFaq.rejected, (state, action) => {
      resource.setFailed(state.getFaq, action.error.message)
    })
    .addCase(updateFaq.pending, state => {
      resource.setPending(state.updateFaq)
    })
    .addCase(updateFaq.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateFaq, action.payload)
    })
    .addCase(updateFaq.rejected, (state, action) => {
      resource.setFailed(state.updateFaq, action.error.message)
    })
)
