import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import {
  MessageInformation,
  GetMessagesInformationParams,
} from 'shared/services/messageInformation'
import {
  MessageUpdate,
  GetMessageUpdatesParams,
} from 'shared/services/messageUpdate'
import { MODULE_NAME } from '../strings'
import {
  PostMessageInformationPayload,
  PostMessageUpdatePayload,
  UpdateMessageInformationPayload,
  PutMessageUpdatePayload,
  DeleteMessagePayload,
} from './action.types'

const messageInformation = new MessageInformation()
const messageUpdate = new MessageUpdate()

export const putMessageUpdate = createAsyncThunk(
  `${MODULE_NAME}/putMessageUpdate`,
  async (
    { payload, onSuccess, onError }: PutMessageUpdatePayload,
    { rejectWithValue }
  ) => {
    try {
      const data = await messageUpdate.putMessageUpdate(payload)
      if (onSuccess) onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      if (onError) onError(error?.response?.data?.message)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const addMessageUpdate = createAsyncThunk(
  `${MODULE_NAME}/addMessageUpdate`,
  async (
    { payload, onSuccess, onError }: PostMessageUpdatePayload,
    { rejectWithValue }
  ) => {
    try {
      const data = await messageUpdate.addMessageUpdate(payload)
      if (onSuccess) onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      if (onError) onError(error?.response?.data?.message)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getMessagesUpdates = createAsyncThunk(
  `${MODULE_NAME}/getMessagesUpdates`,
  (payload?: GetMessageUpdatesParams) =>
    messageUpdate.getMessagesUpdates(payload)
)

export const getMessageUpdate = createAsyncThunk(
  `${MODULE_NAME}/getMessageUpdate`,
  (payload: number) => messageUpdate.getMessageUpdate(payload)
)

export const deleteMessageUpdate = createAsyncThunk(
  `${MODULE_NAME}/deleteMessageUpdate`,
  async (
    { id, onSuccess, onError }: DeleteMessagePayload,
    { rejectWithValue }
  ) => {
    try {
      const data = await messageUpdate.deleteMessageUpdate(id)
      if (onSuccess) onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      if (onError) onError(error?.response?.data?.message)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const addMessageInformation = createAsyncThunk(
  `${MODULE_NAME}/addMessageInformation`,
  async (
    { payload, onSuccess, onError }: PostMessageInformationPayload,
    { rejectWithValue }
  ) => {
    try {
      const data = await messageInformation.addMessageInformation(payload)
      if (onSuccess) onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      if (onError) onError(error?.response?.data?.message)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const updateMessageInformation = createAsyncThunk(
  `${MODULE_NAME}/updateMessageInformation`,
  async (
    { payload, onSuccess, onError }: UpdateMessageInformationPayload,
    { rejectWithValue }
  ) => {
    try {
      const data = await messageInformation.updateMessageInformation(payload)
      if (onSuccess) onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      if (onError) onError(error?.response?.data?.message)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getMessagesInformations = createAsyncThunk(
  `${MODULE_NAME}/getMessagesInformations`,
  (payload?: GetMessagesInformationParams) =>
    messageInformation.getMessagesInformations(payload)
)

export const getMessageInformation = createAsyncThunk(
  `${MODULE_NAME}/getMessageInformation`,
  (payload: number) => messageInformation.getMessageInformation(payload)
)

export const deleteMessageInformation = createAsyncThunk(
  `${MODULE_NAME}/deleteMessageInformation`,
  async (
    { id, onSuccess, onError }: DeleteMessagePayload,
    { rejectWithValue }
  ) => {
    try {
      const data = await messageInformation.deleteMessageInformation(id)
      if (onSuccess) onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      if (onError) onError(error?.response?.data?.message)
      return rejectWithValue(error?.response?.data)
    }
  }
)
