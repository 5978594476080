import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormProps,
  AutocompleteValue,
} from '@vivium/frontend-common/components'
import { LoadingStatus } from '@vivium/frontend-common/types'
import { format, parseISO } from 'date-fns'
import { ContentState, convertToRaw, RawDraftContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { SchemaOf, object, string } from 'yup'
import { UpdateSortBy, UpdateSortOrder } from 'shared/services/messageUpdate'
import {
  MessageUpdatePutParams,
  MessageUpd,
} from 'shared/services/messageUpdate'
import { AccessibleApplications } from 'shared/types'
import {
  convertOptionToAccessibleApplicationArray,
  InformationAutocompleteOption,
  useInformationAutocompleteOptions,
  dateAndTimeToIso,
} from '../../pages'
import { selectors, actions } from '../../store'

export enum UpdateFields {
  AccessibleApplications = 'accessibleApplications',
  PublicationDate = 'publicationDate',
  PublicationTime = 'publicationTime',
  UpdateMessage = 'updateMessage',
  Title = 'title',
  MessageBody = 'messageBody',
}

export const MAX_MESSAGE_LENGTH = 150
export const MAX_TITLE_LENGTH = 40

export interface UpdateValues {
  [UpdateFields.AccessibleApplications]: AutocompleteValue
  [UpdateFields.PublicationDate]: string
  [UpdateFields.PublicationTime]: string
  [UpdateFields.UpdateMessage]: string
  [UpdateFields.Title]: string
  [UpdateFields.MessageBody]: RawDraftContentState | string
}

export const useGetUpdateDefaultValues = (): UpdateValues => {
  const { defaultAccessibleApplicationOptions } =
    useInformationAutocompleteOptions()
  return {
    [UpdateFields.AccessibleApplications]: defaultAccessibleApplicationOptions,
    [UpdateFields.PublicationDate]: '',
    [UpdateFields.PublicationTime]: '',
    [UpdateFields.UpdateMessage]: '',
    [UpdateFields.Title]: '',
    [UpdateFields.MessageBody]: '',
  }
}

export const useUpdateSchema = (): SchemaOf<UpdateValues> => {
  const { t } = useTranslation()
  return object()
    .shape({
      [UpdateFields.PublicationDate]: string(),
      [UpdateFields.PublicationTime]: string(),
      [UpdateFields.UpdateMessage]: string().max(
        MAX_MESSAGE_LENGTH,
        t('validation.characterLimit', {
          characterLimit: MAX_MESSAGE_LENGTH,
        })
      ),
      [UpdateFields.MessageBody]: object(),
      [UpdateFields.Title]: string().max(
        MAX_TITLE_LENGTH,
        t('validation.characterLimit', {
          characterLimit: MAX_TITLE_LENGTH,
        })
      ),
      [UpdateFields.Title]: string(),
    })
    .required()
}

const useValidationSchema = (): SchemaOf<UpdateValues> => {
  const updateSchema = useUpdateSchema()

  return object().shape({
    ...updateSchema.fields,
  })
}

const useGetDefaultValues = (): UpdateValues => {
  const updateDefaultValues = useGetUpdateDefaultValues()
  return {
    ...updateDefaultValues,
  }
}

export const useOnSubmit = (id: number, onCloseModal: () => void) => {
  const { loading } = useSelector(selectors.putMessageUpdate)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isSubmitting = loading === LoadingStatus.Pending

  const handleSubmit = useCallback(
    (values: UpdateValues) => {
      const startDate = dateAndTimeToIso(
        values.publicationDate,
        values.publicationTime
      )
      const payload: MessageUpdatePutParams = {
        id,
        startDate,
        accessibleApplications: convertOptionToAccessibleApplicationArray(
          values[UpdateFields.AccessibleApplications]?.value
        ),
        title: values.title,
        body: values.messageBody
          ? draftToHtml(values.messageBody as RawDraftContentState)
          : '',
        message: values.updateMessage,
      }
      const onError = (message?: string) =>
        enqueueSnackbar(message || t('error.defaultMessage'), {
          variant: 'error',
        })

      const onSuccess = (message?: string) => {
        dispatch(
          actions.getMessagesUpdates({
            sortBy: UpdateSortBy.CreatedAt,
            sortOrder: UpdateSortOrder.Descending,
          })
        )
        onCloseModal()
        return enqueueSnackbar(message || t('messages.modifyUpdateSuccess'), {
          variant: 'success',
        })
      }

      return dispatch(actions.putMessageUpdate({ onError, onSuccess, payload }))
    },
    [dispatch, id, enqueueSnackbar, t, onCloseModal]
  )

  return { isSubmitting, handleSubmit }
}

export const useFormProps = (): Omit<FormProps<UpdateValues>, 'onSubmit'> => {
  const schema = useValidationSchema()
  const defaultValues = useGetDefaultValues()
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  return methods
}

export const useConvertFetchedDataToFormValues = (
  messageUpdate: MessageUpd | null
) => {
  const { accessibleApplicationsOptions, defaultAccessibleApplicationOptions } =
    useInformationAutocompleteOptions()
  if (!messageUpdate) return undefined
  const { message, accessibleApplications, body, startDate, title } =
    messageUpdate
  const timeFormat = 'HH.mm'
  const formattedPublicationTime = format(parseISO(startDate), timeFormat)
  const contentBlock = htmlToDraft(body ? body : '')
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  )
  const rawMessageBody = convertToRaw(contentState)

  const findOptionValueCb =
    (val: AccessibleApplications) => (option: InformationAutocompleteOption) =>
      option.value == val

  const autocompleteAccessibleApplicationsOptions = accessibleApplications.map(
    accessibleApplication =>
      accessibleApplicationsOptions.find(
        findOptionValueCb(accessibleApplication)
      )
  )

  return {
    [UpdateFields.AccessibleApplications]:
      autocompleteAccessibleApplicationsOptions.length > 1
        ? defaultAccessibleApplicationOptions
        : autocompleteAccessibleApplicationsOptions[0],
    [UpdateFields.PublicationDate]: startDate,
    [UpdateFields.PublicationTime]: formattedPublicationTime,
    [UpdateFields.UpdateMessage]: message,
    [UpdateFields.Title]: title,
    [UpdateFields.MessageBody]: rawMessageBody,
  }
}
