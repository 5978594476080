import Api from '../api'
import {
  GetSystemRolesResponse,
  PostSystemRole,
  GetSystemRolesWithSpecialitiesResponse,
  UpdateRoleParams,
  GetSystemRolesParams,
  AssignSpecialtiesToSystemRoleParams,
  UnassignSpecialtyFromSystemRoleParams,
  GetRolesSuggestionsParams,
  GetRolesSuggestionsResponse,
  GetSystemRolesWithSpecialitiesParams,
} from './SystemRoles.types'

class SystemRoles extends Api {
  public getRolesSuggestions = async (params: GetRolesSuggestionsParams) => {
    const { data } = await this.api.get<GetRolesSuggestionsResponse>(
      '/suggestions/roles',
      { params }
    )
    return data.data
  }
  public getSystemRolesWithSpecialties = async (
    params: GetSystemRolesWithSpecialitiesParams
  ) => {
    const { data } = await this.api.get<GetSystemRolesWithSpecialitiesResponse>(
      '/admin/roles/specialties',
      { params }
    )
    return data
  }
  public getSystemRoles = async () => {
    const { data } = await this.api.get<GetSystemRolesResponse>('/admin/roles')

    return data
  }

  public getSystemRolesData = async (params: GetSystemRolesParams) => {
    const { data } = await this.api.get<GetSystemRolesResponse>(
      '/admin/roles',
      { params }
    )

    return data.data
  }

  public addSystemRole = async ({ ...payload }: PostSystemRole) => {
    const { data } = await this.api.post<number>('/admin/roles', payload)
    return data
  }

  public deleteSystemRole = async (id: number) => {
    const { data } = await this.api.delete<number>(`/admin/roles/${id}`)
    return data
  }

  public updateSystemRole = async ({ id, ...payload }: UpdateRoleParams) => {
    const { data } = await this.api.put<number>(`/admin/roles/${id}`, payload)
    return data
  }

  public assignSpecialtiesToSystemRole = async ({
    roleId,
    ...payload
  }: AssignSpecialtiesToSystemRoleParams) => {
    const { data } = await this.api.post<number>(
      `/admin/roles/${roleId}/specialties/assign`,
      payload
    )
    return data
  }

  public unassignSpecialtyFromSystemRole = async ({
    roleId,
    specialtyId,
  }: UnassignSpecialtyFromSystemRoleParams) => {
    const { data } = await this.api.post<number>(
      `/admin/roles/${roleId}/specialties/unassign/${specialtyId}`
    )
    return data
  }
}

export default SystemRoles
