import { Typography } from '@material-ui/core'
import { ModulePillTypes } from 'shared/types'
import { ModulePillWrapper } from './ModulePill.styles'

interface ModulePillProps {
  label: string
  pillType: ModulePillTypes
}

const ModulePill = ({ label, pillType }: ModulePillProps) => {
  return (
    <ModulePillWrapper pillType={pillType}>
      <Typography>{label}</Typography>
    </ModulePillWrapper>
  )
}

export default ModulePill
