import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'
import { CareProviderResourceDocumentType } from 'shared/types'

export const getDeleteAccount = (state: RootState) =>
  state.system.accounts.deleteAccount

export const addAccount = (state: RootState) => state.system.accounts.addAccount

export const getIsAccountActive = (state: RootState) =>
  state.system.accounts.getAccount.data?.isActive

export const getAccount = (state: RootState) => state.system.accounts.getAccount

export const updateAccount = (state: RootState) =>
  state.system.accounts.updateAccount

export const getAccountsDetailsCount = (state: RootState) =>
  state.system.accounts.getAccountsDetails.meta?.count

export const getAccountsDetails = (state: RootState) =>
  state.system.accounts.getAccountsDetails

export const deactivateAccount = (state: RootState) =>
  state.system.accounts.deactivateAccount

export const activateAccount = (state: RootState) =>
  state.system.accounts.activateAccount

export const getAllAccountsDetails = (state: RootState) =>
  state.system.accounts.getAllAccountsDetails

// CARE PROVIDER RESOURCE
export const getIsResourceActive = (state: RootState) =>
  state.system.accounts.getResourceDetails.data?.isActive

export const getUpdateResource = (state: RootState) =>
  state.system.accounts.updateCareProviderResource

export const getResourceDetailsResource = (state: RootState) =>
  state.system.accounts.getResourceDetails

export const getCreateCareProviderResource = (state: RootState) =>
  state.system.accounts.createCareProviderResource

export const getResourcePhoneNumber = (state: RootState) =>
  state.system.accounts.resourcePhoneNumber

export const getResourceDocuments = (state: RootState) =>
  state.system.accounts.resourceDocuments

export const getResourceDocumentsCount = createSelector(
  getResourceDocuments,
  documents =>
    documents.filter(
      document => document.type !== CareProviderResourceDocumentType.Other
    ).length
)

export const getTags = (state: RootState) => state.system.accounts.getTags

export const getCreateTag = (state: RootState) =>
  state.system.accounts.createTag

export const getCreateTagLoading = (state: RootState) =>
  state.system.accounts.createTag.loading

export const deleteResourceDocumentLoading = (state: RootState) =>
  state.system.accounts.deleteResourceDocument.loading

export const getUploadResourceDocumentLoading = (state: RootState) =>
  state.system.accounts.uploadResourceDocument.loading
