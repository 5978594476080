import { createAction } from '@reduxjs/toolkit'
import { createAsyncThunkWithErrorHandling } from '@vivium/frontend-common/utils'
import { Accounts } from '../../services/accounts'
import Api from '../../services/api'
import { NAMESPACE } from '../../strings'
import { AuthChallenge } from '../../types'
import {
  SignInPayload,
  SetNewPasswordPayload,
  ConfirmForgotPasswordPayload,
  ResolveChallengePayload,
  ChallengeData,
  ChallengeHandlers,
} from './actions.types'

const api = new Api()
const accounts = new Accounts()

const handleChallenge = (
  { challenge, token }: ChallengeData,
  {
    onNewPasswordRequired = () => {},
    onMFARequired = () => {},
  }: ChallengeHandlers
) => {
  switch (challenge) {
    case AuthChallenge.NewPasswordRequired: {
      onNewPasswordRequired(token)
      return null
    }
    case AuthChallenge.MFARequired: {
      onMFARequired(token)
      return null
    }
    default:
      return accounts.getMe()
  }
}

export const getCurrentUser = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/getCurrentUser`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (params: {}) => accounts.getMe()
)

export const signIn = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/signIn`,
  async ({ email, password, ...challengeHandlers }: SignInPayload) => {
    const data = await api.signIn(email, password)
    return handleChallenge(data, challengeHandlers)
  }
)

export const signOut = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/signOut`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: {}) => {
    try {
      await api.signOut()
    } finally {
      window.location.reload()
    }
  }
)

export const resetPassword = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/resetPassword`,
  ({ email }: { email: string }) => api.resetPassword(email)
)

export const confirmForgotPassword = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/confirmForgotPassword`,
  ({ password, token }: ConfirmForgotPasswordPayload) =>
    api.confirmForgotPassword(password, token)
)

export const setNewPassword = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/setNewPassword`,
  ({ password, token }: SetNewPasswordPayload) =>
    api.setNewPassword(password, token)
)

export const resolveChallenge = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/resolveChallenge`,
  async ({ code, token }: ResolveChallengePayload) => {
    await api.resolveChallenge(code, token)
    return accounts.getMe()
  }
)

export const resetSignIn = createAction(`${NAMESPACE}/resetSignIn`)

export const resetSignOut = createAction(`${NAMESPACE}/resetSignOut`)

export const resetResetPassword = createAction(
  `${NAMESPACE}/resetResetPassword`
)

export const resetSetNewPassword = createAction(
  `${NAMESPACE}/resetSetNewPassword`
)

export const resetConfirmForgotPassword = createAction(
  `${NAMESPACE}/resetConfirmForgotPassword`
)

export const resetResolveChallenge = createAction(
  `${NAMESPACE}/resetResolveChallenge`
)
