import { LoadingStatus } from '@vivium/frontend-common/types'
import { format, parseISO, parse, setMinutes, addHours } from 'date-fns'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AccessibleApplications } from 'shared/types'
import { selectors, actions } from '../store'

export const getDefaultTime = (today: Date) => {
  const defaultTime = setMinutes(addHours(today, 2), 0)
  return format(defaultTime, 'HH.mm')
}

export const dateAndTimeToIso = (date: string, time: string | Date) => {
  const dateFormat = 'yyyy-MM-dd'
  const formattedDate = format(parseISO(date), dateFormat)
  return parse(
    `${formattedDate} ${time}`,
    'yyyy-MM-dd HH.mm',
    new Date()
  ).toISOString()
}

export const useGetMessageUpdate = (id: number) => {
  const dispatch = useDispatch()

  const {
    data: messageUpdate,
    loading,
    error,
  } = useSelector(selectors.getMessageUpdate)

  useEffect(() => {
    dispatch(actions.getMessageUpdate(id))
  }, []) //eslint-disable-line

  return {
    messageUpdate,
    isLoading: loading === LoadingStatus.Pending,
    error,
  }
}

export const convertOptionToAccessibleApplicationArray = (
  option: AccessibleApplications
) =>
  option === AccessibleApplications.All
    ? [AccessibleApplications.ViviumX, AccessibleApplications.ViviumI]
    : [option]

export interface InformationAutocompleteOption {
  label: string
  value: AccessibleApplications
}

export const useInformationAutocompleteOptions = () => {
  const { t } = useTranslation()

  const availableAccessibleApplications = [
    AccessibleApplications.ViviumI,
    AccessibleApplications.ViviumX,
    AccessibleApplications.All,
  ]

  const accessibleApplicationsOptions = availableAccessibleApplications.map(
    (accessibleApplication: AccessibleApplications) => ({
      label: t(`accessibleApplications.${accessibleApplication}`),
      value: accessibleApplication,
    })
  )

  const defaultAccessibleApplicationOptions = {
    label: t(`accessibleApplications.${AccessibleApplications.All}`),
    value: AccessibleApplications.All,
  }

  return {
    accessibleApplicationsOptions,
    defaultAccessibleApplicationOptions,
  }
}
