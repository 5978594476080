import Api from '../api'
import { PostSms } from './Sms.types'

class Sms extends Api {
  public sendSms = async ({ ...payload }: PostSms) => {
    const { data } = await this.api.post<number>(`/admin/sms`, payload)
    return data
  }
}

export default Sms
