import { useState, MouseEvent } from 'react'
import { ModalTypes } from '../types'

export interface UseModal {
  openModal: (
    anchor: MouseEvent<HTMLElement> | MouseEvent<SVGSVGElement>,
    modalType: ModalTypes,
    id: number,
    data?: any
  ) => void
  closeModal: () => void
  anchorEl: HTMLElement | SVGSVGElement | null
  isModalOpen: boolean
  modalType: ModalTypes | null
  id: number
  data?: any
}

export const useModal = (): UseModal => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | SVGSVGElement | null>(
    null
  )
  const [modalType, setModalType] = useState<ModalTypes | null>(null)
  const [id, setId] = useState<number>(0)
  const [data, setData] = useState(null)

  const openModal = (
    anchor: MouseEvent<HTMLElement> | MouseEvent<SVGSVGElement>,
    type: ModalTypes,
    id: number,
    data?: any
  ) => {
    setAnchorEl(anchor.currentTarget)
    setModalType(type)
    setId(id)
    setData(data)
  }

  const closeModal = () => setAnchorEl(null)

  const isModalOpen = !!anchorEl

  return { openModal, closeModal, anchorEl, isModalOpen, modalType, id, data }
}
