import { Button, Grid } from '@material-ui/core'
import { WatchLater } from '@material-ui/icons'
import {
  TextField,
  Form,
  DateField,
  TimeField,
} from '@vivium/frontend-common/components'
import { useTranslation } from 'react-i18next'
import { RichTextEditor } from 'shared/components'
import { useInformationAutocompleteOptions } from '../../pages/informations.utils'
import { MODULE_NAME } from '../../strings'
import {
  DateFieldWrapper,
  EmailBodyWrapper,
  ActionsWrapper,
  StyledLoadingButton,
  GridDateWrapper,
  TextFieldWrapper,
  StyledAutocompleteField,
} from './UpdateForm.styles'
import {
  UpdateFields,
  useFormProps,
  useOnSubmit,
  MAX_TITLE_LENGTH,
  MAX_MESSAGE_LENGTH,
} from './UpdateForm.utils'

interface UpdateFormProps {
  onCloseModal: () => void
}

const UpdateForm = ({ onCloseModal }: UpdateFormProps) => {
  const { t } = useTranslation(MODULE_NAME)
  const formProps = useFormProps()
  const { isSubmitting, handleSubmit } = useOnSubmit(onCloseModal)
  const { accessibleApplicationsOptions } = useInformationAutocompleteOptions()

  return (
    <Form {...formProps} onSubmit={handleSubmit}>
      <Grid container justifyContent="center" alignItems="center">
        <TextFieldWrapper container>
          <StyledAutocompleteField
            label={t('informationsForms.recipients')}
            options={accessibleApplicationsOptions}
            name={UpdateFields.AccessibleApplications}
            disableClearable
          />
        </TextFieldWrapper>
        <DateFieldWrapper container item xs={12}>
          <GridDateWrapper item xs={4}>
            <DateField
              name={UpdateFields.PublicationDate}
              label={t('informationsForms.publicationDate')}
              placeholder={t('informationsForms.datePlaceholder')}
              formatToIsoString
              disablePast
            />
          </GridDateWrapper>
          <Grid item xs={2}>
            <TimeField
              label=" "
              IconComponent={WatchLater}
              name={UpdateFields.PublicationTime}
            />
          </Grid>
        </DateFieldWrapper>
        <TextFieldWrapper item xs={12}>
          <TextField
            required
            label={t('informationsForms.message')}
            name={UpdateFields.UpdateMessage}
            charactersCounter={MAX_MESSAGE_LENGTH}
            inputProps={{ maxLength: MAX_MESSAGE_LENGTH }}
          />
        </TextFieldWrapper>
        <TextFieldWrapper item xs={12}>
          <TextField
            required
            label={t('informationsForms.tittle')}
            name={UpdateFields.Title}
            charactersCounter={MAX_TITLE_LENGTH}
            inputProps={{ maxLength: MAX_TITLE_LENGTH }}
          />
        </TextFieldWrapper>
        <EmailBodyWrapper container item md={11} sm={12}>
          <RichTextEditor name={UpdateFields.MessageBody} />
        </EmailBodyWrapper>
      </Grid>
      <ActionsWrapper container justifyContent="flex-end">
        <Button fullWidth={false} onClick={onCloseModal}>
          {t('informationsForms.cancel')}
        </Button>
        <StyledLoadingButton
          type="submit"
          color="primary"
          variant="contained"
          fullWidth={false}
          loading={isSubmitting}
        >
          {t('informations.create')}
        </StyledLoadingButton>
      </ActionsWrapper>
    </Form>
  )
}

export default UpdateForm
