import Api from '../api'
import {
  PostMessageInformation,
  GetMessagesInformationResponse,
  GetMessagesInformationParams,
  MessageInfoUpdateParams,
  MessageInfo,
} from './MessageInformation.types'

class MessageInformation extends Api {
  public getMessagesInformations = async (
    params?: GetMessagesInformationParams
  ) => {
    const { data } = await this.api.get<GetMessagesInformationResponse>(
      '/admin/system-messages/info',
      { params }
    )
    return data
  }
  public addMessageInformation = async ({
    ...payload
  }: PostMessageInformation) => {
    const { data } = await this.api.post<number>(
      '/admin/system-messages/info',
      payload
    )
    return data
  }

  public getMessageInformation = async (messageId: number) => {
    const { data } = await this.api.get<MessageInfo>(
      `/admin/system-messages/info/${messageId}`
    )
    return data
  }

  public updateMessageInformation = async ({
    id,
    ...payload
  }: MessageInfoUpdateParams) => {
    const { data } = await this.api.put<MessageInfo>(
      `/admin/system-messages/info/${id}`,
      payload
    )
    return data
  }

  public deleteMessageInformation = async (id: number) => {
    const { data } = await this.api.delete<number>(
      `/admin/system-messages/${id}`
    )
    return data
  }
}

export default MessageInformation
