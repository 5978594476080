import { RequestHandler } from 'msw'
import { careProviderResourceRequestHandlers } from './careProviderResourceRequestHandlers'
import { staffProviderResourceRequestHandlers } from './staffProviderResource'

const handlers: RequestHandler[] = [
  ...staffProviderResourceRequestHandlers,
  ...careProviderResourceRequestHandlers,
]

export default handlers
