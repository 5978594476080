import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import config, { paths } from 'config'
import { identity } from '../../utils/helpers'
import CryptoService from '../crypto'
import { RequestConfig, SignInResponse } from './Api.types'

class Api {
  protected api: AxiosInstance = axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
  })

  constructor() {
    this.api.interceptors.request.use(this.handleRequest)
    this.api.interceptors.response.use(identity, this.handleError)
  }

  private handleRequest = (requestConfig: AxiosRequestConfig) => {
    requestConfig.headers['X-Api-Key'] = config.apiKey
    requestConfig.headers['X-Application-Id'] = config.applicationId
    requestConfig.headers['X-Timezone-Offset'] = new Date().getTimezoneOffset()
    requestConfig.headers['X-Timezone'] =
      Intl.DateTimeFormat().resolvedOptions().timeZone
    return requestConfig
  }

  public signIn = async (email: string, password: string) => {
    const headers = await this.getCsrfHeaders()
    const _email = CryptoService.encryptMessage(
      email,
      config.requestEncryptionAESSecretKey,
      config.requestEncryptionAESIv
    )
    const _password = CryptoService.encryptMessage(
      password,
      config.requestEncryptionAESSecretKey,
      config.requestEncryptionAESIv
    )

    const { data } = await this.api.post<SignInResponse>(
      '/auth/login',
      { email: _email, password: _password },
      { headers }
    )
    return data
  }

  public signOut = () => this.api.post('/auth/logout')

  public resetPassword = async (email: string) => {
    const headers = await this.getCsrfHeaders()
    return this.api.post('/auth/forgot-password', { email }, { headers })
  }

  public confirmForgotPassword = async (password: string, token: string) => {
    const headers = await this.getCsrfHeaders()
    const _password = CryptoService.encryptMessage(
      password,
      config.requestEncryptionAESSecretKey,
      config.requestEncryptionAESIv
    )

    return this.api.post(
      '/auth/forgot-password/confirm',
      { password: _password, token },
      { headers }
    )
  }
  public setNewPassword = async (password: string, token: string) => {
    const headers = await this.getCsrfHeaders()
    const _password = CryptoService.encryptMessage(
      password,
      config.requestEncryptionAESSecretKey,
      config.requestEncryptionAESIv
    )

    return this.api.post(
      '/auth/new-password',
      { password: _password, token },
      { headers }
    )
  }

  public resolveChallenge = async (code: string, token: string) => {
    const headers = await this.getCsrfHeaders()
    return this.api.post('/auth/login/challenge', { code, token }, { headers })
  }

  protected getCsrfHeaders = async () => {
    const { data } = await this.api.get('/auth/check-action')
    const { token = null } = data || {}
    const headers: AxiosRequestConfig['headers'] = { 'X-CSRF-Token': token }
    return headers
  }

  protected refreshToken = () => this.api.post('/auth/refresh')

  private isAuthRequest = (url?: string) => url?.match(/\/auth\//)

  private handleUnauthorizedRequest = async (error: AxiosError) => {
    if ((error.config as RequestConfig).isRetry) {
      return Promise.reject(error)
    }
    if (this.isAuthRequest(error.config.url)) {
      localStorage.clear()
      return window.location.assign(paths.unauthorized)
    }
    await this.refreshToken()
    return this.api.request({
      ...error.config,
      isRetry: true,
    } as AxiosRequestConfig)
  }

  private handleForbiddenRequest = async () =>
    window.location.assign(paths.forbidden)

  private handleError = async (error: any) => {
    switch ((error as AxiosError)?.response?.status) {
      case 401:
        return this.handleUnauthorizedRequest(error)
      case 403:
        return this.handleForbiddenRequest()
      default:
        return Promise.reject(error)
    }
  }
}

export default Api
