import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'

export const selectAllIdsCheckbox = (state: RootState) =>
  state.system.shared.selectAllIdsCheckbox

export const selectedIds = (state: RootState) => state.system.shared.selectedIds

export const isIdSelected = createSelector(selectedIds, ids => (id: number) => {
  return ids[id] ? ids[id] : false
})
