import tables from '@vivium/frontend-common/store/tables'
import { combineReducers } from 'redux'
import auth from './auth'
import organizations from './organizations'
import type { State as OrganizationsState } from './organizations'
import recipients from './recipients'
import type { State as RecipientsState } from './recipients'

export interface CommonState {
  common: {
    organizations: OrganizationsState
    recipients: RecipientsState
  }
}

export const actions = {
  auth: auth.actions,
  organizations: organizations.actions,
  recipients: recipients.actions,
  tables: tables.actions,
}

export const selectors = {
  auth: auth.selectors,
  organizations: organizations.selectors,
  recipients: recipients.selectors,
  tables: tables.selectors,
}

export const reducer = combineReducers({
  auth: auth.reducer,
  organizations: organizations.reducer,
  recipients: recipients.reducer,
  tables: tables.reducer,
})
