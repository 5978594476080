import { RootState } from 'app'

export const deleteMessageUpdate = (state: RootState) =>
  state.informations.deleteMessageUpdate

export const deleteMessageInformation = (state: RootState) =>
  state.informations.deleteMessageInformation

export const putMessageUpdate = (state: RootState) =>
  state.informations.putMessageUpdate

export const getMessageUpdate = (state: RootState) =>
  state.informations.getMessageUpdate

export const addMessageInformation = (state: RootState) =>
  state.informations.addMessageInformation

export const addMessageUpdate = (state: RootState) =>
  state.informations.addMessageUpdate

export const updateMessageInformation = (state: RootState) =>
  state.informations.updateMessageInformation

export const getMessagesInformations = (state: RootState) =>
  state.informations.getMessagesInformations

export const getMessagesUpdates = (state: RootState) =>
  state.informations.getMessagesUpdates

export const getMessageInformation = (state: RootState) =>
  state.informations.getMessageInformation

export const addMessageInformationLoading = (state: RootState) =>
  state.informations.addMessageInformation.loading
