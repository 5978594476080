import { styled, Grid } from '@material-ui/core'

export const ModalContainer = styled(Grid)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 2,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.neutral.main,
  '& button:nth-child(1)': {
    backgroundColor: theme.palette.background.default,
    marginRight: theme.spacing(1),
  },

  '& button': {
    padding: theme.spacing(1, 6),
  },

  '& div:nth-child(1)': {
    paddingLeft: theme.spacing(3),
  },
  '& div:nth-child(2)': {
    paddingRight: theme.spacing(3),
  },
}))
