import { ContentState, convertToRaw, RawDraftContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { useState, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useFormContext, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  StyledTypography,
  editorStyle,
  toolbarStyle,
  wrapperStyle,
} from './RichTextEditor.styles'
import {
  handleEditorChange,
  imageUploadCallback,
  toolbarOptions,
} from './RichTextEditor.utils'

interface RichTextEditorProps {
  name: string
  defaultValue?: string
}

const RichTextEditor = ({ name, defaultValue }: RichTextEditorProps) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext()
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue })

  const [contentState, setContentState] = useState<
    RawDraftContentState | undefined
  >(undefined)
  useEffect(() => {
    if (defaultValue) {
      const contentBlock = htmlToDraft(defaultValue)
      const _contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const raw = convertToRaw(_contentState)
      setContentState(raw)
      setValue(name, raw)
    }
  }, [defaultValue, name, setValue])

  return (
    <>
      <Editor
        // @ts-expect-error
        wrapperStyle={wrapperStyle}
        contentState={contentState}
        onChange={e => onChange(handleEditorChange(e))}
        editorStyle={editorStyle(error)}
        toolbarStyle={toolbarStyle(error)}
        placeholder={t('richTextEditor.bodyPlaceholder')}
        toolbar={{
          image: {
            uploadCallback: imageUploadCallback,
            urlEnabled: false,
            alt: { present: true, mandatory: false },
            previewImage: true,
            defaultSize: {
              height: '200',
              width: '200',
            },
          },
          plugins: {},
          options: toolbarOptions,
        }}
        inputRef={ref}
        {...inputProps}
      />
      {error && (
        <StyledTypography variant="caption">
          {t('validation.required')}
        </StyledTypography>
      )}
    </>
  )
}

export default RichTextEditor
