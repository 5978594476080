import { combineReducers } from '@reduxjs/toolkit'
// accounts
import * as accountsActions from './accounts/actions'
import accountsReducer from './accounts/reducer'
import * as accountsSelectors from './accounts/selectors'
// shared
import * as systemActions from './actions'
// consultants
import * as consutalntsActions from './consultants/actions'
import consutalntsReducer from './consultants/reducer'
import * as consutalntsSelectors from './consultants/selectors'
// groups
import * as organizationGroupsActions from './organizationGroups/actions'
import organizationGroupsReducer from './organizationGroups/reducer'
import * as organizationGroupsSelectors from './organizationGroups/selectors'
// organizations
import * as organizationsActions from './organizations/actions'
import organizationsReducer from './organizations/reducer'
import * as organizationsSelectors from './organizations/selectors'
// shared
import systemReducer from './reducer'
// roles and specializations
import * as rolesAndSpecializationsActions from './rolesAndSpecializations/actions'
import rolesAndSpecializationsReducer from './rolesAndSpecializations/reducer'
import * as rolesAndSpecializationsSelectors from './rolesAndSpecializations/selectors'
// shared
import * as systemSelectors from './selectors'
//suggestions
import * as suggestionsActions from './suggestions/actions'
import suggestionsReducer from './suggestions/reducer'
import * as suggestionsSelectors from './suggestions/selectors'

const actions = {
  ...organizationGroupsActions,
  ...systemActions,
  ...rolesAndSpecializationsActions,
  ...accountsActions,
  ...organizationsActions,
  ...consutalntsActions,
  ...suggestionsActions,
}

const reducer = combineReducers({
  organizationGroups: organizationGroupsReducer,
  shared: systemReducer,
  rolesAndSpecializations: rolesAndSpecializationsReducer,
  accounts: accountsReducer,
  organizations: organizationsReducer,
  consultants: consutalntsReducer,
  suggestions: suggestionsReducer,
})

const selectors = {
  ...organizationGroupsSelectors,
  ...systemSelectors,
  ...rolesAndSpecializationsSelectors,
  ...accountsSelectors,
  ...organizationsSelectors,
  ...consutalntsSelectors,
  ...suggestionsSelectors,
}

export { actions, reducer, selectors }
