import { createReducer } from '@reduxjs/toolkit'
import { MessageInfo } from 'shared/services/messageInformation'
import { MessageUpd } from 'shared/services/messageUpdate'
import resource, { Resource } from '../../../shared/resource'
import {
  addMessageInformation,
  getMessagesInformations,
  getMessageInformation,
  updateMessageInformation,
  getMessagesUpdates,
  addMessageUpdate,
  getMessageUpdate,
  putMessageUpdate,
  deleteMessageInformation,
  deleteMessageUpdate,
} from './actions'

export interface State {
  addMessageUpdate: Resource<number | null>
  getMessagesUpdates: Resource<MessageUpd[]>
  getMessageUpdate: Resource<MessageUpd | null>
  putMessageUpdate: Resource<MessageUpd | null>
  addMessageInformation: Resource<number | null>
  getMessagesInformations: Resource<MessageInfo[]>
  getMessageInformation: Resource<MessageInfo | null>
  updateMessageInformation: Resource<MessageInfo | null>
  deleteMessageUpdate: Resource<number | null>
  deleteMessageInformation: Resource<number | null>
}

const initialState: State = {
  addMessageUpdate: resource.getInitial<number | null>(null),
  getMessagesUpdates: resource.getInitial<MessageUpd[]>([]),
  getMessageUpdate: resource.getInitial<MessageUpd | null>(null),
  putMessageUpdate: resource.getInitial<MessageUpd | null>(null),
  getMessagesInformations: resource.getInitial<MessageInfo[]>([]),
  getMessageInformation: resource.getInitial<MessageInfo | null>(null),
  updateMessageInformation: resource.getInitial<MessageInfo | null>(null),
  addMessageInformation: resource.getInitial<number | null>(null),
  deleteMessageUpdate: resource.getInitial<number | null>(null),
  deleteMessageInformation: resource.getInitial<number | null>(null),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(deleteMessageUpdate.pending, state => {
      resource.setPending(state.deleteMessageUpdate)
    })
    .addCase(deleteMessageUpdate.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteMessageUpdate, action.payload)
    })
    .addCase(deleteMessageUpdate.rejected, (state, action) => {
      resource.setFailed(state.deleteMessageUpdate, action.error.message)
    })
    .addCase(deleteMessageInformation.pending, state => {
      resource.setPending(state.deleteMessageInformation)
    })
    .addCase(deleteMessageInformation.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteMessageInformation, action.payload)
    })
    .addCase(deleteMessageInformation.rejected, (state, action) => {
      resource.setFailed(state.deleteMessageInformation, action.error.message)
    })
    .addCase(addMessageUpdate.pending, state => {
      resource.setPending(state.addMessageUpdate)
    })
    .addCase(addMessageUpdate.fulfilled, (state, action) => {
      resource.setSucceeded(state.addMessageUpdate, action.payload)
    })
    .addCase(addMessageUpdate.rejected, (state, action) => {
      resource.setFailed(state.addMessageUpdate, action.error.message)
    })
    .addCase(getMessagesUpdates.pending, state => {
      resource.setPending(state.getMessagesUpdates)
    })
    .addCase(getMessagesUpdates.fulfilled, (state, action) => {
      resource.setSucceeded(state.getMessagesUpdates, action.payload.data)
    })
    .addCase(getMessagesUpdates.rejected, (state, action) => {
      resource.setFailed(state.getMessagesUpdates, action.error.message)
    })
    .addCase(getMessageUpdate.pending, state => {
      resource.setPending(state.getMessageUpdate)
    })
    .addCase(getMessageUpdate.fulfilled, (state, action) => {
      resource.setSucceeded(state.getMessageUpdate, action.payload)
    })
    .addCase(getMessageUpdate.rejected, (state, action) => {
      resource.setFailed(state.getMessageUpdate, action.error.message)
    })
    .addCase(putMessageUpdate.pending, state => {
      resource.setPending(state.putMessageUpdate)
    })
    .addCase(putMessageUpdate.fulfilled, (state, action) => {
      resource.setSucceeded(state.putMessageUpdate, action.payload)
    })
    .addCase(putMessageUpdate.rejected, (state, action) => {
      resource.setFailed(state.putMessageUpdate, action.error.message)
    })
    .addCase(addMessageInformation.pending, state => {
      resource.setPending(state.addMessageInformation)
    })
    .addCase(addMessageInformation.fulfilled, (state, action) => {
      resource.setSucceeded(state.addMessageInformation, action.payload)
    })
    .addCase(addMessageInformation.rejected, (state, action) => {
      resource.setFailed(state.addMessageInformation, action.error.message)
    })
    .addCase(updateMessageInformation.pending, state => {
      resource.setPending(state.updateMessageInformation)
    })
    .addCase(updateMessageInformation.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateMessageInformation, action.payload)
    })
    .addCase(updateMessageInformation.rejected, (state, action) => {
      resource.setFailed(state.updateMessageInformation, action.error.message)
    })
    .addCase(getMessagesInformations.pending, state => {
      resource.setPending(state.getMessagesInformations)
    })
    .addCase(getMessagesInformations.fulfilled, (state, action) => {
      resource.setSucceeded(state.getMessagesInformations, action.payload.data)
    })
    .addCase(getMessagesInformations.rejected, (state, action) => {
      resource.setFailed(state.getMessagesInformations, action.error.message)
    })
    .addCase(getMessageInformation.pending, state => {
      resource.setPending(state.getMessageInformation)
    })
    .addCase(getMessageInformation.fulfilled, (state, action) => {
      resource.setSucceeded(state.getMessageInformation, action.payload)
    })
    .addCase(getMessageInformation.rejected, (state, action) => {
      resource.setFailed(state.getMessageInformation, action.error.message)
    })
)
