import { RequestHandler, rest } from 'msw'
import { apiUrl, search, searchUnits } from '../server.utils'

const handlers: RequestHandler[] = [
  rest.put(
    apiUrl('/mock/care-providers/:careProviderId/resources/:resourceId'),
    (req, res, ctx) => res(ctx.status(200), ctx.text('677'))
  ),
  rest.get(
    apiUrl('/mock/care-providers/:careProviderId/resources/:resourceId'),
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          id: 677,
          name: '2609Test',
          socialSecurityNumber: 'test26',
          legitimizationYear: 1927,
          contractExpiresAt: '2022-10-26T22:00:00.000Z',
          monthlyWorkingHours: 5,
          medicalRecordSystem:
            'fdlhamuixmjyqnchdsdqajdvgnezmrwzxtttdrovdbiylcoemr',
          prescriberCode: 'fdlhamuixmjyqnchdsdqajdvgnezmrwzxtttdrovdbiylcoemr',
          HSAID: 'fdlhamuixmjyqnchdsdqajdvgnezmrwzxtttdrovdbiylcoemr',
          hasSITHSCard: true,
          contactAddress: 'Test',
          contactPostalCode: '2',
          contactPhoneNumber: '795504214',
          contactEmail: 'marcin.michno@euvic.pl',
          contactDescription:
            'shgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgdsshgsdgsdgds',
          contactCity: 'test',
          profileFulfillment: 95,
          isActive: false,
          employmentType: 'HOURLY',
          employeeType: 'SUBSTITUTE',
          preferredWorkTimes: ['MORNING', 'AFTERNOON', 'NIGHT', 'WEEKEND'],
          tags: [
            {
              id: 52,
              name: 'tag52',
            },
          ],
          documents: [
            {
              id: 417,
              originalName: 'Leon.pdf',
              mimeType: 'application/pdf',
              type: 'CV',
            },
          ],
          account: {
            twoFactorAuthenticationEnabled: false,
            id: 1002,
            name: '2609Test',
            title: null,
            email: 'cprcprpcprpcpr@euvic.pl',
            isActive: false,
            role: 'CARE_PROVIDER_RESOURCE',
            isBlocked: false,
          },
          specialties: [
            {
              id: 38,
              name: 'Neorologi',
            },
          ],
          role: {
            id: 1,
            name: 'Läkare',
          },
          competences: [
            {
              id: 2,
              name: 'Competence 02',
            },
          ],
        })
      )
  ),
  rest.post(
    apiUrl('/mock/care-providers/:careProviderId/resources'),
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          name: 'test cp resource',
          socialSecurityNumber: '1248995-13',
          legitimizationYear: 0,
          contractExpiresAt: null,
          monthlyWorkingHours: 15,
          medicalRecordSystem: '',
          prescriberCode: '',
          HSAID: '12354123213',
          hasSITHSCard: false,
          contactAddress: '',
          contactPostalCode: '',
          contactCity: '',
          contactPhoneNumber: '',
          contactEmail: 'testemail@email.com',
          contactDescription: '',
          preferredWorkTimes: ['MORNING', 'AFTERNOON', 'NIGHT', 'WEEKEND'],
          tagIds: [187],
          roleId: 5,
          specialtyIds: [],
          competenceIds: [],
          hideIdentity: false,
          documentIds: [],
          employmentType: 'FULL_TIME',
        })
      )
  ),
  rest.post(
    apiUrl('/mock/care-providers/:careProviderId/tags'),
    (req, res, ctx) => res(ctx.status(200), ctx.text('456'))
  ),
  rest.delete(
    apiUrl(
      '/mock/care-providers/:careProviderId/resource-documents/:documentId'
    ),
    (req, res, ctx) => res(ctx.status(200), ctx.text('456'))
  ),
  rest.get(
    apiUrl(
      '/mock/care-providers/:careProviderId/resource-documents/:documentId'
    ),
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          id: req.url.searchParams.get('documentId'),
          createdAt: '2022-11-08T21:54:59.927Z',
          updatedAt: '2022-11-08T21:54:59.927Z',
          deletedAt: null,
          originalName: 'document.pdf',
          mimeType: 'application/pdf',
          path: 'care_provider_resources_documents/1667944499938_453_bilet_1859454711944.pdf',
          type: 'CV',
          careProviderId: req.url.searchParams.get('careProviderId'),
          resourceId: null,
          createdById: 3,
          modifiedById: null,
          deletedById: null,
          url: 'https://s3.sto2.safedc.net/vivium-api-dev-storage/care_provider_resources_documents/1667944499938_453_bilet_1859454711944.pdf?AWSAccessKeyId=62QUBY1S32J74H07Y2OM&Signature=wr4LnbGoZZ2UgTIAK4IjjuDX3tU%3D&Expires=1667944622',
        })
      )
  ),
  rest.post(
    apiUrl('/mock/care-providers/:careProviderId/resource-documents'),
    (req, res, ctx) => res(ctx.status(200), ctx.text('456'))
  ),
  rest.get(
    apiUrl('/mock/care-providers/:careProviderId/suggestions/tags'),
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          data: search(
            [
              {
                id: 180,
                name: 'a',
              },
              {
                id: 185,
                name: 'adminowy tag',
              },
              {
                id: 48,
                name: 'tag48',
              },
              {
                id: 5,
                name: 'tag5',
              },
              {
                id: 50,
                name: 'tag50',
              },
              {
                id: 52,
                name: 'tag52',
              },
              {
                id: 82,
                name: 'tag82',
              },
              {
                id: 83,
                name: 'tag83',
              },
              {
                id: 184,
                name: 'tag czubaja',
              },
              {
                id: 179,
                name: 'test',
              },
              {
                id: 182,
                name: 'test4532',
              },
              {
                id: 178,
                name: 'unit 10',
              },
              {
                id: 181,
                name: 'vzvx',
              },
            ],
            {
              search: req.url.searchParams.get('search'),
              searchBy: req.url.searchParams.get('searchBy'),
            }
          ),
        })
      )
  ),
  rest.get(
    apiUrl('/mock/care-providers/:careProviderId/units'),
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          data: searchUnits(
            [
              {
                id: 115,
                name: 'AKM ',
                description: 'AKM ',
                tags: [],
                department: {
                  id: 27,
                  name: 'Akutsjukvård',
                },
              },
              {
                id: 107,
                name: 'asdas',
                description: 'asdas',
                tags: [
                  {
                    id: 48,
                    name: 'tag48',
                  },
                  {
                    id: 180,
                    name: 'a',
                  },
                ],
                department: {
                  id: 23,
                  name: 'shor department',
                },
              },
              {
                id: 110,
                name: 'das',
                description: 'das',
                tags: [],
                department: {
                  id: 5,
                  name: 'Department 5',
                },
              },
              {
                id: 98,
                name: 'new unit test',
                description: 'new unit test',
                tags: [
                  {
                    id: 5,
                    name: 'tag5',
                  },
                  {
                    id: 48,
                    name: 'tag48',
                  },
                  {
                    id: 50,
                    name: 'tag50',
                  },
                ],
                department: {
                  id: 5,
                  name: 'Department 5',
                },
              },
              {
                id: 102,
                name: 'Ny avdelning',
                description: 'Ny avdelning',
                tags: [],
                department: {
                  id: 20,
                  name: 'na',
                },
              },
              {
                id: 101,
                name: 'Ny avdelning test',
                description: 'Ny avdelning test',
                tags: [],
                department: {
                  id: 26,
                  name: 'Enhet test',
                },
              },
              {
                id: 111,
                name: 'test',
                description: '',
                tags: [],
                department: {
                  id: 5,
                  name: 'Department 5',
                },
              },
              {
                id: 112,
                name: 'Test',
                description: '',
                tags: [],
                department: {
                  id: 5,
                  name: 'Department 5',
                },
              },
              {
                id: 109,
                name: 'Test102514',
                description: '',
                tags: [],
                department: {
                  id: 5,
                  name: 'Department 5',
                },
              },
              {
                id: 108,
                name: 'Test2508',
                description: 'Test2508',
                tags: [],
                department: {
                  id: 18,
                  name: 'test12',
                },
              },
              {
                id: 116,
                name: 'Unit123',
                description: 'Unit123',
                tags: [],
                department: {
                  id: 27,
                  name: 'Akutsjukvård',
                },
              },
              {
                id: 28,
                name: 'Unit 28',
                description: 'Unit 28',
                tags: [
                  {
                    id: 50,
                    name: 'tag50',
                  },
                ],
                department: {
                  id: 5,
                  name: 'Department 5',
                },
              },
              {
                id: 30,
                name: 'Unit 30a',
                description: 'Unit 30a',
                tags: [
                  {
                    id: 52,
                    name: 'tag52',
                  },
                ],
                department: {
                  id: 19,
                  name: 'test department',
                },
              },
              {
                id: 5,
                name: 'Unit 5',
                description: 'Unit 5',
                tags: [
                  {
                    id: 5,
                    name: 'tag5',
                  },
                  {
                    id: 48,
                    name: 'tag48',
                  },
                ],
                department: {
                  id: 5,
                  name: 'Department 5',
                },
              },
              {
                id: 97,
                name: 'Unit czubaja',
                description: 'Unit czubaja',
                tags: [
                  {
                    id: 184,
                    name: 'tag czubaja',
                  },
                ],
                department: {
                  id: 5,
                  name: 'Department 5',
                },
              },
            ],
            {
              tagIds: req.url.searchParams.getAll('tagIds[]'),
              search: req.url.searchParams.get('search'),
              searchBy: req.url.searchParams.get('searchBy'),
            }
          ),
        })
      )
  ),
]

export default handlers
