import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from '../../../shared/resource'
import { sendSms } from './actions'

interface State {
  sendSms: Resource<number | null>
}

const initialState: State = {
  sendSms: resource.getInitial<number | null>(null),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(sendSms.pending, state => {
      resource.setPending(state.sendSms)
    })
    .addCase(sendSms.fulfilled, (state, action) => {
      resource.setSucceeded(state.sendSms, action.payload)
    })
    .addCase(sendSms.rejected, (state, action) => {
      resource.setFailed(state.sendSms, action.error.message)
    })
)
