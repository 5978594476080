import { FilteringParams, SortingOrder } from '@vivium/frontend-common/types'
import { Entity } from '@vivium/frontend-common/types'
import { isEmpty, orderBy, isObject } from 'lodash'
import config from '../../config'

export const apiUrl = (path: string) => config.apiUrl + path

type SearchParams = { [P in keyof FilteringParams]: FilteringParams[P] | null }

export const sort = (items: any[], searchQuery: string) => {
  const orderByParam = searchQuery.match(/sortBy=([^&]*)/)
  const sortOrderParam = searchQuery.match(/sortOrder=([^&]*)/)

  if (orderByParam && sortOrderParam)
    return orderBy(
      items,
      item =>
        isObject(item[orderByParam[1]])
          ? item[orderByParam[1]].name
          : item[orderByParam[1]],
      [sortOrderParam[1] as SortingOrder]
    )
  return items
}

export const search = <T>(items: T[], { search, searchBy }: SearchParams) =>
  items.filter(item =>
    // @ts-expect-error
    searchBy ? (item[searchBy] || '').includes(search ? search : '') : item
  )

interface Unit extends Entity {
  description: string
  tags: Entity[]
  department: Entity
}

export const searchUnits = (
  items: Unit[],
  { search, searchBy, tagIds }: SearchParams & { tagIds: string[] | null }
) => {
  const filteredUnits = items.filter(item =>
    // @ts-expect-error
    searchBy ? (item[searchBy] || '').includes(search ? search : '') : item
  )

  if (isEmpty(tagIds) || isEmpty(filteredUnits)) return filteredUnits

  const tagIdsArrNumbers = tagIds?.map(tag => parseInt(tag))

  const unitInlcudingTags = filteredUnits.filter(unit => {
    const foundUnits = unit.tags.filter(({ id }: Entity) =>
      tagIdsArrNumbers?.includes(id)
    )
    return !isEmpty(foundUnits)
  })

  return unitInlcudingTags
}
