import { styled, Typography } from '@material-ui/core'
import CSS from 'csstype'
import { theme } from 'config'

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  margin: `${theme.spacing(0.5, 0)}`,
}))

export const editorStyle = (error: Error): CSS.Properties => ({
  padding: `${theme.spacing(0, 3, 3)}`,
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
  border: '2px solid',
  borderColor: error
    ? theme.palette.error.main
    : theme.palette.background.secondary,
  maxWidth: '100%',
  height: '60%',
})

export const toolbarStyle = (error: Error): CSS.Properties => ({
  backgroundColor: theme.palette.background.paper,
  margin: theme.spacing(4, 0, 1),
  border: '2px solid',
  borderColor: error
    ? theme.palette.error.main
    : theme.palette.background.secondary,
})

export const wrapperStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}
