import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MuiPickersUtilsProviderProps } from '@material-ui/pickers/MuiPickersUtilsProvider'
import {
  useCurrentUser,
  useDateFns,
  useNotificationsListener,
} from '@vivium/frontend-common/hooks'
import { partition } from '@vivium/frontend-common/utils'
import { routes as baseRoutes } from 'modules'

export const routes = partition(baseRoutes, route => !route.modal)

export const Auth = () => {
  useCurrentUser()
  return <></>
}

export const NotificationsListener = () => {
  useNotificationsListener()
  return <></>
}

export const PickersProvider = (
  props: Omit<MuiPickersUtilsProviderProps, 'utils'>
) => {
  const dateOptions = useDateFns()
  return (
    <MuiPickersUtilsProvider
      locale={dateOptions?.locale}
      utils={DateFnsUtils}
      {...props}
    />
  )
}
