import { Button, Typography, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Messages } from 'shared/types'
import { MODULE_NAME } from '../../strings'
import { ModalContainer } from './DeleteActualizationModal.styles'
import { useDeleteMessage } from './DeleteActualizationModal.utils'

interface DeleteActualizationModalProps {
  onCloseModal: () => void
  id: number
  messageType: Messages
}

const DeleteActualizationModal = ({
  onCloseModal,
  id,
  messageType,
}: DeleteActualizationModalProps) => {
  const { t } = useTranslation(MODULE_NAME)
  const deleteMessage = useDeleteMessage()
  return (
    <ModalContainer container alignItems="center" justifyContent="center">
      <Grid
        container
        item
        xs={8}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Typography variant="h6" component="p">
          {t('informationsDeleteModal.deleteQuestion')}
        </Typography>
      </Grid>
      <Grid container item xs={4} alignItems="center" justifyContent="flex-end">
        <Button
          variant="outlined"
          color="primary"
          fullWidth={false}
          onClick={onCloseModal}
        >
          {t('informationsDeleteModal.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth={false}
          onClick={() => deleteMessage(id, messageType)}
        >
          {t('informationsDeleteModal.accept')}
        </Button>
      </Grid>
    </ModalContainer>
  )
}

export default DeleteActualizationModal
