import { styled, Grid, Dialog, DialogContent } from '@material-ui/core'

export const GridWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(8, 5, 2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',

    '& button': {
      marginTop: theme.spacing(1),
    },
  },
}))

export const ModalContainer = styled(Dialog)(({ theme }) => ({
  backgroundColor: theme.palette.shadow.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const FormWrapper = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5, 5, 3, 5),
  overflowY: 'auto',
}))

export const HeaderWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))
