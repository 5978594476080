import Api from '../api'
import { GetFaqsParams } from './Faqs.types'
import {
  FaqUpdateParams,
  GetFaqsResponse,
  PostFaqParams,
  GetFaqResponse,
} from './Faqs.types'

class Faqs extends Api {
  public getFaqs = async (params?: GetFaqsParams) => {
    const { data } = await this.api.get<GetFaqsResponse>('/admin/faqs', {
      params,
    })
    return data
  }
  public addFaq = async ({ ...payload }: PostFaqParams) => {
    const { data } = await this.api.post<number>('/admin/faqs', payload)
    return data
  }

  public getFaq = async (id: number) => {
    const { data } = await this.api.get<GetFaqResponse>(`/admin/faqs/${id}`)
    return data
  }

  public updateFaq = async ({ id, ...payload }: FaqUpdateParams) => {
    const { data } = await this.api.put<number>(`/admin/faqs/${id}`, payload)
    return data
  }

  public deleteFaq = async (id: number) => {
    const { data } = await this.api.delete<number>(`/admin/faqs/${id}`)
    return data
  }
}

export default Faqs
