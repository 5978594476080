import { PieChart } from '@vivium/frontend-common/components'
import { PieChartWrapper } from './OpinionsStatisticsChart.styles'
import {
  OpinionsStatisticsChartProps,
  useModifyFeedbackDataForCircularChart,
} from './OpinionsStatisticsChart.utils'

const OpinionsStatisticsChart = ({
  feedbackData,
}: OpinionsStatisticsChartProps) => {
  const dataForChart = useModifyFeedbackDataForCircularChart(feedbackData)

  return (
    <PieChartWrapper
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      direction="row-reverse"
    >
      <PieChart
        data={dataForChart}
        legend
        size="large"
        loading={false}
        sortingOrder={[]}
        innerRadius={0.9}
      />
    </PieChartWrapper>
  )
}

export default OpinionsStatisticsChart
