import {
  Email,
  Info,
  Call,
  Help,
  SettingsApplications,
} from '@material-ui/icons'
import { ModuleRoute, Location } from '@vivium/frontend-common/types'
import { getCurrentPath } from '@vivium/frontend-common/utils'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, generatePath } from 'react-router'
import { paths } from 'config'
import { DrawerItemProps } from './Drawer'

export const useCurrentLayoutSettings = (routes: ModuleRoute[]) => {
  const location = useLocation<{ background?: Location } | null>()
  const pathname = getCurrentPath(location as Location)
  const currentRoute = routes.find(routeProps =>
    matchPath(pathname, routeProps)
  )

  return currentRoute?.layoutSettings || {}
}

export const useNavItems = (): DrawerItemProps[] => {
  const { t } = useTranslation()

  return [
    {
      label: t('navigation.email'),
      icon: Email,
      to: generatePath(paths.email),
    },
    {
      label: t('navigation.sms'),
      icon: Call,
      to: generatePath(paths.sms),
    },
    {
      label: t('navigation.informations'),
      icon: Info,
      to: generatePath(paths.informations),
    },
    {
      label: t('navigation.faq'),
      icon: Help,
      to: generatePath(paths.faq),
    },
    {
      label: t('navigation.system'),
      icon: SettingsApplications,
      to: generatePath(paths.system),
    },
  ]
}
