import { styled, Grid } from '@material-ui/core'
import {
  LoadingButton,
  AutocompleteField,
} from '@vivium/frontend-common/components'

export const StyledAutocompleteField = styled(AutocompleteField)(() => ({
  minWidth: 285,
}))

export const Container = styled(Grid)(({ theme }) => ({
  minHeight: 690,
  minWidth: 520,
  [theme.breakpoints.down('sm')]: {
    minWidth: 400,
  },
}))

export const DateFieldWrapper = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(3, 0, 4, 0),
  '& p.MuiFormHelperText-root': {
    marginTop: 0,
  },
}))

export const TextFieldWrapper = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0.5, 0, 0),
  '& p.MuiFormHelperText-root': {
    marginTop: 0,
  },
}))

export const GridDateWrapper = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(3),
}))
export const EmailBodyWrapper = styled(Grid)(({ theme }) => ({
  height: 350,
  [theme.breakpoints.down('sm')]: {
    height: 400,
  },
  '& .rdw-image-modal': {
    top: -100,
    left: -270,
  },
}))

export const ActionsWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  '& button:first-child': {
    marginRight: theme.spacing(5),
  },
}))

export const FormWrapper = styled(Grid)(() => ({
  height: 300,
}))

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: theme.spacing(1, 5, 1, 5),
}))
