import { StandardTextFieldProps, TextField } from '@material-ui/core'
import { useController, useFormContext } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { Language } from 'shared/types'
import { usePhoneField } from './PhoneField.utils'

interface PhoneFieldProps extends StandardTextFieldProps {
  name: string
  selectedLanguage?: Language
  label?: string
}

const PhoneField = ({
  name,
  label,
  selectedLanguage,
  ...props
}: PhoneFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { value, ref, ...formFieldProps },
    fieldState: { error },
  } = useController({ control, name })
  const { defaultLabel, defaultInputProps, displayedValue, mask } =
    usePhoneField(value, selectedLanguage)

  return (
    <ReactInputMask {...formFieldProps} mask={mask} value={displayedValue}>
      <TextField
        {...props}
        label={label || defaultLabel}
        error={!!error}
        helperText={error?.message}
        InputProps={props.InputProps || defaultInputProps}
        inputRef={ref}
      />
    </ReactInputMask>
  )
}

export default PhoneField
