import { Typography } from '@material-ui/core'
import { InformationStatuses } from 'shared/types'
import { InfromationStatusPill } from './InformationStatusPill.styles'

interface InformationStatusPillProps {
  label: string
  status: InformationStatuses
}

const InformationStatusPill = ({
  label,
  status,
}: InformationStatusPillProps) => {
  return (
    <InfromationStatusPill
      container
      justifyContent="center"
      alignItems="center"
      status={status}
    >
      <Typography>{label}</Typography>
    </InfromationStatusPill>
  )
}

export default InformationStatusPill
