import imageCompression from 'browser-image-compression'
import { RawDraftContentState } from 'draft-js'

export const handleEditorChange = (
  e: RawDraftContentState
): RawDraftContentState | null =>
  e.blocks[0].text || Object.keys(e.entityMap).length !== 0 ? e : null

export const toolbarOptions = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'list',
  'textAlign',
  'colorPicker',
  'link',
  'emoji',
  'image',
  'remove',
  'history',
]

export enum CompressionSettings {
  MaxSizeMb = 0.05,
  MaxWidthOrHeight = 1920,
}

type Result = string | ArrayBuffer | null
type Callback = (result: Result) => void

export const compressImage = async (imageFile: File) => {
  const options = {
    //TODO: change max size when backend will accept bigger request
    maxSizeMB: CompressionSettings.MaxSizeMb,
    maxWidthOrHeight: CompressionSettings.MaxWidthOrHeight,
    useWebWorker: true,
  }
  try {
    const compressedFile = await imageCompression(imageFile, options)
    return { file: compressedFile, shouldReject: false }
  } catch (error) {
    return { file: imageFile, shouldReject: true }
  }
}

export const getFileBase64 = (file: File, callback: Callback) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => callback(reader.result)
}

export const imageUploadCallback = async (file: File) => {
  const compressedImage = await compressImage(file)
  return new Promise((resolve, reject) => {
    if (file.type.match('image.*') && !compressedImage.shouldReject) {
      const fileFromBlob = new File([compressedImage.file], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      })
      return getFileBase64(fileFromBlob, (data: Result) =>
        resolve({ data: { link: data } })
      )
    } else {
      reject()
    }
  })
}
