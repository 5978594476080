import {
  AppBarProps,
  IconButton,
  Toolbar,
  Hidden,
  Typography,
} from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Logo } from '../../icons/Logo'
import {
  StyledTopbar,
  ToolbarActions,
  LogoContainer,
  AppLabelWrapper,
} from './Topbar.style'
import UserMenu from './UserMenu'

export interface TopbarProps extends Partial<AppBarProps> {
  hidden?: boolean
  onDrawerToggle?: () => void
}

const Topbar = ({ hidden, onDrawerToggle, ...props }: TopbarProps) => {
  const { t } = useTranslation()
  if (hidden) return null
  return (
    <StyledTopbar position="fixed" color="primary" {...props}>
      <Toolbar disableGutters>
        <LogoContainer>
          <Hidden mdUp>
            <IconButton edge="start" color="primary" onClick={onDrawerToggle}>
              <Menu />
            </IconButton>
          </Hidden>
          <Logo />
        </LogoContainer>
        <AppLabelWrapper>
          <Typography variant="body1" component="p">
            {t('layout.admin')}
          </Typography>
        </AppLabelWrapper>
        <ToolbarActions>
          <UserMenu />
        </ToolbarActions>
      </Toolbar>
    </StyledTopbar>
  )
}

export default Topbar
