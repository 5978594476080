import { LoadingButton } from '@vivium/frontend-common/components'
import { useHandleToggleAccountStatus } from './ToggleAccountStatusButton.utils'

export interface ToggleAccountStatusButtonProps {
  accountIds: number[]
  accountName: string // if multiple pass empty string
  accountActive: boolean
}

const ToggleAccountStatusButton = ({
  accountIds,
  accountName,
  accountActive,
}: ToggleAccountStatusButtonProps) => {
  const { toggleAccountStatus, activationButtonLabel } =
    useHandleToggleAccountStatus(accountIds, accountName, accountActive)
  return (
    <LoadingButton
      variant="outlined"
      color="primary"
      fullWidth={false}
      onClick={toggleAccountStatus()}
    >
      {activationButtonLabel}
    </LoadingButton>
  )
}

export default ToggleAccountStatusButton
