const paths = {
  signIn: '/sign-in',
  forgotPassword: '/forgot-password',
  activateAccount: '/activate-account',
  newPassword: '/new-password',
  multifactorAuthentication: '/mfa',
  unauthorized: '/unauthorized',
  forbidden: '/forbidden',
  email: '/email',
  sms: '/sms',
  informations: '/informations',
  faq: '/faq',
  system: '/system',
  systemCreateOrganizationScheduling: '/system/create-organization-scheduling',
  systemUpdateOrganizationScheduling:
    '/system/update-organization-scheduling/:id/:name',
  systemCreateOrganizationStaffing: '/system/create-organization-staffing',
  systemUpdateOrganizationStaffing:
    '/system/update-organization-staffing/:id/:name',
  systemCreateUser: '/system/create-user/:type/:name/:id',
  systemCreateCareProviderResource:
    '/system/create-care-provider-resource/:type/:name/:careProviderId',
  systemUpdateCareProviderResource:
    '/system/update-care-provider-resource/:resourceId/:resourceName/:orgType/:orgName/:orgId',
  systemCreateGroupAdmin: '/system/create-group-admin/:groupId/:groupName',
  systemUpdateAccount: '/system/update-account/:id',
  systemUpdateGroupAdmin:
    '/system/update-group-admin/:groupId/:groupName/:adminId',
  systemCreateOrganizationGroup: '/system/create-organization-group',
  systemUpdateOrganizationGroup: '/system/update-organization-group/:groupId',
  systemCreateStaffProviderResource:
    '/system/create-staff-provider-resource/:name/:staffProviderId',
}

export default paths
