import { createAction } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import { HandleSelectAllIdsCheckbox, ToggleId } from './action.types'

// select account/consultants checkbox

export const resetSelectedIds = createAction(`${MODULE_NAME}/resetSelectedIds`)

export const addId = createAction(
  `${MODULE_NAME}/addId`,
  (payload: ToggleId) => ({
    payload,
  })
)

export const removeId = createAction(
  `${MODULE_NAME}/removeId`,
  (payload: ToggleId) => ({
    payload,
  })
)

export const selectAllIdsCheckbox = createAction(
  `${MODULE_NAME}/handleSelectAllIdsCheckbox`,
  (payload: HandleSelectAllIdsCheckbox) => ({
    payload,
  })
)
