import { InputLabel, Button, Grid } from '@material-ui/core'
import { WatchLater } from '@material-ui/icons'
import {
  Form,
  TextField,
  DateField,
  TimeField,
} from '@vivium/frontend-common/components'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useInformationAutocompleteOptions } from '../../pages/informations.utils'
import { MODULE_NAME } from '../../strings'
import { useGetAvailableTimeRangeValues } from '../InformationsSection/informationsSection.utils'
import {
  InputWrapper,
  ActionsWrapper,
  StyledLoadingButton,
  GridDateWrapper,
  StyledAutocompleteField,
} from './InformationForm.styles'
import {
  InformationFields,
  MAX_MESSAGE_LENGTH,
  useFormProps,
  useOnSubmit,
  InformationValues,
} from './InformationForm.utils'

interface InformationFormProps {
  onCloseModal: () => void
}

const InformationForm = ({ onCloseModal }: InformationFormProps) => {
  const { t } = useTranslation(MODULE_NAME)
  const formProps = useFormProps()
  const { control, setValue } = formProps
  const { isSubmitting, handleSubmit } = useOnSubmit(onCloseModal)
  const { accessibleApplicationsOptions } = useInformationAutocompleteOptions()
  const { dateFrom, dateTo } = useWatch<InformationValues>({
    control,
  })
  const { timeFromFieldRange, timeToFieldRange } =
    useGetAvailableTimeRangeValues(control, setValue)
  return (
    <Form {...formProps} onSubmit={handleSubmit}>
      <Grid container alignItems="center">
        <InputWrapper item container xs={12}>
          <StyledAutocompleteField
            label={t('informationsForms.recipients')}
            options={accessibleApplicationsOptions}
            name={InformationFields.AccessibleApplications}
            disableClearable
          />
        </InputWrapper>
        <InputWrapper item xs={12}>
          <InputLabel>{t('informationsForms.publicationDate')}</InputLabel>
        </InputWrapper>
        <InputWrapper container item xs={12}>
          <GridDateWrapper item xs={4}>
            <DateField
              maxDate={dateTo}
              name={InformationFields.DateFrom}
              label={t('informationsForms.fromDate')}
              placeholder={t('informationsForms.datePlaceholder')}
              formatToIsoString
              disablePast
            />
          </GridDateWrapper>
          <Grid item xs={2}>
            <TimeField
              label=" "
              maxTime={timeFromFieldRange}
              IconComponent={WatchLater}
              name={InformationFields.TimeFrom}
            />
          </Grid>
        </InputWrapper>
        <InputWrapper container item xs={12}>
          <GridDateWrapper item xs={4}>
            <DateField
              minDate={dateFrom}
              name={InformationFields.DateTo}
              label={t('informationsForms.toDate')}
              placeholder={t('informationsForms.datePlaceholder')}
              formatToIsoString
              disablePast
            />
          </GridDateWrapper>
          <Grid item xs={2}>
            <TimeField
              label=" "
              minTime={timeToFieldRange}
              fullWidth={false}
              IconComponent={WatchLater}
              name={InformationFields.TimeTo}
            />
          </Grid>
        </InputWrapper>
        <InputWrapper item container xs={12}>
          <TextField
            required
            label={t('informationsForms.message')}
            name={InformationFields.Message}
            charactersCounter={MAX_MESSAGE_LENGTH}
            inputProps={{ maxLength: MAX_MESSAGE_LENGTH }}
          />
        </InputWrapper>
      </Grid>
      <ActionsWrapper container justifyContent="flex-end">
        <Button fullWidth={false} onClick={onCloseModal}>
          {t('informationsForms.cancel')}
        </Button>
        <StyledLoadingButton
          type="submit"
          color="primary"
          variant="contained"
          fullWidth={false}
          loading={isSubmitting}
        >
          {t('informations.create')}
        </StyledLoadingButton>
      </ActionsWrapper>
    </Form>
  )
}

export default InformationForm
