import { LoadingStatus } from '@vivium/frontend-common/types'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectors, actions } from '../../../modules/system/store'

export const useActivateAccount = (
  setIsAccountActive: Dispatch<SetStateAction<boolean>>,
  accountName: string,
  accountIds: number[]
) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { loading } = useSelector(selectors.activateAccount)

  const onFailure = (message?: string) =>
    enqueueSnackbar(
      message ||
        t('activateAccount.fail', {
          accountName,
        }),
      {
        variant: 'error',
      }
    )
  const onSuccess = (message?: string) => {
    setIsAccountActive(true)
    enqueueSnackbar(
      message ||
        t('activateAccount.success', {
          accountName,
        }),
      {
        variant: 'success',
      }
    )
  }
  const activateAccount = () => {
    dispatch(
      actions.activateAccount({
        onFailure,
        onSuccess,
        params: { accountIds },
      })
    )
  }

  return { activateAccount, isActivating: loading === LoadingStatus.Pending }
}

export const useDeactivateAccount = (
  setIsAccountActive: Dispatch<SetStateAction<boolean>>,
  accountName: string,
  accountIds: number[]
) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { loading } = useSelector(selectors.deactivateAccount)

  const onFailure = (message?: string) =>
    enqueueSnackbar(
      message ||
        t('deactivateAccount.fail', {
          accountName,
        }),
      {
        variant: 'error',
      }
    )

  const onSuccess = (message?: string) => {
    setIsAccountActive(false)
    enqueueSnackbar(
      message ||
        t('deactivateAccount.success', {
          accountName,
        }),
      {
        variant: 'success',
      }
    )
  }
  const deactivateAccount = () => {
    dispatch(
      actions.deactivateAccount({
        onFailure,
        onSuccess,
        params: { accountIds },
      })
    )
  }

  return {
    deactivateAccount,
    isDeactivating: loading === LoadingStatus.Pending,
  }
}

export const useHandleToggleAccountStatus = (
  accountIds: number[],
  accountName: string,
  accountActive: boolean
) => {
  const { t } = useTranslation()
  const [isAccountActive, setIsAccountActive] = useState(false)

  useEffect(() => {
    if (accountActive !== undefined) setIsAccountActive(accountActive)
  }, [accountActive])

  const { deactivateAccount, isDeactivating } = useDeactivateAccount(
    setIsAccountActive,
    accountName,
    accountIds
  )
  const { activateAccount, isActivating } = useActivateAccount(
    setIsAccountActive,
    accountName,
    accountIds
  )

  const toggleAccountStatus = () => () =>
    isAccountActive ? deactivateAccount() : activateAccount()

  return {
    toggleAccountStatus,
    isAccountActive,
    activationButtonLabel: isAccountActive
      ? t('deactivateAccount.deactivate')
      : t('activateAccount.activate'),
    isAccountStatusLoading: isDeactivating || isActivating,
  }
}
