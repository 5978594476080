import { AutocompleteValue } from '@vivium/frontend-common/components'
import { useTranslation } from 'react-i18next'
import { SchemaOf, mixed, object } from 'yup'
import {
  useAutocompleteOptions,
  useStatusAutocompleteOptions,
} from 'shared/components'

export enum RecipientsFields {
  OrganizationType = 'organizationType',
  Organization = 'organization',
  Recipients = 'recipients',
  AccountStatus = 'accountStatus',
}

export interface RecipientsValues {
  [RecipientsFields.OrganizationType]: AutocompleteValue
  [RecipientsFields.Organization]: AutocompleteValue
  [RecipientsFields.Recipients]: AutocompleteValue
  [RecipientsFields.AccountStatus]: AutocompleteValue
}

export const useRecipientsDefaultValues = (): RecipientsValues => {
  const { defaultAutocompleteOption } = useAutocompleteOptions()
  const { defaultStatusAutocompleteOption } = useStatusAutocompleteOptions()
  const { t } = useTranslation()

  return {
    [RecipientsFields.OrganizationType]: defaultAutocompleteOption,
    [RecipientsFields.Organization]: {
      label: t('organizationType.all'),
      value: 0,
    },
    [RecipientsFields.Recipients]: null,
    [RecipientsFields.AccountStatus]: defaultStatusAutocompleteOption,
  }
}

export const useRecipientsSchema = (): SchemaOf<RecipientsValues> => {
  const { t } = useTranslation()
  return object()
    .shape({
      [RecipientsFields.Organization]: mixed<RecipientsValues>().required(
        t('validation.required')
      ),
      [RecipientsFields.OrganizationType]: mixed<RecipientsValues>().required(
        t('validation.required')
      ),
      [RecipientsFields.Recipients]: mixed<RecipientsValues>().required(
        t('validation.required')
      ),
      [RecipientsFields.AccountStatus]: mixed<RecipientsValues>().required(
        t('validation.required')
      ),
    })
    .required()
}
