import { Typography } from '@material-ui/core'
import {
  SelectOrganizationTypeAutocomplete,
  SelectOrganizationAutocomplete,
  SelectEmailRecipientsAutocomplete,
  SelectAccountStatusAutocomplete,
} from 'shared/components'
import { MessageDeliveryType } from 'shared/types'
import { Wrapper, AutocompletesWrapper } from './RecipientsSection.styles'
import { RecipientsFields } from './RecipientsSection.utils'

interface RecipientsSectionProps {
  header: string
  messageDeliveryType: MessageDeliveryType
}

const RecipientsSection = ({
  header,
  messageDeliveryType,
}: RecipientsSectionProps) => {
  return (
    <Wrapper
      container
      alignItems="flex-start"
      justifyContent="center"
      direction="column"
    >
      <Typography component="h5" variant="h5">
        {header}
      </Typography>
      <AutocompletesWrapper
        container
        item
        alignItems="center"
        justifyContent="flex-start"
        sm={12}
      >
        <SelectOrganizationTypeAutocomplete
          name={RecipientsFields.OrganizationType}
        />
        <SelectOrganizationAutocomplete name={RecipientsFields.Organization} />
        <SelectAccountStatusAutocomplete
          name={RecipientsFields.AccountStatus}
        />
        <SelectEmailRecipientsAutocomplete
          name={RecipientsFields.Recipients}
          messageDeliveryType={messageDeliveryType}
        />
      </AutocompletesWrapper>
    </Wrapper>
  )
}

export default RecipientsSection
