import { Phone } from '@material-ui/icons'
import { StartAdornment } from '@vivium/frontend-common/components'
import { AsYouType, getExampleNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Language } from 'shared/types'

/**
 * Not every country is easily detectible, like US for example (almost all digits need to be
 * typed in order for libphonenumber to detect it as US number), therefore we leave a lot of space
 * as a default mask, to allow typing as much as needed for all numbers.
 */
const BASE_MASK = '+999999999999999'

const countryCodesMap = {
  [Language.English]: '+44',
  [Language.Swedish]: '+46',
  [Language.Norwegian]: '+47',
}

export const usePhoneField = (value: string, selectedLanguage?: Language) => {
  const { t } = useTranslation()
  const defaultValue = countryCodesMap[selectedLanguage || Language.Swedish]
  const defaultLabel = t('form.phoneInputLabel')
  const defaultInputProps = {
    startAdornment: <StartAdornment icon={Phone} />,
  }
  const displayedValue = value || defaultValue

  const [maskGenerator, setMaskGenerator] = useState<AsYouType | null>(null)

  useEffect(() => {
    setMaskGenerator(new AsYouType())
  }, [])

  maskGenerator?.reset()

  if (displayedValue) {
    maskGenerator?.input(displayedValue.replaceAll('_', ''))
  }

  const countryCode = maskGenerator?.getCountry()
  const mask = countryCode
    ? getExampleNumber(countryCode, examples)
        ?.formatInternational()
        .replace(/[\d]/g, '9') || BASE_MASK
    : BASE_MASK

  return { defaultLabel, defaultInputProps, displayedValue, mask }
}
