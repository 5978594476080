import { Typography, Grid } from '@material-ui/core'
import {
  Cancel,
  SentimentVeryDissatisfied,
  SentimentSatisfiedAlt,
  SentimentSatisfied,
} from '@material-ui/icons'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { useGetMessageUpdate } from '../../../pages'
import { MODULE_NAME } from '../../../strings'
import {
  PreviewModalContainer,
  PreviewDialogContent,
  PreviewFormHeaderWrapper,
  PreviewFormContentHeaderWrapper,
  FeedbackWrapper,
  PreviewHtmlWrapper,
} from './UpdatePreview.styles'

interface UpdatePreviewProps {
  isModalOpen: boolean
  onCloseModal: () => void
  id: number
}

const UpdatePreview = ({
  isModalOpen,
  onCloseModal,
  id,
}: UpdatePreviewProps) => {
  const { t } = useTranslation(MODULE_NAME)
  const { messageUpdate, error, isLoading } = useGetMessageUpdate(id)

  const shouldPreviewBeRendered = !error && !isLoading && !!messageUpdate

  return (
    <PreviewModalContainer
      fullWidth
      maxWidth="xl"
      open={isModalOpen}
      onClose={onCloseModal}
    >
      {shouldPreviewBeRendered && (
        <>
          <PreviewFormHeaderWrapper
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1" component="h6">
              {messageUpdate.title}
            </Typography>
            <Cancel onClick={onCloseModal} />
          </PreviewFormHeaderWrapper>
          <PreviewDialogContent>
            <PreviewFormContentHeaderWrapper>
              <Typography variant="h4" component="h4">
                {messageUpdate.title}
              </Typography>
            </PreviewFormContentHeaderWrapper>
            <PreviewHtmlWrapper>
              {ReactHtmlParser(messageUpdate.body)}
            </PreviewHtmlWrapper>
            <FeedbackWrapper
              container
              alignItems="center"
              justifyContent="flex-end"
              direction="column"
            >
              <Typography variant="subtitle2" component="p">
                {t('feedback.feedbackQuestion')}
              </Typography>
              <Typography variant="subtitle2" component="p">
                {t('feedback.sendFeedback')}
              </Typography>
              <Grid container alignItems="center" justifyContent="center">
                <SentimentSatisfiedAlt color="primary" />
                <SentimentSatisfied color="primary" />
                <SentimentVeryDissatisfied color="primary" />
              </Grid>
            </FeedbackWrapper>
          </PreviewDialogContent>
        </>
      )}
    </PreviewModalContainer>
  )
}

export default UpdatePreview
