import { createReducer } from '@reduxjs/toolkit'
import { Document } from '@vivium/frontend-common/types'
import resource, { Resource } from 'shared/resource'
import { StaffProviderResourceDetail } from 'shared/services/staffProviderResource'
import {
  getStaffProviderResourceDetails,
  deleteStaffProviderResource,
  resetDeleteStaffProviderResource,
  getAllStaffProvidersResourceDetails,
  createResource,
  resetCreateResource,
} from './actions'

export interface State {
  getStaffProviderResourceDetails: Resource<StaffProviderResourceDetail[]>
  deleteStaffProviderResource: Resource<number>
  handleSelectConsultantChange: () => void
  getAllStaffProvidersResourceDetails: Resource<StaffProviderResourceDetail[]>
  createResource: Resource<string>
  resourceDocuments: Document[]
}

const initialState: State = {
  createResource: resource.getInitial(''),
  getStaffProviderResourceDetails: resource.getInitial<
    StaffProviderResourceDetail[]
  >([]),
  deleteStaffProviderResource: resource.getInitial<number>(),
  handleSelectConsultantChange: () => {},
  getAllStaffProvidersResourceDetails:
    resource.getInitial<StaffProviderResourceDetail[]>(),
  resourceDocuments: [],
}

export default createReducer(initialState, builder =>
  builder
    .addCase(createResource.pending, state => {
      resource.setPending(state.createResource)
    })
    .addCase(createResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.createResource, action.payload)
    })
    .addCase(createResource.rejected, (state, action) => {
      resource.setFailed(
        state.createResource,
        (action.payload as string) || action.error.message
      )
    })
    .addCase(resetCreateResource, state => {
      resource.reset(state.createResource, initialState.createResource.data)
      state.resourceDocuments = initialState.resourceDocuments
    })
    .addCase(getAllStaffProvidersResourceDetails.pending, state => {
      resource.setPending(state.getAllStaffProvidersResourceDetails)
    })
    .addCase(getAllStaffProvidersResourceDetails.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getAllStaffProvidersResourceDetails,
        action.payload.data
      )
    })
    .addCase(getAllStaffProvidersResourceDetails.rejected, (state, action) => {
      resource.setFailed(
        state.getAllStaffProvidersResourceDetails,
        action.error.message
      )
    })
    .addCase(getStaffProviderResourceDetails.pending, state => {
      resource.setPending(state.getStaffProviderResourceDetails)
    })
    .addCase(getStaffProviderResourceDetails.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getStaffProviderResourceDetails,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getStaffProviderResourceDetails.rejected, (state, action) => {
      resource.setFailed(
        state.getStaffProviderResourceDetails,
        action.error.message
      )
    })
    .addCase(deleteStaffProviderResource.pending, state => {
      resource.setPending(state.deleteStaffProviderResource)
    })
    .addCase(deleteStaffProviderResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteStaffProviderResource, action.payload)
    })
    .addCase(deleteStaffProviderResource.rejected, (state, action) => {
      resource.setFailed(
        state.deleteStaffProviderResource,
        action.error.message
      )
    })
    .addCase(resetDeleteStaffProviderResource, state => {
      resource.reset(state.deleteStaffProviderResource)
    })
)
