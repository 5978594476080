import Api from '../api'
import {
  AssignSpecialtiesParams,
  UnassignSpecialtyParams,
} from './CareProviderSpecialties.types'

class CareProviderSpecialties extends Api {
  public careProviderSpecialtiesAssign = async ({
    careProviderId,
    ...payload
  }: AssignSpecialtiesParams) => {
    const { data } = await this.api.post<object>(
      `/admin/care-providers/${careProviderId}/specialties/assign`,
      payload
    )
    return data
  }
  public careProviderSpecialtiesUnassign = async ({
    careProviderId,
    specialtyId,
  }: UnassignSpecialtyParams) => {
    const { data } = await this.api.post<object>(
      `/admin/care-providers/${careProviderId}/specialties/unassign/${specialtyId}`
    )
    return data
  }
}

export default CareProviderSpecialties
