import type { RootState } from 'app'

export const getAuth = (state: RootState) => state.common.auth

export const getAuthLoading = (state: RootState) => state.common.auth.loading

export const getAuthError = (state: RootState) => state.common.auth.error

export const getUser = (state: RootState) => state.common.auth.user

export const getAccountId = (state: RootState) => state.common.auth.user?.id

export const getResourceId = (state: RootState) =>
  state.common.auth.user?.careProviders[0].resources[0].id

export const getSignIn = (state: RootState) => state.common.auth.signIn

export const getSignOut = (state: RootState) => state.common.auth.signOut

export const getResetPassword = (state: RootState) =>
  state.common.auth.resetPassword

export const getSetNewPassword = (state: RootState) =>
  state.common.auth.setNewPassword

export const getConfirmForgotPassword = (state: RootState) =>
  state.common.auth.confirmForgotPassword

export const getResolveChallenge = (state: RootState) =>
  state.common.auth.resolveChallenge
