import { createAsyncThunk } from '@reduxjs/toolkit'
import Sms from 'shared/services/sms'
import { MODULE_NAME } from '../strings'
import { SendSmsPayload } from './action.types'

const sms = new Sms()

export const sendSms = createAsyncThunk(
  `${MODULE_NAME}/sendSms`,
  async ({ payload, onSuccess, onError }: SendSmsPayload) => {
    try {
      const data = await sms.sendSms(payload)
      if (onSuccess) onSuccess()
      return data
    } catch {
      if (onError) onError()
    }
  }
)
