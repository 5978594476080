import { createGetAutocompleteOptions } from '@vivium/frontend-common/utils'
import { useMemo } from 'react'
import config from 'config'
import { Country } from 'shared/types'
import { Suggestions } from '../services/suggestions'

const { defaultSearchBy } = config
const suggestions = new Suggestions()

export const useGetRegionOptions = (country: Country) =>
  useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getRegions({ search, country, searchBy: defaultSearchBy })
      ),
    [country]
  )
