import { styled, Dialog, DialogContent, Grid } from '@material-ui/core'

export const PreviewModalContainer = styled(Dialog)(({ theme }) => ({
  backgroundColor: theme.palette.shadow.dark,
  zIndex: theme.zIndex.drawer + 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiDialog-paper': {
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
}))

export const PreviewDialogContent = styled(DialogContent)(() => ({
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  '& img': {
    maxWidth: '100%',
  },
}))

export const PreviewHtmlWrapper = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 8),
}))

export const PreviewFormHeaderWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.contrastText,
  padding: theme.spacing(1, 2),
  color: theme.palette.neutral.light,

  '& svg': {
    fontSize: '2rem',
    cursor: 'pointer',
  },
}))

export const PreviewFormContentHeaderWrapper = styled(Grid)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(5, 8, 1, 8),
}))

export const FeedbackWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  padding: theme.spacing(3, 0),

  '& svg': {
    fontSize: '3rem',
    margin: theme.spacing(2, 1, 0),
    color: theme.palette.primary.main,
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
  },

  '& svg:hover': {
    transform: 'scale(1.2)',
  },

  '& p': {
    color: theme.palette.text.primary,
    margin: theme.spacing(0.5),
  },
}))
