import Api from '../api'
import {
  CreateResourceParams,
  CreateResourceResponse,
  GetResourceDetailsParams,
  GetResourceDetailsResponse,
  UpdateResourceParams,
  UpdateResourceResponse,
} from './CareProviderResource.types'

class CareProviderResource extends Api {
  public updateResource = async ({
    careProviderId,
    resourceId,
    ...payload
  }: UpdateResourceParams) => {
    const { data } = await this.api.put<UpdateResourceResponse>(
      `/mock/care-providers/${careProviderId}/resources/${resourceId}`,
      payload
    )
    return data
  }
  public createResource = async ({
    careProviderId,
    ...payload
  }: CreateResourceParams) => {
    const headers = await this.getCsrfHeaders()
    const { data } = await this.api.post<CreateResourceResponse>(
      `/mock/care-providers/${careProviderId}/resources`,
      payload,
      { headers }
    )
    return data
  }
  public getResourceDetails = async ({
    careProviderId,
    resourceId,
    ...params
  }: GetResourceDetailsParams) => {
    const { data } = await this.api.get<GetResourceDetailsResponse>(
      `/mock/care-providers/${careProviderId}/resources/${resourceId}`,
      { params }
    )
    return data
  }
}

export default CareProviderResource
