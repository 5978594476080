import { Theme, createTheme } from '@material-ui/core'
import { svSE } from '@material-ui/core/locale'
import breakpoints from './breakpoints'
import overrides from './overrides'
import palette from './palette'
import props from './props'
import spacing from './spacing'
import typography from './typography'
import zIndex from './zIndex'

const theme: Theme = createTheme(
  {
    props,
    palette,
    overrides,
    typography,
    spacing,
    zIndex,
    breakpoints,
  },
  svSE
)

export default theme
