import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  GetOrganizationsParams,
  Organizations,
} from 'shared/services/organizations'
import { NAMESPACE } from '../../strings'

const organizations = new Organizations()

export const getOrganizations = createAsyncThunk(
  `${NAMESPACE}/getOrganizations`,
  (payload?: GetOrganizationsParams) => organizations.getOrganizations(payload)
)
