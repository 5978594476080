import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { i18n, consoleFilter } from 'config'
import App from './app'
import reportWebVitals from './reportWebVitals'
import { server } from './shared/mocks/server'

i18n.init()
consoleFilter.init()
if (process.env.REACT_APP_MSW_ENABLED === 'true')
  server.start({ quiet: true, onUnhandledRequest: 'bypass' })

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
