import { Grid } from '@material-ui/core'
import { AutocompleteField } from '@vivium/frontend-common/components'
import { useTranslation } from 'react-i18next'
import { useAutocompleteOptions } from './SelectOrganizationTypeAutocomplete.utils'

interface SelectOrganizationAutocompleteProps {
  name: string
}

const SelectOrganizationTypeAutocomplete = ({
  name,
}: SelectOrganizationAutocompleteProps) => {
  const { t } = useTranslation()
  const { autocompleteOptions } = useAutocompleteOptions()

  return (
    <Grid item container xs={12} sm={6} md={5} lg={3}>
      <AutocompleteField
        name={name}
        fullWidth
        disableClearable
        label={t('sendMessage.filters.organizationType')}
        options={autocompleteOptions}
      />
    </Grid>
  )
}

export default SelectOrganizationTypeAutocomplete
