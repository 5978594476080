import { AutocompleteValue } from '@vivium/frontend-common/components'
import { LoadingStatus } from '@vivium/frontend-common/types'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Recipient } from 'shared/services/recipients'
import { actions, selectors } from 'shared/store'
import { MessageDeliveryType, OrganizationTypes } from 'shared/types'

export const useGetRecipients = (
  organizationType: OrganizationTypes | undefined,
  organization: AutocompleteValue,
  isActive: boolean,
  messageDeliveryType: MessageDeliveryType
) => {
  const dispatch = useDispatch()

  const {
    data: recipients,
    loading,
    error,
  } = useSelector(selectors.recipients.getRecipients)

  const getIds = (expectedType: string, organization: AutocompleteValue) =>
    organization?.data === expectedType ? [organization.value] : undefined

  const staffProviderIds = useMemo(
    () => getIds(OrganizationTypes.StaffProvider, organization),
    [organization]
  )
  const careProviderIds = useMemo(
    () => getIds(OrganizationTypes.CareProvider, organization),
    [organization]
  )

  const arrOfDeps = [
    organizationType,
    organization,
    isActive,
    messageDeliveryType,
    staffProviderIds,
    careProviderIds,
  ]
  const arrOfDepsRef = useRef<any[]>([])

  useEffect(() => {
    if (!isEqual(arrOfDeps, arrOfDepsRef.current)) {
      dispatch(
        actions.recipients.getRecipients({
          organizationType,
          staffProviderIds,
          careProviderIds,
          isActive,
          messageDeliveryType,
        })
      )
      arrOfDepsRef.current = arrOfDeps
    }
  }, [organizationType, organization, isActive]) //eslint-disable-line

  return {
    recipients,
    isLoading: loading === LoadingStatus.Pending,
    error,
  }
}
export const useAutocompleteRecipientsOptions = () => {
  const { t } = useTranslation()
  const { data: recipients } = useSelector(selectors.recipients.getRecipients)
  const onlyOneRoleAvailableCount = 2

  if (!recipients) return []

  const removeRecipientsWhereCountIsEqualZeroCb = ({ count }: Recipient) =>
    count

  const recipientsWhereCountsIsHigherThanZero = recipients.filter(
    removeRecipientsWhereCountIsEqualZeroCb
  )

  const translateRecipientLabelCb = ({ count, authRoleGroup }: Recipient) => ({
    label: t(`sendMessage.recipients.${authRoleGroup}`, { count }),
    value: authRoleGroup,
  })

  const translatedRecipientsAutocompleteOptions =
    recipientsWhereCountsIsHigherThanZero.map(translateRecipientLabelCb)

  if (recipientsWhereCountsIsHigherThanZero.length == onlyOneRoleAvailableCount)
    return [translatedRecipientsAutocompleteOptions[0]] // if organization has one available role return only all option

  if (recipientsWhereCountsIsHigherThanZero.length == 0) return []

  return translatedRecipientsAutocompleteOptions
}
