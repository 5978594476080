import { createReducer } from '@reduxjs/toolkit'
import {
  resetSelectedIds,
  addId,
  removeId,
  selectAllIdsCheckbox,
} from './actions'

interface SelectedIds {
  [key: string]: boolean
}

export interface State {
  selectedIds: SelectedIds
  selectAllIdsCheckbox: boolean
}

const initialState = {
  selectAllIdsCheckbox: false,
  selectedIds: {} as SelectedIds,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(resetSelectedIds, state => {
      state.selectedIds = {}
    })
    .addCase(selectAllIdsCheckbox, (state, action) => {
      const data = action.payload.data
      state.selectAllIdsCheckbox = action.payload.isSelected
      if (state.selectAllIdsCheckbox) {
        const ids = data.map(val => {
          return {
            [val.id]: true,
          }
        })
        const idsObj = Object.assign({}, ...ids)
        state.selectedIds = idsObj
      } else {
        state.selectedIds = {}
      }
    })
    .addCase(addId, (state, action) => {
      const { id, totalCount } = action.payload
      const selectedIds = state.selectedIds
      state.selectedIds = { ...selectedIds, [id]: true }
      const selectedIdsLength = Object.keys(selectedIds).length + 1
      if (selectedIdsLength == totalCount) state.selectAllIdsCheckbox = true
    })
    .addCase(removeId, (state, action) => {
      const { id } = action.payload
      const selectedIds = state.selectedIds
      const { [id]: removedId, ...rest } = selectedIds //eslint-disable-line
      state.selectedIds = { ...rest }
      if (state.selectAllIdsCheckbox) state.selectAllIdsCheckbox = false
    })
)
