import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import { Recipient } from 'shared/services/recipients'
import { getRecipients } from './actions'

export interface State {
  getRecipients: Resource<Recipient[]>
}

const initialState: State = {
  getRecipients: resource.getInitial<Recipient[]>([]),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getRecipients.pending, state => {
      resource.setPending(state.getRecipients)
    })
    .addCase(getRecipients.fulfilled, (state, action) => {
      resource.setSucceeded(state.getRecipients, action.payload.data)
    })
    .addCase(getRecipients.rejected, (state, action) => {
      resource.setFailed(state.getRecipients, action.error.message)
    })
)
