import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from '../../../shared/resource'
import { Organization } from '../../../shared/services/organizations'
import { getOrganizations } from './actions'

export interface State {
  getOrganizations: Resource<Organization[]>
}

const initialState: State = {
  getOrganizations: resource.getInitial<Organization[]>([]),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getOrganizations.pending, state => {
      resource.setPending(state.getOrganizations)
    })
    .addCase(getOrganizations.fulfilled, (state, action) => {
      resource.setSucceeded(state.getOrganizations, action.payload)
    })
    .addCase(getOrganizations.rejected, (state, action) => {
      resource.setFailed(state.getOrganizations, action.error.message)
    })
)
