import { InputLabel, Button, Grid, CircularProgress } from '@material-ui/core'
import { WatchLater } from '@material-ui/icons'
import {
  Form,
  TextField,
  DateField,
  TimeField,
  ErrorHandler,
} from '@vivium/frontend-common/components'
import { useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useInformationAutocompleteOptions } from '../../pages/informations.utils'
import { MODULE_NAME } from '../../strings'
import { useGetAvailableTimeRangeValues } from '../InformationsSection/informationsSection.utils'
import {
  InputWrapper,
  ActionsWrapper,
  StyledLoadingButton,
  GridDateWrapper,
  Container,
  StyledAutocompleteField,
} from './InformationUpdateForm.styles'
import { InformationValues } from './InformationUpdateForm.utils'
import {
  InformationFields,
  MAX_MESSAGE_LENGTH,
  useFormProps,
  useOnSubmit,
  useGetMessageInformation,
  useConvertFetchedDataToFormValues,
} from './InformationUpdateForm.utils'

interface InformationFormProps {
  onCloseModal: () => void
  id: number
}

const InformationUpdateForm = ({ onCloseModal, id }: InformationFormProps) => {
  const { t } = useTranslation(MODULE_NAME)
  const formProps = useFormProps()
  const { control, setValue } = formProps
  const { isSubmitting, handleSubmit } = useOnSubmit(id, onCloseModal)
  const { accessibleApplicationsOptions } = useInformationAutocompleteOptions()
  const { messageInformation, error, isLoading } = useGetMessageInformation(id)
  const formValues = useConvertFetchedDataToFormValues(messageInformation)
  const { timeFromFieldRange, timeToFieldRange } =
    useGetAvailableTimeRangeValues(control, setValue)
  const { dateFrom, dateTo } = useWatch<InformationValues>({
    control,
  })
  useEffect(() => {
    if (messageInformation !== null && !error) {
      formProps.reset(formValues)
    }
  }, [messageInformation, isLoading]) //eslint-disable-line

  return (
    <Form {...formProps} onSubmit={handleSubmit}>
      {isLoading && (
        <Container container alignItems="center" justifyContent="center">
          <CircularProgress />
        </Container>
      )}
      {error && (
        <Container container alignItems="center" justifyContent="center">
          <ErrorHandler message={error} />
        </Container>
      )}
      {!error && !isLoading && (
        <>
          <Grid container alignItems="center">
            <InputWrapper item container xs={12}>
              <StyledAutocompleteField
                disableClearable
                label={t('informationsForms.recipients')}
                options={accessibleApplicationsOptions}
                name={InformationFields.AccessibleApplications}
              />
            </InputWrapper>
            <InputWrapper item xs={12}>
              <InputLabel>{t('informationsForms.publicationDate')}</InputLabel>
            </InputWrapper>
            <InputWrapper container item xs={12}>
              <GridDateWrapper item xs={4}>
                <DateField
                  maxDate={dateTo}
                  name={InformationFields.DateFrom}
                  label={t('informationsForms.fromDate')}
                  placeholder={t('informationsForms.datePlaceholder')}
                  formatToIsoString
                  disablePast
                />
              </GridDateWrapper>
              <Grid item xs={2}>
                <TimeField
                  maxTime={timeFromFieldRange}
                  label=" "
                  IconComponent={WatchLater}
                  name={InformationFields.TimeFrom}
                />
              </Grid>
            </InputWrapper>
            <InputWrapper container item xs={12}>
              <GridDateWrapper item xs={4}>
                <DateField
                  minDate={dateFrom}
                  name={InformationFields.DateTo}
                  label={t('informationsForms.toDate')}
                  placeholder={t('informationsForms.datePlaceholder')}
                  formatToIsoString
                  disablePast
                />
              </GridDateWrapper>
              <Grid item xs={2}>
                <TimeField
                  minTime={timeToFieldRange}
                  label=" "
                  fullWidth={false}
                  IconComponent={WatchLater}
                  name={InformationFields.TimeTo}
                />
              </Grid>
            </InputWrapper>
            <InputWrapper item container xs={12}>
              <TextField
                required
                label={t('informationsForms.message')}
                name={InformationFields.Message}
                charactersCounter={MAX_MESSAGE_LENGTH}
                inputProps={{ maxLength: MAX_MESSAGE_LENGTH }}
              />
            </InputWrapper>
          </Grid>
          <ActionsWrapper container justifyContent="flex-end">
            <Button fullWidth={false} onClick={onCloseModal}>
              {t('informationsForms.cancel')}
            </Button>
            <StyledLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={false}
              loading={isSubmitting}
            >
              {t('informationsForms.update')}
            </StyledLoadingButton>
          </ActionsWrapper>
        </>
      )}
    </Form>
  )
}

export default InformationUpdateForm
