import { styled, Grid, TableRow as BaseTableRow } from '@material-ui/core'

export const StyledTableRow = styled(BaseTableRow)(({ theme }) => ({
  boxShadow: `0px 2px 16px ${theme.palette.shadow.main}`,
  borderRadius: 4,
  height: 120,

  '& .MuiTableCell-body': {
    color: theme.palette.text.primary,
    height: 120,
  },
}))

export const ActionsWrapper = styled(Grid)(({ theme }) => ({
  '& svg': {
    width: '1.9rem',
    height: '1.9rem',
    cursor: 'pointer',
    color: theme.palette.neutral.contrastText,
  },

  '& svg:nth-child(1)': {
    marginRight: theme.spacing(1.5),
  },
}))

export const PillWrapper = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}))
