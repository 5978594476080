import { DocumentType } from '@vivium/frontend-common/types'
import { Country } from 'shared/types'
import env from './env'

const DAY_SECONDS = 24 * 60 * 60

const scheduler = {
  firstDayOfWeek: 1 as Day,
  startDayHour: 9,
  endDayHour: 11,
  resourceName: 'id',
}

const config = {
  ...env,
  scheduler,
  notificationsListenerInterval: 30000,
  defaultAutocompleteOptionsCount: 40,
  defaultSearchBy: 'name',
  defaultSortBy: 'name',
  defaultSortOrder: 'ASC',
  defaultCountry: Country.Sweden,
  maxSnackbarsCount: 3,
  defaultCountryCode: '+46',
  maxPhoneNumberLength: 64,
  authTokenKey: 'token',
  sessionLength: DAY_SECONDS,
  documentTypes: [
    DocumentType.CV,
    DocumentType.Reference,
    DocumentType.HOSP,
    DocumentType.IVO,
    DocumentType.QualifyingPeriod,
  ],
  pagination: {
    defaultCount: 0,
    defaultPage: 0,
    defaultPageSize: 10,
  },
}

export default config
